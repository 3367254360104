import React from "react";
// import Sponser1 from '../../assets/images/sponser-1.png';
// import Sponser10 from '../../assets/images/sponser-10.png';
// import Sponser2 from '../../assets/images/sponser-2.png';
// import Sponser3 from '../../assets/images/sponser-3.png';
// import Sponser4 from '../../assets/images/sponser-4.png';
// import Sponser5 from '../../assets/images/sponser-5.png';
// import Sponser6 from '../../assets/images/sponser-6.png';
// import Sponser7 from '../../assets/images/sponser-7.png';
// import Sponser8 from '../../assets/images/sponser-8.png';
// import Sponser9 from '../../assets/images/sponser-9.png';
// import sponserShape from '../../assets/images/sponser-shape.png';

import GEBCO from "../../media/gebco.png";
import OSM from "../../media/OSM.png";
import EMODNET from "../../media/emodnet.jpg";
import ERA5 from "../../media/era5.jpg";
import PyWake from "../../media/pywake.png";
import DTU from "../../media/DTU.png";
import MR from "../../media/marineRegions.png";

function SponserHomeTwo({ className }) {
  return (
    <>
      <section className={`appie-sponser-area pb-100 pt-35 ${className}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">
                  Powered By<br />
                </h3>
                {/* <p>Vekta Digital utilises free/open-source solutions .</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-sponser-box d-flex justify-content-center">
                <a className="sponser-item" href="https://www.gebco.net/" target="_blank">
                  <img
                    src={GEBCO}
                    style={{
                      width: "100px",
                      height: "100%",
                      objectFit: "scale-down",
                    }}
                    alt=""
                  />
                </a>
                <a className="sponser-item" href="https://www.openstreetmap.org/#map=6/54.910/-3.432" target="_blank">
                  <img
                    src={OSM}
                    style={{
                      width: "100px",
                      height: "100%",
                      objectFit: "scale-down",
                    }}
                    alt=""
                  />
                </a>
                <a className="sponser-item" href="https://emodnet.ec.europa.eu/en" target="_blank">
                  <img
                    src={EMODNET}
                    style={{
                      width: "100px",
                      height: "100%",
                      objectFit: "scale-down",
                    }}
                    alt=""
                  />
                </a>
                <a className="sponser-item" href="https://climate.copernicus.eu/climate-reanalysis" target="_blank">
                  <img
                    src={ERA5}
                    style={{
                      width: "190px",
                      height: "100%",
                      objectFit: "scale-down",
                    }}
                    alt=""
                  />
                </a>
                <a className="sponser-item" href="https://wind.dtu.dk/" target="_blank">
                  <img
                    src={DTU}
                    style={{
                      width: "100px",
                      height: "100%",
                      objectFit: "scale-down",
                    }}
                    alt=""
                  />
                </a>
                <a className="sponser-item" href="https://marineregions.org/" target="_blank">
                  <img
                    src={MR}
                    style={{
                      width: "250px",
                      height: "100%",
                      objectFit: "scale-down",
                    }}
                    alt=""
                  />
                </a>
                <a className="sponser-item" href="https://topfarm.pages.windenergy.dtu.dk/PyWake/" target="_blank">
                  <img
                    src={PyWake}
                    style={{
                      width: "110px",
                      height: "100%",
                      objectFit: "scale-down",
                    }}
                    alt=""
                  />
                </a>
              </div>
              {/* <div className="appie-sponser-box item-2 d-flex justify-content-center"></div> */}
            </div>
          </div>
        </div>
        <div className="sponser-shape">
          {/* <img src={sponserShape} alt="" /> */}
        </div>
      </section>
    </>
  );
}

export default SponserHomeTwo;
