/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useContext, useState } from "react";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import { PlatformData } from "../../imports/Context";
import $ from "jquery";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";

function WidgetButton({
  name,
  title,
  svg,
  currentWidget,
  sidebarContent,
  setSidebarContent,
  dot,
}) {
  const navigate = useNavigate();
  const { platformData, setPlatformData } = useContext(PlatformData);
  const [searchParams, setSearchParams] = useSearchParams();

  const widgetBtnClick = () => {
    $(".leaflet-container").trigger("shown");

    var params = null;

    if (searchParams.size > 0) {
      for (const [key, value] of searchParams.entries()) {
        params = createSearchParams({
          [key]: value,
        }).toString();
      }
    }

    const locationDescriptor = {
      pathname: name,
      search: params,
    };

    navigate(name);

    setPlatformData((platformData) => {
      let sidePanelOpen;
      //===================
      if (platformData.sidePanelOpen) {
        if (platformData.activeWidget === name) {
          sidePanelOpen = false;
        } else {
          /**
           * 
           * GOOGLE ANALYTICS
           * 
           */
          logEvent(analytics, 'page_view', {
            page_title: name,
            page_location: window.location
          });

          sidePanelOpen = true;
        }
      } else {
        /**
         * 
         * GOOGLE ANALYTICS
         * 
         */
        logEvent(analytics, 'page_view', {
          page_title: name,
          page_location: window.location
        });

        sidePanelOpen = true;
      }
      //===================
      if (name === "weatherAnalysis")
        return {
          ...platformData,
          activeWidget: name,
          weatherAnalysis: {
            ...platformData.weatherAnalysis,
            ["renderERA5"]: true,
          },
          ["sidePanelOpen"]: sidePanelOpen,

        };

      return {
        ...platformData,
        activeWidget: name,
        ["sidePanelOpen"]: sidePanelOpen,
      };
    });
  };

  return (
    <button
      id={name}
      title={title}
      className={
        currentWidget !== name ? name + " widgetBtn" : name + " widgetBtn active"
      }
      onClick={() => widgetBtnClick()}
    >
      <img src={dot + "./svg/" + svg} />
    </button>
  );
}

export default WidgetButton;
