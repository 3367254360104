/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

export const weatherProforma = [
    ["DateTime", "WS", "PlaceHolder1", "PlaceHolder2", "SWH", "MWP", "WD"],
    ["01/01/1986 00:00", "6.37", "0", "0", "3.55", "5.63", "216.2"],
    ["01/01/1986 01:00", "6.62", "0", "0", "3.55", "5.45", "218.2"],
    ["01/01/1986 02:00", "6.73", "0", "0", "3.52", "5.31", "225.1"],
    ["01/01/1986 03:00", "6.97", "0", "0", "3.48", "5.11", "225.0"],
    ["01/01/1986 04:00", "7.26", "0", "0", "3.42", "4.86", "221.12"],
    ["01/01/1986 05:00", "7.23", "0", "0", "3.36", "4.81", "217.4"]
]

