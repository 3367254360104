/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import {
  getProducts,
  getStripePayments,
} from "@invertase/firestore-stripe-payments";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
//import { getFirestore } from "firebase/firestore";
// import { getFunctions } from "firebase/functions";
import firebase from "firebase/compat/app";

import { useCookies, Cookies } from "react-cookie";

import {
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInAnonymously,
  OAuthProvider,
} from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};

const cookieMonster = new Cookies();

// Firebase Providers
const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    if (cookieMonster.get("newsletter")) {
      await updateDoc(doc(db, "userAccounts", "newsletter"), {
        addresses: arrayUnion(user.email),
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const microsoftProvider = new OAuthProvider("microsoft.com");
const signInWithMicrosoft = async () => {
  try {
    const res = await signInWithPopup(auth, microsoftProvider);
    const user = res.user;

    if (cookieMonster.get("newsletter")) {
      await updateDoc(doc(db, "userAccounts", "newsletter"), {
        addresses: arrayUnion(user.email),
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;

    if (cookieMonster.get("newsletter")) {
      await updateDoc(doc(db, "userAccounts", "newsletter"), {
        addresses: arrayUnion(user.email),
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  auth.signOut();
};

const signInWithAnonymous = async () => {
  try {
    await signInAnonymously(auth);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const getProductsStripe = async (test) => {
  try {
    return await getProducts(test, {
      includePrices: true,
      activeOnly: true,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const payments = getStripePayments(app, {
  productsCollection: "products",
  customersCollection: "customers",
});

//const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);

const docRef = doc(db, "accessControl", "vekta-digital-plus");
const accessList = await getDoc(docRef);

const registerRef = doc(db, "userSharing", "CompanyRegister");
const compnayRegister = await getDoc(registerRef);

const analytics = getAnalytics(app);

// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6Le6VzcpAAAAALj5xJN_8lvFfOsP1gMELkVU-uhx'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true
// });

// if (window.location.hostname === "localhost") {
//   // connectFirestoreEmulator(db, "localhost", 8282);
//   // getFunctions(app, "us-central1")
//   connectFunctionsEmulator(getFunctions(app, "us-central1"), "127.0.0.1", 5001);
// }

// Exports
export {
  app,
  auth,
  storage,
  db,
  firebase,
  analytics,
  accessList,
  compnayRegister,
  payments,
  getProductsStripe,
  signInWithGoogle,
  signInWithMicrosoft,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  signInWithAnonymous,
  logout,
};
//export const db = getFirestore(app);
//export const functions = getFunctions(app);
