/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useState } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import WindPowerIcon from "@mui/icons-material/WindPower";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrawPolygon as DrawPolygonIcon,
  faMousePointer as SelectIcon,
  faMapMarkerAlt as LocationIcon,
  faTrash as DeleteIcon, // Import the trash (delete) icon
  faAngleDoubleUp as MinimiseIcon,
  faRuler as Measure,
  faCircleXmark as ClearMeasures,
  faPencil,
  faWandMagicSparkles,
  faPenSquare,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";

import { faSquare as RectangleIcon } from "@fortawesome/free-regular-svg-icons";

import {
  Adjust as PointIcon,
  Grain as MultiSelectIcon,
  Timeline as LineIcon,
  ControlCamera as MoveIcon,
  OpenWith as MultiMoveIcon,
  Draw as DrawIcon,
} from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";
import { clearMeasurements } from "./drawFunctions";
import { SecButton } from "../../styles/styles";
import SiteBuilderUpload from "./SiteBuilderUpload";
import { PlatformData } from "../../imports/Context";
import { useContext } from "react";

const ToolBox = styled.div`
  position: fixed;
  top: 50%;
  right: 10px;
  transform: translate(0, -50%);
  border-radius: 5px;
  border: 1px solid lightgray;
  /* width:47px; */
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const SubToolBox = styled.div`
  position: fixed;
  top: ${(props) =>
    props.pos ? props.pos.y / 10 + props.pos.height / 2 + "%" : "0px"};
  right: 47px;
  transform: translate(0, -50%);
  border-radius: 8px 0 0 8px;
  border: 1px solid lightgray;
  border-right: none;
  background-color: white;
  padding: 8px;
  z-index: 1000;
  width: 160px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledIconButton = styled(IconButton)`
  /* width:35px;
  height:35px; */
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #f5f5f5 !important;
    transform: scale(1.1);
  }
`;

function DrawControlPanel({ setMode, mode, map }) {
  const [minimised, setMinimised] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const { platformData } = useContext(PlatformData);

  const handleMinimise = () => {
    setMode("minimise");
    setMinimised(true);
  };

  const handleModeChange = (selection) => {
    // const measure = document.getElementById("polyline-measure-control")

    // if (measure && measure.classList.contains("polyline-measure-controlOnBgColor")) {
    //   measure.click()
    // }
    if (selection !== "edit") {
      map.pm.disableGlobalEditMode();
    }
    setMode(selection);
  };

  return (
    <ToolBox className="drawControls">
      {!minimised ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <StyledIconButton
            className="draw_select"
            active={mode === "select"}
            onClick={() => handleModeChange("select")}
            title="Select"
          >
            <FontAwesomeIcon icon={SelectIcon} />
            {/* </StyledIconButton>
            <StyledIconButton active={mode === "multiSelect"} onClick={() => setMode("multiSelect")} title="Multi Select">
            <MultiSelectIcon /> */}
          </StyledIconButton>
          <StyledIconButton
            className="draw_move"
            active={mode === "move"}
            onClick={() => handleModeChange("move")}
            title="Move"
          >
            <MoveIcon />
          </StyledIconButton>
          <Divider />
          {/* <StyledIconButton active={mode === "multiMove"} onClick={() => setMode("multiMove")} title="Multi Move">
            <MultiMoveIcon />
          </StyledIconButton> */}
          <StyledIconButton
            disabled={platformData.layerControl.layerOrder[0].tree.length === 0}
            className="draw_point"
            active={mode === "point" || mode === "snapPoint"}
            onClick={() => handleModeChange("point")}
            title="Draw Point"
          >
            <PointIcon />
          </StyledIconButton>
          <StyledIconButton
            disabled={platformData.layerControl.layerOrder[0].tree.length === 0}
            className="draw_line"
            active={mode === "line" || mode === "snapLine"}
            onClick={() => handleModeChange("line")}
            title="Draw Line"
          >
            <LineIcon />
          </StyledIconButton>
          <StyledIconButton
            disabled={platformData.layerControl.layerOrder[0].tree.length === 0}
            className="draw_rectangle"
            active={mode === "rectangle"}
            onClick={() => handleModeChange("rectangle")}
            title="Draw Rectangle"
          >
            <FontAwesomeIcon icon={RectangleIcon} />
          </StyledIconButton>
          <StyledIconButton
            disabled={platformData.layerControl.layerOrder[0].tree.length === 0}
            className="draw_polygon"
            active={mode === "polygon" || mode === "snapPolygon"}
            onClick={() => handleModeChange("polygon")}
            title="Draw Polygon"
          >
            <FontAwesomeIcon icon={DrawPolygonIcon} />
          </StyledIconButton>
          <StyledIconButton
            disabled={platformData.layerControl.layerOrder[0].tree.length === 0}
            className="edit_shape"
            active={mode === "edit"}
            onClick={() => handleModeChange("edit")}
            title="Edit Shape"
          >
            <FontAwesomeIcon icon={faPenToSquare} />
          </StyledIconButton>
          <Divider />

          <StyledIconButton
            disabled={platformData.layerControl.layerOrder[0].tree.length === 0}
            className="draw_site"
            active={
              mode === "siteBuilder" ||
              mode.includes("cables") ||
              mode.includes("osp") ||
              mode.includes("cluster")
            }
            onClick={() => handleModeChange("siteBuilder")}
            title="Site Builder"
          >
            <WindPowerIcon id={"draw_site"} fontSize="medium" />
          </StyledIconButton>
          {(mode === "siteBuilder" ||
            mode.includes("cables") ||
            mode.includes("osp") ||
            mode.includes("cluster")) && (
            <SubToolBox
              pos={document.getElementById("draw_site").getBoundingClientRect()}
            >
              <SecButton
                style={{ width: "100%" }}
                active={mode === "siteBuilder_upload"}
                onClick={() => setUploadOpen(true)}
                title="Upload a Site"
              >
                Upload Site
              </SecButton>
              <SecButton
                style={{ width: "100%" }}
                active={mode === "siteBuilder"}
                onClick={() => handleModeChange("siteBuilder")}
                title="Build a Site"
              >
                Build Site
              </SecButton>
              <div style={{ border: "2px solid #00A0C6", borderRadius: "8px" }}>
                <SecButton
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: "none",
                  }}
                  // active={mode === "cables"}
                  onClick={() => handleModeChange("osp")}
                  title="OSP Options"
                >
                  Place OSP
                </SecButton>
                {mode.includes("osp") && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <StyledIconButton
                      active={mode === "siteBuilder_draw_osp"}
                      onClick={() => handleModeChange("siteBuilder_draw_osp")}
                      title="OSP Draw"
                    >
                      {/* Draw Cables */}
                      <FontAwesomeIcon icon={faPencil} />
                    </StyledIconButton>
                    <StyledIconButton
                      active={mode === "siteBuilder_auto_osp"}
                      onClick={() => handleModeChange("siteBuilder_auto_osp")}
                      title="Automatic OSP Placement"
                    >
                      {/* Automatic Cables */}
                      <FontAwesomeIcon icon={faWandMagicSparkles} />
                    </StyledIconButton>
                  </div>
                )}
              </div>

              <div
                style={{
                  border: "2px solid rgb(215 215 215)",
                  borderRadius: "8px",
                }}
              >
                <SecButton
                  disabled
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: "none",
                    opacity: "0.7",
                    background: "#cdcdcd5e",
                  }}
                  // active={mode === "cables"}
                  onClick={() => handleModeChange("cluster")}
                  title="Purchase Vekta Digital + to access"
                >
                  Create Turbine Cluster
                </SecButton>
                {mode.includes("cluster") && null && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <StyledIconButton
                      active={mode === "cluster_draw"}
                      onClick={() => handleModeChange("cluster_draw")}
                      title="Cluster Draw"
                    >
                      {/* Draw Cables */}
                      <FontAwesomeIcon icon={faPencil} />
                    </StyledIconButton>
                    <StyledIconButton
                      active={mode === "cluster_auto"}
                      onClick={() => handleModeChange("cluster_auto")}
                      title="Automatic Clustering"
                    >
                      {/* Automatic Cables */}
                      <FontAwesomeIcon icon={faWandMagicSparkles} />
                    </StyledIconButton>
                  </div>
                )}
              </div>

              <div style={{ border: "2px solid #00A0C6", borderRadius: "8px" }}>
                <SecButton
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    border: "none",
                  }}
                  // active={mode === "cables"}
                  onClick={() => handleModeChange("cables")}
                  title="Cable Options"
                >
                  Place Cables
                </SecButton>
                {mode.includes("cables") && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <StyledIconButton
                      active={mode === "siteBuilder_draw_cables"}
                      onClick={() =>
                        handleModeChange("siteBuilder_draw_cables")
                      }
                      title="Cable Draw"
                    >
                      {/* Draw Cables */}
                      <FontAwesomeIcon icon={faPencil} />
                    </StyledIconButton>

                    <div title="Purchase Vekta Digital + to access">
                      <StyledIconButton
                        disabled
                        active={mode === "siteBuilder_auto_cables"}
                        onClick={() =>
                          handleModeChange("siteBuilder_auto_cables")
                        }
                        title="Purchase Vekta Digital + to access"
                      >
                        {/* Automatic Cables */}
                        <FontAwesomeIcon icon={faWandMagicSparkles} />
                      </StyledIconButton>
                    </div>
                  </div>
                )}
              </div>
            </SubToolBox>
          )}

          <StyledIconButton
            className="draw_location"
            active={mode === "locationMarker"}
            onClick={() => handleModeChange("locationMarker")}
            title="Location Marker"
          >
            <FontAwesomeIcon icon={LocationIcon} />
          </StyledIconButton>
          <Divider />
          <StyledIconButton
            className="draw_delete"
            active={mode === "delete"}
            onClick={() => handleModeChange("delete")}
            title="Delete Layer"
          >
            <FontAwesomeIcon icon={DeleteIcon} />
          </StyledIconButton>

          <StyledIconButton
            active={mode === "minimise"}
            onClick={handleMinimise}
            title="Minimise Tools"
          >
            <FontAwesomeIcon icon={MinimiseIcon} />
          </StyledIconButton>
        </div>
      ) : (
        <StyledIconButton
          active={mode === "maximise"}
          onClick={() => setMinimised(false)}
          title="Show Draw Tools"
        >
          <DrawIcon />
        </StyledIconButton>
      )}

      <SiteBuilderUpload
        uploadOpen={uploadOpen}
        setUploadOpen={setUploadOpen}
      />
    </ToolBox>
  );
}

export default DrawControlPanel;
