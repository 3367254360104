import React, { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import styled from "styled-components";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, logout, compnayRegister, app } from "../../../../firebase";
import {
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  updateDoc,
  deleteField,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import Modal from "react-modal";
import { PlatformData } from "../../../../imports/Context";

import IconButton from "@mui/material/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical as ActionControl,
  faShareFromSquare as ShareControl,
  faComments as CommentControl,
  faLock,
  faLockOpen,
  faEye,
  faEyeSlash,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen as OpenControl } from "@fortawesome/free-regular-svg-icons";
import { useSearchParams } from "react-router-dom";

import SettingsMenu from "../../common/SettingsMenu";
import { getFunctions, httpsCallable } from "firebase/functions";
import { handleLoad } from "../helperFunctions";
import ContextMenu from "../../common/ContextMenu";
import { Chips } from "primereact/chips";
import { source } from "leaflet.wms";

const Expanded = styled.div`
  border-radius: 0 0 5px 5px;
  border: none;
  background-color: #e9e9e9;
  padding: 8px;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: rgba(0, 158, 198, 0.255) !important;
    transform: scale(1.1);
  }
`;

function Scenario({
  data,
  setCommentPop,
  projectIds,
  type,
  setSaveLoadShare,
  setPromptStatus,
}) {
  const [user, loading, error] = useAuthState(auth);
  const { platformData, setPlatformData } = useContext(PlatformData);

  const [isSaving, setIsSaving] = useState("initial");

  const [userSaves, setUserSaves] = useState({ personal: [], shared: {} });
  const [userAccount, setUserAccount] = useState({ file: null, fileData: {} });
  const [searchParams, setSearchParams] = useSearchParams();

  const [savedProjects, setSavedProjects] = useState([]);
  const [shareSettings, setShareSettings] = useState({
    users: [],
    comment: null,
    shareType: "Master",
  });

  const [sharePop, setSharePop] = useState({ open: false, key: null });
  const [settingsMenu, setSettingsMenu] = useState({
    open: null,
    row: null,
    source: null,
  });

  useEffect(() => {
    setSavedProjects(
      data.scenario.ids.map((scn, i) => {
        return {
          id: scn,
          name: data.scenario.baseScenario[i] ? (
            <div>
              {scn} - Base Scenario <FontAwesomeIcon icon={faLock} />
            </div>
          ) : (
            <div>
              {scn}{" "}
              {data.scenario.locked[i] && <FontAwesomeIcon icon={faLock} />}{" "}
              {data.scenario.hidden[i] && <FontAwesomeIcon icon={faEyeSlash} />}
            </div>
          ),
          date: data.scenario.dates[i],
          createdBy:
            user.email === data.scenario.createdBy[i]
              ? "Me"
              : data.scenario.createdBy[i],
          // tags: <Chips pt={{
          //   container: { style: { display: "flex", alignItems: "center", maxHeight: "50px", background: "none", border: "none" } },
          //   token: { style: { border: "2px solid #00a0c6", background: "none" } }
          // }} max={3} readOnly value={data.scenario.tags[i]} />,
          base: data.scenario.baseScenario[i],
          locked: data.scenario.locked[i],
          hidden: data.scenario.hidden[i],
        };
      })
    );
  }, [data]);

  const handleDelete = (row, project) => {
    const functions = getFunctions(app, "us-central1");
    const fullDelete = httpsCallable(functions, "deleteScenario");

    let location = "personal";
    if (projectIds.shared.includes(project)) {
      location = "shared";
    }

    fullDelete({ location: location, project: project, scenario: row.id })
      .then((result) => {
        // Read result of the Cloud Function.
        setSaveLoadShare(true);
        setPromptStatus({
          success: result.data,
          message: "Scenario Successfully Deleted",
        });
        // setMessageSent(result.data.success)
        // setComment('')
        // setMessageLoading(!result.data.success)
        // handleCloseModal(); // Close modal on successful comment submission
      })
      .catch((error) => {
        // Getting the Error details.
        setSaveLoadShare(true);
        setPromptStatus({
          success: false,
          message: "Scenario Deletion Failed - Try again",
        });
        console.log(error.code);
      });
  };

  const handleLockHide = async (type, row, project, current) => {
    const scenario = doc(
      db,
      "userSaves",
      user.uid,
      "personal",
      project,
      "scenarios",
      row.id
    );

    await updateDoc(scenario, {
      [type]: !current,
    });
  };

  // const handleShare = async () => {
  //   try {
  //     if (!user.isAnonymous) {
  //       document.getElementById("saveLoadBanner").innerHTML =
  //         "Project Shared Successfully";
  //       setIsSaving("loading");

  //       for (let i = 0; i < shareSettings.users.length; i++) {
  //         const userShare = shareSettings.users[i];
  //         const docRef = doc(db, "userSaves", userShare);

  //         var date = new Date();
  //         const dateTime =
  //           date.getDate() +
  //           "/" +
  //           (date.getMonth() + 1) +
  //           "/" +
  //           date.getFullYear() +
  //           " @ " +
  //           date.getHours() +
  //           ":" +
  //           date.getMinutes() +
  //           ":" +
  //           date.getSeconds();

  //         const sharedUserSaves = await getDoc(docRef);

  //         var commentControl = [
  //           {
  //             commentBy:
  //               userAccount.fileData.firstname +
  //               " " +
  //               userAccount.fileData.lastname,
  //             comment: shareSettings.comment,
  //             time: dateTime,
  //           },
  //         ];
  //         if (sharedUserSaves.data().shared.hasOwnProperty([sharePop.key])) {
  //           if (sharedUserSaves.data().shared[sharePop.key].comments) {
  //             commentControl = [
  //               ...sharedUserSaves.data().shared[sharePop.key].comments,
  //               {
  //                 commentBy:
  //                   userAccount.fileData.firstname +
  //                   " " +
  //                   userAccount.fileData.lastname,
  //                 comment: shareSettings.comment,
  //                 time: dateTime,
  //               },
  //             ];
  //           }
  //         }

  //         const new_data = {
  //           shared: {
  //             ...sharedUserSaves.data().shared,
  //             [sharePop.key]: {
  //               save: userSaves.fileData.personal[sharePop.key].save,
  //               lastSaved: dateTime,
  //               sharedBy:
  //                 userAccount.fileData.firstname +
  //                 " " +
  //                 userAccount.fileData.lastname,
  //               versionType: shareSettings.shareType,
  //               comments: commentControl,
  //             },
  //           },
  //         };

  //         await setDoc(docRef, new_data, { merge: true });
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleComment = async (ref, name, projName) => {
    const activeComments = onSnapshot(
      doc(ref, "scenarios", name, "comments", "comments"),
      (snap) => {
        let data = [];
        if (snap.data()) {
          data = snap.data().comments;
        }

        setCommentPop({
          comments: data,
          open: true,
          scenario: name,
          project: projName,
          ref: ref,
          snapshot: activeComments,
        });
      }
    );
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.base,
      style: {
        backgroundColor: "#009ec641",
        // color: 'white',
        // '&:hover': {
        //   cursor: 'pointer',
        // },
      },
    },
  ];

  const savedTableColumns = [
    {
      name: "Scenario Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 2,
      style: {
        // fontSize: '12px',
        fontWeight: 600,
      },
    },
    // {
    //   name: "Tags",
    //   selector: (row) => row.tags,
    //   sortable: true,
    // },
    {
      name: "Last Updated",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Edited By",
      selector: (row) => row.createdBy,
      sortable: true,
    },
    {
      cell: (row) => (
        <StyledIconButton
          title="Open"
          onClick={async () => {
            setSearchParams({
              ...searchParams,
              projectName: data.name + " - " + row.name,
            });
            setSaveLoadShare(true);
            setPromptStatus(
              await handleLoad(user, data.ref, row.id, setPlatformData)
            );
          }}
        >
          <FontAwesomeIcon size="xs" icon={OpenControl} />
        </StyledIconButton>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
    // ...type === "personal" ?
    //   [{
    //     cell: (row) => (
    //       <StyledIconButton title="Share">
    //         <FontAwesomeIcon icon={ShareControl} />
    //       </StyledIconButton>
    //     ),
    //     allowOverflow: true,
    //     button: true,
    //     width: "56px",
    //   }] : [],
    // {
    //   cell: (row) => (
    //     <StyledIconButton
    //       title="Comments"
    //       onClick={() => handleComment(data.ref, row.id, data.name)}
    //     >
    //       <FontAwesomeIcon size="xs" icon={CommentControl} />
    //     </StyledIconButton>
    //   ),
    //   allowOverflow: true,
    //   button: true,
    //   width: "56px",
    // },
    ...(type === "personal"
      ? [
          {
            cell: (row) =>
              // <SettingsMenu
              //   row={row}
              //   onDeleteRow={handleDelete}
              //   project={data.name}
              // />
              [
                <StyledIconButton
                  title="Scenario Settings"
                  onClick={(e) => {
                    setSettingsMenu({
                      open: e.currentTarget,
                      row: row,
                      source: e.target,
                    });
                  }}
                >
                  <FontAwesomeIcon size="xs" icon={ActionControl} />
                </StyledIconButton>,
                <ContextMenu
                  status={settingsMenu.source}
                  item={null}
                  reset={() =>
                    setSettingsMenu((settingsMenu) => ({
                      ...settingsMenu,
                      open: null,
                      source: null,
                    }))
                  }
                  sectionOne={
                    !settingsMenu?.row?.base && null
                      ? [
                          {
                            name: (
                              <div>
                                <strong>
                                  {!settingsMenu?.row?.locked
                                    ? "Lock"
                                    : "Unlock"}
                                </strong>{" "}
                                <FontAwesomeIcon
                                  size="xs"
                                  icon={
                                    !settingsMenu?.row?.locked
                                      ? faLock
                                      : faLockOpen
                                  }
                                />
                              </div>
                            ),
                            callback: () => {
                              handleLockHide(
                                "locked",
                                row,
                                data.name,
                                settingsMenu.row.locked
                              );
                              setSettingsMenu((settingsMenu) => ({
                                ...settingsMenu,
                                open: null,
                                source: null,
                              }));
                            },
                          },
                          {
                            name: (
                              <div style={{ width: "100%" }}>
                                <strong>Hide</strong>{" "}
                                <FontAwesomeIcon
                                  size="xs"
                                  icon={
                                    settingsMenu?.row?.hidden
                                      ? faEye
                                      : faEyeSlash
                                  }
                                />
                              </div>
                            ),
                            callback: () => {
                              handleLockHide(
                                "hidden",
                                row,
                                data.name,
                                settingsMenu.row.hidden
                              );
                              setSettingsMenu((settingsMenu) => ({
                                ...settingsMenu,
                                open: null,
                                source: null,
                              }));
                            },
                          },
                        ]
                      : []
                  }
                  sectionTwo={[
                    {
                      name: (
                        <div style={{ width: "100%" }}>
                          <strong>Delete</strong>{" "}
                          <FontAwesomeIcon size="xs" icon={faTrash} />
                        </div>
                      ),
                      callback: () => {
                        handleDelete(row, data.name);
                        setSettingsMenu((settingsMenu) => ({
                          ...settingsMenu,
                          open: null,
                          source: null,
                        }));
                      },
                    },
                  ]}
                />,
              ],
            allowOverflow: true,
            button: true,
            width: "56px",
          },
        ]
      : []),
  ];

  return (
    <Expanded>
      <DataTable
        columns={savedTableColumns}
        data={savedProjects}
        conditionalRowStyles={conditionalRowStyles}
      />
    </Expanded>
  );
}

export default Scenario;
