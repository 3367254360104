import React, { useState, useEffect, useContext } from "react";
import { Mention, MentionsInput } from "react-mentions";
import Style from "./Mentions.module.css"; // Import the provided default styles
// import Map from './Map'; // Assuming MapWithMarker component is in a separate file
import styled from "styled-components";
import { PlatformData } from "../../../imports/Context";

import $ from "jquery";
import jstree from "jstree";
import { StyledIconButton } from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 0;
  left: 40px;
`;

const TextBoxWrapper = styled.div`
  position: absolute;
  /* right: 50%; */
  top: 50%;
  width: 100%;
  height: 20px;
  font-size: 16px;
`;

const SearchResponse = styled.div`
  /* padding: 8px; */
  background-color: white;
  position: relative;
  left: 0px;
  padding: 8px;
  border-radius: 0px 0px 8px 8px;
`;

const TableRow = styled.tr`
  cursor: pointer;
  background-color: transparent;
  border-top: 1px solid black;
  border-bottom: 1px solid black;

  :hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

function MentionsTextBox({
  onItemSelected,
  selectedMentionGroup,
  setSelectedMentionGroup,
  map,
}) {
  const [comment, setComment] = useState(null);
  const [response, setResponse] = useState(null);

  const { platformData, setPlatformData } = useContext(PlatformData);

  const colours = {
    Wind: "#00A0C6",
    Solar: "#FFEA00",
    Hydro: "#00008B",
    Geothermal: "#ff0000",
    Nuclear: "#000000",
  };
  const defaultMentionStyle = {
    backgroundColor: colours[selectedMentionGroup],
    opacity: 0.3,
    borderRadius: 8,
    fontWeight: "bold",
    textAligin: "center",
    height: "50px",
  };

  const data = [
    {
      id: "https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_GLOBAL_EnergyTracker_Wind",
      display: "Wind",
    },
    {
      id: "https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_Global_EnergyTracker_Solar",
      display: "Solar",
    },
    {
      id: "https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_GLOBAL_EnergyTracker_Hydro",
      display: "Hydro",
    },
    {
      id: "https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_GLOBAL_EnergyTracker_Geothermal",
      display: "Geothermal",
    },
    {
      id: "https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_GLOBAL_EnergyTracker_Nuclear",
      display: "Nuclear",
    },
    // {
    //   id: "https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_ports_api",
    //   display: "Ports"
    // }
  ];

  const [selectedItem, setSelectedItem] = useState(null);

  const handleRowClick = (item) => {
    // If the clicked item is already selected, close it
    if (selectedItem === item) {
      setSelectedItem(null); // Reset selectedItem to null
    } else {
      setSelectedItem(item); // Set selectedItem to the clicked item
      // Construct the JSON object containing the name, lat, and lng properties of the selected item
      const selectedMention = {
        name: item.name,
        lat: item.lat,
        lng: item.lng,
      };
      onItemSelected(selectedMention, selectedMentionGroup);

      setResponse(null);
    }
  };

  useEffect(() => {
    if (comment !== null) {
      const textAfterAt = extractTextAfterAt(comment);
      const textInParentheses = extractTextInParentheses(comment);

      if (textAfterAt && textInParentheses) {
        searchAPI(textAfterAt, textInParentheses);
      }

      if (textAfterAt === "") {
        setResponse(null);

        // Clear all layers from the 'windfarm_search' pane
        map?.eachLayer(function (layer) {
          if (
            layer._name === "Renewable_Projects:GLOBAL_Wind-Power-Tracker" ||
            layer._name === "Renewable_Projects:GLOBAL_Solar-Power-Tracker" ||
            layer._name === "Renewable_Projects:GLOBAL_Hydropower-Tracker" ||
            layer._name ===
              "Renewable_Projects:GLOBAL_Geothermal-Power-Tracker" ||
            layer._name === "Renewable_Projects:GLOBAL_Nuclear-Power-Tracker"
          ) {
            map.removeLayer(layer);
          }
        });

        const active = platformData.layerControl.activeLayers;

        setPlatformData((platformData) => ({
          ...platformData,
          layerControl: {
            ...platformData.layerControl,
            activeLayers: active.filter(
              (layer) =>
                layer.id !== "Wind_Farms:EUR_Offshore-Wind-Farms" &&
                layer.id !==
                  "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Polygon" &&
                layer.id !==
                  "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Onshore"
            ),
          },
        }));
      }
    }
  }, [comment]); // Run the effect when the comment state changes

  const handleInputChange = (e) => {
    setSelectedItem(null); // Reset selectedItem to null
    setComment(e.target.value);
  };

  const extractTextAfterAt = (str) => {
    // Remove "@" symbol, text within square brackets, and text within parentheses
    return str
      .replace(/@/, "")
      .replace(/\[.*?\]/g, "")
      .replace(/\(.*?\)/g, "")
      .trim();
  };

  // Function to extract text inside parentheses
  const extractTextInParentheses = (str) => {
    const matches = str.match(/\((.*?)\)/);
    return matches ? matches[1] : null;
  };

  const searchAPI = async (textAfterAt, textInParentheses) => {
    try {
      const response = await fetch(textInParentheses, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query_string: textAfterAt }),
      });
      const output = await response.json();
      setResponse(output);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="advanced_search">
      <Container>
        <TextBoxWrapper>
          {/* <div className="App"> */}
          <MentionsInput
            className="mentions"
            classNames={Style}
            style={
              response
                ? { backgroundColor: "white", borderRadius: "8px 8px 0px 0px" }
                : { backgroundColor: "white", borderRadius: "8px" }
            }
            type="text"
            singleLine
            value={comment ? comment : ""}
            onChange={handleInputChange}
            hasText={comment ? comment.length > 0 : false} // Pass a prop indicating whether there's text in the input
            placeholder="@Type + Search Input"
          >
            <Mention
              data={data}
              style={defaultMentionStyle}
              onAdd={(id, display) => setSelectedMentionGroup(display)}
            />
          </MentionsInput>
          {comment && comment.length > 0 && (
            <StyledIconButton
              style={{
                position: "absolute",
                right: "8px",
                top: "12px",
                zIndex: "1",
              }}
              onClick={() => setComment(null)}
            >
              <FontAwesomeIcon icon={faClose} />
            </StyledIconButton>
          )}
          {response && (
            <SearchResponse>
              {/* {selectedItem ? null : ( */}
              <table style={{ width: "100%" }}>
                <thead>
                  <tr></tr>
                </thead>
                <tbody>
                  {response.map((item, index) => (
                    <TableRow key={index} onClick={() => handleRowClick(item)}>
                      <td style={{ padding: "8px" }}>{item.name}</td>
                    </TableRow>
                  ))}
                </tbody>
              </table>
              {/* )} */}
            </SearchResponse>
          )}
          {/* <div>
            </div> */}
          {/* </div> */}
        </TextBoxWrapper>
      </Container>
      {/* <Map coordinates={selectedObject} /> */}
    </div>
  );
}

export default MentionsTextBox;
