const apiHeaders = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
    'Accept': 'application/json',
    'Content-Type': 'application/json'
};

export const getWindRoseAt = async (lat, lng, platformData, setPlatformData) => {
    const weatherJson = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                lng,
                lat
            ]
        },
        "properties": {
            "name": "Sample Point",
            "dataloc": "gcp",
            "weatherdatatype": "windrose",
            "windheight": (isNaN(platformData.weatherAnalysis.windHeight)) ? 100 : Number(platformData.weatherAnalysis.windHeight)
        }
    }

    try {
        const windrose = await fetch(
            "https://vekdig-hiibq2h6dq-nw.a.run.app/weather",
            {
                method: "POST",
                headers: apiHeaders,
                body: JSON.stringify(weatherJson),
            }
        );

        const windRose = await windrose.json()

        if (windrose.status === 200) {
            setPlatformData((platformData) => ({
                ...platformData,
                ["activeWidget"]: "WeatherAnalysis",
                ["sidePanelOpen"]: true,
                ["weatherAnalysis"]: {
                    ...platformData.weatherAnalysis,
                    ["pointClick"]: [parseFloat(windRose.ERALong[0]), parseFloat(windRose.ERALat[0])],
                    ["windRoseData"]: windRose
                }
            }));
            return true
        } else {
            alert("Something went wrong, please try again")
            return false
        }
    } catch (err) {
        console.error(err)
    }
}

export const getWindSpeedAt = async (lat, lng, platformData, setPlatformData) => {
    const weatherJson = {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [
                lng,
                lat
            ]
        },
        "properties": {
            "name": "Sample Point",
            "dataloc": "gcp",
            "weatherdatatype": "avgwindspeed",
            "windheight": (isNaN(platformData.weatherAnalysis.windHeight)) ? 100 : Number(platformData.weatherAnalysis.windHeight)
        }
    }

    try {
        const windspeed = await fetch(
            "https://vekdig-hiibq2h6dq-nw.a.run.app/weather",
            {
                method: "POST",
                headers: apiHeaders,
                body: JSON.stringify(weatherJson),
            }
        );

        const windSpeed = await windspeed.json()

        if (windspeed.status === 200) {
            setPlatformData((platformData) => ({
                ...platformData,
                ["weatherAnalysis"]: {
                    ...platformData.weatherAnalysis,
                    ["windSpeedData"]: windSpeed
                }
            }));
            return true
        } else {
            alert("Something went wrong, please try again")
            return false
        }
    } catch (err) {
        console.error(err)
    }
}

export const getMarkerCoods = async (lat, lng) => {
    try {
        const coords = await fetch(
            "https://vekdig-hiibq2h6dq-nw.a.run.app/coordinates",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [lng, lat],
                    },
                    properties: {
                        name: "Sample Point",
                    },
                }),
            }
        );

        const coord = await coords.json()

        if (coords.status === 200) {
            return { data: coord, successful: true }
        } else {
            return { data: "Something went wrong, please try again", successful: false }
        }
    } catch (error) {
        console.error("Error:", error);
        return { data: "Something went wrong, please try again", successful: false }

    }
}

export const getPostcodes = async (lat, lng) => {
    try {
      const response = await fetch(`https://api.postcodes.io/postcodes?lon=${lng}&lat=${lat}&limit=1&radiaus=100&wideSearch=NULL`);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data.result; // Assuming the result is an array of postcodes, adjust as needed
    } catch (error) {
      console.error('Error fetching postcodes:', error.message);
      throw error; // Rethrow the error to handle it further upstream
    }
  };


export const callSearchWindfarmAPI = async (query) => {
    try {
        const response = await fetch('https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_windfarm_api', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query_string: query })
        })

        const output = await response.json()
        return output
    } catch (err) {
        console.error(err)
    }

}

export const getDominantWind = async (weatherJson) => {
    try {
        const domWind = await fetch(
            "https://vekdig-hiibq2h6dq-nw.a.run.app/weather",
            {
                method: "POST",
                headers: apiHeaders,
                body: JSON.stringify(weatherJson),
            }
        );

        const wind = await domWind.json()
        return wind

        // if (wind.status === 200) {
            // return domWind.json()
        // } else {
        //     alert("Something went wrong, please try again")
        //     return false
        // }
    } catch (err) {
        console.error(err)
        alert("Something went wrong, please try again")
        return false
    }
}

export const getTurbPlacement = async (fillType, siteInfoJson) => {
    try {
        const placements = await fetch(
            `https://vekwin-hiibq2h6dq-ez.a.run.app/${fillType}`,
            {
                method: "POST",
                headers: apiHeaders,
                body: JSON.stringify(siteInfoJson),
            }
        );

        const turbs = await placements.json()
        return turbs
        // if (turbs.status === 200) {
            // return turbs.json()
        // } else {
            // alert("Something went wrong, please try again")
            // return false
        // }
    } catch (err) {
        console.error(err)
        alert("Something went wrong, please try again")
        return false
    }
}