import React, { useState } from "react";

// VD Platform Features
import AIS from "../../media/Demo/ais.png";
import Bath from "../../media/Demo/bath.png";
import WindFarmSearch from "../../media/Demo/disipline.png";
import Elevation from "../../media/Demo/elevation.png";
import ElevationR1 from "../../media/Demo/elevationResults1.png";
import ElevationR2 from "../../media/Demo/elevationResults2.png";
import Persistence from "../../media/Demo/persistence.png";
import PersistenceResults from "../../media/Demo/persistenceReport.png";
import Ports from "../../media/Demo/ports.png";
import PowerInfra from "../../media/Demo/powerInfra.png";
import Weather from "../../media/Demo/weatherResults.png";
import Wind from "../../media/Demo/windClimate.png";
import YieldR1 from "../../media/Demo/yieldResults1.png";
import YieldR2 from "../../media/Demo/yieldResults2.png";

// VD+ Platform Features
import VD_Plus_UK from "../../media/Demo/VD+_uk_ireland.png";
import VD_Plus_Global from "../../media/Demo/VD+_new_global.png";
import VD_Plus_NEWA from "../../media/Demo/VD+_NEWA.png";
import VD_Plus_Compare from "../../media/Demo/VD+_yield_compare.png";
import VD_Plus_Turbine from "../../media/Demo/VD+_yield_turbines.png";
import VD_Plus_Modal from "../../media/Demo/VD+_yield_modal.png";
import VD_Plus_Neighboring from "../../media/Demo/VD+_yield_neighboring.png";
import VD_Plus_Map from "../../media/Demo/VD+_map.png";
import VD_Plus_Map_Output from "../../media/Demo/VD+_mapoutput.png";
import VD_Plus_PD_Home from "../../media/Demo/VD+_pd_home.png";
import VD_Plus_PD_Overview from "../../media/Demo/VD+_pd_overview.png";
import VD_Plus_PD_Programme from "../../media/Demo/VD+_pd_programme.png";
import VD_Plus_PD_Save from "../../media/Demo/VD+_saving.png";
import VD_Plus_PD_Share from "../../media/Demo/VD+_share.png";
import VD_Plus_PD_External from "../../media/Demo/VD+_external.png";
import VD_Plus_Weather_GWA from "../../media/Demo/VD+_weather_gwa.png";
import VD_Plus_Weather_NEWA from "../../media/Demo/VD+_weather_newa.png";
import VD_Plus_Weather_CSP from "../../media/Demo/VD+_weather_csp.png";
import VD_Plus_Weather_Merra from "../../media/Demo/VD+_weather_merra2.png";

import { ReactComponent as YieldIcon } from "../../media/Yield.svg";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import PopupVideo from "../modals/PopupVideo";

function ServicesHomeEight() {
  const settings = {
    // dots: true,
    // infinite: true,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    // autoplay: false,
    // speed: 1500,
    // cssEase: "linear",
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [toggleSwitch, setSwitchValue] = useState(true);

  const [showVideo, setVideoValue] = useState({ state: false, src: null });
  const handleShowVideo = (e) => {
    e.preventDefault();
    setVideoValue({ state: false, src: null });
  };

  const handleFullScreenImage = (img) => {
    setVideoValue({ state: true, src: img });
  };

  return (
    <>
      <section
        className="appie-services-2-area appie-services-8-area pt-185 pb-55"
        id="service"
      >
        <div className="container">
          <div className="row align-items-end">
            <div>
              <div className="appie-section-title">
                <h3 className="appie-title">
                  The comprehensive digital project solution
                </h3>
                <p>
                  Vekta Digital is a secure cloud based platform designed by
                  industry experts to revolutionise your project collaboration,
                  data management and engineering workflows. Our comprehensive
                  tool empowers you with: <br></br>
                  <br></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  A secure platform to develop your projects individually or as
                  part of a team. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Access to trusted data sets and GIS tools. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Tools that are continuously updated and developed by your
                  feedback. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  The ability to preserve knowledge, automate processes and
                  retain skills within your project. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br>
                  {/* <FontAwesomeIcon
                    style={{ width: "8px" }}
                    color="#00a0c6"
                    icon={faCircle}
                  />{" "}
                  Uses the secure Google Cloud eco system. <br></br>
                  <br
                    style={{
                      display: "block",
                      margin: "8px 0",
                      content: `" "`,
                    }}
                  ></br> */}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Platform Features</h3>
              </div>
              <ul
                className="list-inline text-center switch-toggler-list"
                role="tablist"
                id="switch-toggle-tab"
              >
                <li className={`month ${toggleSwitch ? "active" : ""}`}>
                  <a href="#">VD</a>
                </li>
                <li>
                  <label
                    onClick={() => setSwitchValue(!toggleSwitch)}
                    className={`switch ${toggleSwitch ? "on" : "off"}`}
                  >
                    <span
                      className="slider round"
                      style={{
                        background: "none",
                        boxShadow: "0px 0px 14px #898989",
                      }}
                    />
                  </label>
                </li>
                <li
                  className={`year ${toggleSwitch === false ? "active" : ""}`}
                >
                  <a href="#">VD+</a>
                </li>
              </ul>
            </div>
          </div>

          {toggleSwitch ? (
            <div className="row align-items-center">
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-earth-europe"></i>
                  </div>
                  <h4 className="title">Global Datasets</h4>
                  <p>
                    Bringing together a range of the most trusted global
                    datasets all in one place. Build a better understanding for
                    your area of interest by using the included GIS data or
                    uploading your custom layers.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Power Infrastructure</strong> – Global
                      transmission and distribution network
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Bathymetry</strong> – Worldwide model combining
                      GEBCO and EMODnet
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Wind Climate</strong> – Global Wind Atlas layer
                      heights from 10m - 200m
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(PowerInfra)}>
                      <label>Power Infrastructure Layers</label>
                      <img src={PowerInfra} />
                    </div>
                    <div onClick={() => handleFullScreenImage(Bath)}>
                      <label>Global Bathymetry</label>
                      <img src={Bath} />
                    </div>
                    <div onClick={() => handleFullScreenImage(Wind)}>
                      <label>Global Wind Climate</label>
                      <img src={Wind} />
                    </div>
                  </Slider>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(YieldR1)}>
                      <label>Yield Results</label>
                      <img src={YieldR1} />
                    </div>
                    <div onClick={() => handleFullScreenImage(YieldR2)}>
                      <label>Yield Results</label>
                      <img src={YieldR2} />
                    </div>
                  </Slider>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    item-6
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i>
                      <YieldIcon width={30} fill={"#f6ac0e"} />
                    </i>
                  </div>
                  <h4 className="title">Energy Yield</h4>
                  <p>
                    Estimate site yield, calculate Annual Energy Production,
                    Wake Effect and Average Capacity Factor within seconds. This
                    powerful tool helps you make informed decisions about site
                    layout, turbine choices and weather data.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Advanced Site Builder</strong> – Choose from
                      existing operational projects, upload your own or generate
                      a custom site.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Instant Calculations</strong> – Get fast and
                      accurate AEP and capacity factor
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Export Results</strong> – Easily download site
                      data and results to integrate into your workflow
                    </li>
                  </ul>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-cloud-sun-rain"></i>
                  </div>
                  <h4 className="title">Weather Analysis</h4>
                  <p>
                    Harness the power of ERA5, in collaboration with the
                    Copernicus Climate Change Service (C3S), or upload custom
                    weather data to view and interrogate.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Wind Rose</strong> – Select your weather point and
                      generate a wind rose in seconds.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Persistence Analysis</strong> – Quickly convert
                      site specific hindcast data and asses weather windows.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Downloadable Report</strong> – Generate a custom
                      persistence pdf report or export raw csv data.
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(Weather)}>
                      <label>Weather Analysis Results</label>
                      <img src={Weather} />
                    </div>
                    <div onClick={() => handleFullScreenImage(Persistence)}>
                      <label>Persistence Results</label>
                      <img src={Persistence} />
                    </div>
                    <div
                      onClick={() => handleFullScreenImage(PersistenceResults)}
                    >
                      <label>Persistence Report</label>
                      <img src={PersistenceResults} />
                    </div>
                  </Slider>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(Elevation)}>
                      <label>Elevation Run</label>
                      <img src={Elevation} />
                    </div>
                    <div onClick={() => handleFullScreenImage(ElevationR1)}>
                      <label>Elevation Results</label>
                      <img src={ElevationR1} />
                    </div>
                    <div onClick={() => handleFullScreenImage(ElevationR2)}>
                      <label>Slope Results</label>
                      <img src={ElevationR2} />
                    </div>
                  </Slider>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-arrow-down-up-across-line"></i>
                  </div>
                  <h4 className="title">Elevation Analysis</h4>
                  <p>
                    Detailed point or full site area information on offshore
                    water depth and onshore elevation profile.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Onshore & Offshore Data</strong> – The data used
                      is from GEBCO (General Bathymetric Chart of the Oceans)
                      and uses Mean Sea Level (MSL) measurement with 15
                      arc-second interval grids.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Slope Analysis</strong> – Evaluate the stability
                      of slopes of soil and rock-fill dams, embankments,
                      excavated slopes, and natural slopes.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Export Plots</strong> – Easily download elevation
                      charts to support your geospatial workflow.
                    </li>
                  </ul>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-magnifying-glass"></i>
                  </div>
                  <h4 className="title">Data Search</h4>
                  <p>
                    Easily find what matters to you with the built in search
                    feature:
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Global Port Search</strong> – The World Port Index
                      shows locations, physical characteristics, facilities, and
                      services offered by major ports around the world.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>AIS Data (Beta)</strong> – Visualise ship
                      movements with a 10 minutes refresh rate.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Power Plant Search (Beta)</strong> – Search
                      globally for an operational renewable project.
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(Ports)}>
                      <label>Port Search</label>
                      <img src={Ports} />
                    </div>
                    <div onClick={() => handleFullScreenImage(AIS)}>
                      <label>AIS Data</label>
                      <img src={AIS} />
                    </div>
                    <div onClick={() => handleFullScreenImage(WindFarmSearch)}>
                      <label>Renewable Energy Search</label>
                      <img src={WindFarmSearch} />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          ) : (
            <div className="row align-items-center">
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-earth-europe"></i>
                  </div>
                  <h4 className="title">Advanced Datasets</h4>
                  <p>
                    Building on top of the already extensive global datasets by
                    adding even more global layers along with detailed country
                    specific datasets.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong> UK & Ireland layers</strong> – Comprehensive
                      government and industry datasets complied from reliable
                      sources.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>New Global Data</strong> – Expanded global layers,
                      additional sources and detailed metadata.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Advanced Wind Models</strong> – Access GWA, NEWA
                      and CSP all in one place across a range of heights.
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(VD_Plus_UK)}>
                      <label>Global Wind Atlas Weibull K</label>
                      <img src={VD_Plus_UK} />
                    </div>
                    <div onClick={() => handleFullScreenImage(VD_Plus_Global)}>
                      <label>Celtic Sea Power Wind Modal</label>
                      <img src={VD_Plus_Global} />
                    </div>
                    <div onClick={() => handleFullScreenImage(VD_Plus_NEWA)}>
                      <label>New European Wind Atlas</label>
                      <img src={VD_Plus_NEWA} />
                    </div>
                  </Slider>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(VD_Plus_Compare)}>
                      <label>Neighboring Sites Popup</label>
                      <img src={VD_Plus_Compare} />
                    </div>
                    <div
                      onClick={() => handleFullScreenImage(VD_Plus_Neighboring)}
                    >
                      <label>Neighboring Sites Popup</label>
                      <img src={VD_Plus_Neighboring} />
                    </div>
                    <div onClick={() => handleFullScreenImage(VD_Plus_Turbine)}>
                      <label>Options for Turbine Selection</label>
                      <img src={VD_Plus_Turbine} />
                    </div>
                    <div onClick={() => handleFullScreenImage(VD_Plus_Modal)}>
                      <label>Wind Deficit Modals</label>
                      <img src={VD_Plus_Modal} />
                    </div>
                  </Slider>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    item-6
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i>
                      <YieldIcon width={30} fill={"#f6ac0e"} />
                    </i>
                  </div>
                  <h4 className="title">Advanced Energy Yield</h4>
                  <p>
                    Significantly enhanced wind yield tool which allows the user
                    to create flexible and accurate yield results while having
                    greater control over model types, turbine choices and
                    weather sources.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Comparison Tool</strong> – Easily analyse and
                      compare multiple sites with different site settings.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Neighboring Sites</strong> – Include nearby sites
                      in your analysis along with advanced loss settings
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Turbine Database</strong> – Access a large range
                      of turbines which now includes onshore models.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Advanced Settings</strong> – Now access different
                      wind models, weather sources and full power of Pywake.
                    </li>
                  </ul>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    item-3
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-cloud-sun-rain"></i>
                  </div>
                  <h4 className="title">Additional Weather Sources</h4>
                  <p>
                    Building on the power of ERA 5 you can now access weather
                    data from multiple reliable sources giving you much greater
                    flexibility and data accuracy.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Global Wind Atlas (GWA)</strong> – By including
                      the best of GWA data you can enhance your project data
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>New European Wind Atlas (NEWA)</strong> – Building
                      on the power of GWA with this comprehensive EU model
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Celtic Sea Power (CSP)</strong> – Bankable wind
                      model for the Celtic Sea region is now included
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Merra2</strong> – Complimentary to ERA 5 you can
                      now easily access this trusted dataset for comparison.
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div
                      onClick={() => handleFullScreenImage(VD_Plus_Weather_GWA)}
                    >
                      <label>Weather Analysis of GWA Data</label>
                      <img src={VD_Plus_Weather_GWA} />
                    </div>
                    <div
                      onClick={() =>
                        handleFullScreenImage(VD_Plus_Weather_NEWA)
                      }
                    >
                      <label>Weather Analysis of NEWA Data</label>
                      <img src={VD_Plus_Weather_NEWA} />
                    </div>
                    <div
                      onClick={() => handleFullScreenImage(VD_Plus_Weather_CSP)}
                    >
                      <label>Weather Analysis of CSP Data</label>
                      <img src={VD_Plus_Weather_CSP} />
                    </div>
                    <div
                      onClick={() =>
                        handleFullScreenImage(VD_Plus_Weather_Merra)
                      }
                    >
                      <label>Weather Analysis of MERRA 2 Data</label>
                      <img src={VD_Plus_Weather_Merra} />
                    </div>
                  </Slider>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(VD_Plus_Map)}>
                      <label>Map Drawing Settings</label>
                      <img src={VD_Plus_Map} />
                    </div>
                    <div
                      onClick={() => handleFullScreenImage(VD_Plus_Map_Output)}
                    >
                      <label>Map Drawing Downloaded</label>
                      <img src={VD_Plus_Map_Output} />
                    </div>
                  </Slider>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    item-4
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-map-location"></i>
                  </div>
                  <h4 className="title">Map Drawing</h4>
                  <p>
                    Produce a fully customised professional map drawing designed
                    by GIS engineers to match the specific needs of your
                    project.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Professional Template</strong> – Easily create
                      drawings for your project to share
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Fully Customisable</strong> – Simple to use tools
                      for adding company logos, defining metadata and template
                      layout.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Downloadable Drawing</strong> – Quickly export
                      your custom map drawing for sharing and reporting.
                    </li>
                  </ul>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    item-5
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-cloud-arrow-up"></i>
                  </div>
                  <h4 className="title">Advanced Sharing & Save Features</h4>
                  <p>
                    Collaboration at its best. Tailor sharing functions for your
                    project to fit your needs by defining what users see and
                    what scenarios they can view or edit.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Project Saves</strong> – Projects can now be saved
                      with greater ease using quick save and keyboard shortcuts
                      along with an advanced project control panel making it
                      easy to manage.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Company Sharing</strong> – Share a project and its
                      scenarios to your whole company or sub-groups within that
                      company.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>External Sharing</strong> – Invite sub contractors
                      and external stakeholders to collaborate on your project.
                    </li>
                  </ul>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(VD_Plus_PD_Save)}>
                      <label>Saving Options</label>
                      <img src={VD_Plus_PD_Save} />
                    </div>
                    <div
                      onClick={() => handleFullScreenImage(VD_Plus_PD_Share)}
                    >
                      <label>Share Settings</label>
                      <img src={VD_Plus_PD_Share} />
                    </div>
                    <div
                      onClick={() => handleFullScreenImage(VD_Plus_PD_External)}
                    >
                      <label>External Share Invitation</label>
                      <img src={VD_Plus_PD_External} />
                    </div>
                  </Slider>
                </div>
              </div>

              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div className="slider-container">
                  <Slider {...settings}>
                    <div onClick={() => handleFullScreenImage(VD_Plus_PD_Home)}>
                      <label>Project Portfolio</label>
                      <img src={VD_Plus_PD_Home} />
                    </div>
                    <div
                      onClick={() => handleFullScreenImage(VD_Plus_PD_Overview)}
                    >
                      <label>Project Specific Overview</label>
                      <img src={VD_Plus_PD_Overview} />
                    </div>
                    <div
                      onClick={() =>
                        handleFullScreenImage(VD_Plus_PD_Programme)
                      }
                    >
                      <label>Project Specific Programme</label>
                      <img src={VD_Plus_PD_Programme} />
                    </div>
                  </Slider>
                </div>
              </div>
              <div style={{ margin: "16px 0" }} className="col-lg-6">
                <div
                  className="
                    appie-single-service-2 appie-single-service-about
                    mt-30
                    wow
                    animated
                    fadeInUp
                  "
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-table-columns"></i>
                  </div>
                  <h4 className="title">Project Dashboard</h4>
                  <p>
                    Quickly and easily access your portfolio or single project
                    all in one place. Includes essential PM tools to help manage
                    your project workflow and coordinate with teams and clients.
                  </p>

                  <ul style={{ fontSize: "16px" }}>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>At a Glance</strong> – View your whole project
                      portfolio on a map with simple selection to view projects
                      in more detail. Easily export the summary into a
                      presentation or other documents.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Project Overview</strong> – One page view for a
                      project owner to get high level information like budget,
                      key dates and top risks.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Project Programme</strong> – Create a high level
                      programme with the built in tool and easily export the
                      data for use in tools like MS project and Primavera.
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ width: "8px" }}
                        color="#00a0c6"
                        icon={faCircle}
                      />{" "}
                      <strong>Assumption Book</strong> – Bring together all your
                      key engineering data, decisions, base case, options and
                      supporting documents all in one place for the whole team
                      to access.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
        {showVideo.state && (
          <PopupVideo
            imageSrc={showVideo.src}
            handler={(e) => handleShowVideo(e)}
          />
        )}
      </section>
    </>
  );
}

export default ServicesHomeEight;
