/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useContext, useEffect, useRef, useState } from 'react'
import Plot from 'react-plotly.js';
import { PlatformData } from '../../../imports/Context';
import Plotly from "plotly.js-basic-dist-min";

import styled from "styled-components"

const PlotBox = styled.div`
  position:relative;
  /* border:3px solid red; */
`


function ThreeDPlot(elevationData) {

  const { platformData } = useContext(PlatformData)
  const [elevationPlotData, setElevationPlotData] = useState([])
  const [slopePlotData, setSlopePlotData] = useState([])
  const [elevationLayout, setElevationLayout] = useState({})
  const [slopeLayout, setSlopeLayout] = useState({})

  const plotRef = useRef();

  useEffect(() => {
    if (elevationData && elevationData.data) {

      /// load in the data
      const data = elevationData.data
      const Longlabels = elevationData.Longlabels
      const Latlabels = elevationData.LatLabels

      /// This will give us the index values that we will
      /// place the lat long labels
      function createEqualIntervals(start, end, intervals) {
        const intervalSize = (end - start) / intervals;
        const equalIntervals = [];
        for (let i = 0; i <= intervals; i++) {
          const value = start + i * intervalSize;
          //const value2 = parseFloat(value).toFixed(2);
          equalIntervals.push(value);
        }
        return equalIntervals;
      }
      
      // all lat and longs
      let lats = {}
      let lngs = {}
      data.map(item => {
        const coord = item.geometry.coordinates
        const lat = coord[1]
        const lng = coord[0]
        lats[lat] = true
        lngs[lng] = true
      })

      // get the length of each (input for createEqualIntervals)
      const len_lats = Object.keys(lats).length
      const len_lngs = Object.keys(lngs).length

      // get the intervals needed (input for createEqualIntervals)
      const numberOfIntervals = 2;

      // get DtaLatIntervals & DtaLngIntervals.......
      const DtaLngintervals = createEqualIntervals(1, len_lngs, numberOfIntervals);
      var DtaLngintervals2 = DtaLngintervals.map(function (item) {
        return parseInt(item);
      });

      const DtaLatintervals = createEqualIntervals(1, len_lats, numberOfIntervals);
      var DtaLatintervals2 = DtaLatintervals.map(function (item) {
        return parseInt(item);
      });

      // new elevation layout

      const new_layout = {
        font: { size: 13 },
        height: "500px",
        autosize: true,
        legend: { font: { size: 13 } },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        // width: window.innerWidth * plotSizeX,
        // height: window.innerHeight * plotSizeY,
        // width: 500,
        // height: 500,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
        },
        scene: {
          yaxis: {
            title: "Longitude",
            tickvals : DtaLngintervals2,
            ticktext : Longlabels,
            ticklen: 1, // Adjust the length of tick marks
            ticktextdistance: 20
          },
          xaxis: { title: "Latitude",
          tickvals : DtaLatintervals2,
          ticktext : Latlabels,
          ticklen: 1, // Adjust the length of tick marks
          ticktextdistance: 20
        },
          zaxis: { title: "Elevation" },
          camera: {
            eye: {
              x: -1.7,
              y: -1,
              z: 1.5
            }
          }
        }
      }

      // new elevation layout

      const elevation_layout = {
        ...new_layout,
        ["title"]: "",
        ["scene"]: {
          ...new_layout["scene"],
          zaxis: { title: "Elevation" },
          aspectratio: {
            x: 1,
            y: (len_lngs / len_lats) * 1,
            z: 0.6
          },

        }
      }

      // new slope layout

      const slope_layout = {
        ...new_layout,
        ["title"]: "",
        ["scene"]: {
          ...new_layout["scene"],
          zaxis: { title: "Slope" },
          aspectratio: {
            x: 1,
            y: (len_lngs / len_lats) * 1,
            // z: 0.0001
          },
        }
      }


      setElevationLayout(elevation_layout)
      setSlopeLayout(slope_layout)

      const lat_keys = Object.keys(lats).sort((a, b) => (Number(a) - Number(b)))
      const lng_keys = Object.keys(lngs).sort((a, b) => (Number(b) - Number(a)))

      lat_keys.map((lat, index) => {
        lats[lat] = index
      })

      lng_keys.map((lng, index) => {
        lngs[lng] = index
      })

      let z_elevation = []
      let z_slope = []

      for (let i = 0; i < len_lngs; i++) {
        z_elevation.push([])
        z_slope.push([])
        for (let j = 0; j < len_lats; j++) {
          z_elevation[i].push(NaN)
          z_slope[i].push(NaN)

        }
      }

      let max_elevation = -Infinity
      let min_elevation = Infinity
      let max_slope = -Infinity
      let min_slope = Infinity

      data.map(item => {
        const coord = item.geometry.coordinates
        const elevation = item.Elevation
        const slope = item.slope
        const lat = coord[1]
        const lng = coord[0]

        const lngIndex = lngs[lng]
        const latIndex = lats[lat]

        z_elevation[lngIndex][latIndex] = Number(elevation)
        z_slope[lngIndex][latIndex] = Number(slope)

        if (elevation > max_elevation) {
          max_elevation = elevation
        }
        if (elevation < min_elevation) {
          min_elevation = elevation
        }

        if (slope > max_slope) {
          max_slope = slope
        }
        if (slope < min_slope) {
          min_slope = slope
        }

      })

      const mid = (Math.abs(min_elevation) / (max_elevation + Math.abs(min_elevation) + 0.00001))

      const bottom = [0, "black"]
      const deep_water = mid / 3
      const water = 2 * mid / 3
      const under_sea = mid
      const above_sea = mid + 0.1
      const land = mid + (1 - mid) * 1 / 3
      const high_land = mid + (1 - mid) * 2 / 3
      const top = [0, "green"]

      const colorscale = [
        [0, 'black'],   // -300, deep blue
        [deep_water, "darkblue"],
        [water, "blue"],
        [under_sea, "cyan"],
        [above_sea, "green"],
        [land, "yellow"],
        [high_land, "orange"],
        [1, 'red']  // 300, green
      ];

      // 
      var ele_text = z_elevation.map (function(z_elevation, i) { 
        return z_elevation.map (function (value, j) {
          if (value > 0) {
            return ` Elevation: ${value.toFixed(2)} `
          } else {
            return ` Depth: ${value.toFixed(2)} `
          } });
      });


      var slope_text = z_slope.map (function(z_slope, i) { 
        return z_slope.map (function (value, j) {
          return ` Slope: ${value.toFixed(2)} `
        });
      });


      const formattedElevationData = [{
        z: z_elevation,
        text: ele_text, 
        hoverinfo: 'text',
        type: "surface",
        colorscale: colorscale,
        cmin: max_elevation,  // minimum elevation value
        cmax: max_elevation,  // maximum elevation value
      }]

      const formattedSlopeData = [{
        z: z_slope,
        text: slope_text, 
        hoverinfo: 'text',
        type: "surface",
        colorscale: colorscale,
        cmin: max_slope,  // minimum elevation value
        cmax: max_slope,  // maximum elevation value
      }]
      setElevationPlotData(formattedElevationData)
      setSlopePlotData(formattedSlopeData)

    }

  }, [elevationData])

  const config = {
    responsive: true,
    toImageButtonOptions: {
      format: 'png', // one of png, svg, jpeg, webp
      filename: 'ElevationAnalysis',
      height: 500,
      width: 700,
      scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
    }
  };

  useEffect(() => {
    if (plotRef.current) {
      // Plotly.Plots.resize(plotRef.current.el);
    }
  }, [])

  return (
    <PlotBox>
      <Plot ref={plotRef} useResizeHandler style={{ width: "100%", height: "500px" }} id={"ElevationPlot"} data={elevationPlotData} layout={elevationLayout} config={config} />
      {elevationData.slope && <Plot useResizeHandler style={{ width: "100%", height: "500px" }} id={"SlopePlot"} data={slopePlotData} layout={slopeLayout} />}
    </PlotBox>
  )
}

export default ThreeDPlot

