/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

///////////////////// IMPORTS //////////////////////////

import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { BounceLoader } from "react-spinners";
import { ScaleLoader } from "react-spinners";
import { FormControl, MenuItem, Select } from "@mui/material";
import { CSVLink } from "react-csv";
import { PlatformData, LoadingStatus } from "../../../imports/Context";
import {
  windfarmturbines,
  powerCurveExample,
  siteProforma,
  turbineType,
} from "../../../data/SiteData";
import { overlay } from "leaflet.wms";
import $ from "jquery";
import {
  calculateMean,
  callElevationAPI,
  formatDataToGeoJSON,
  sendVektaWindAPIRequest,
  parseCSVAndSetPowerCurve,
  parseCSVAndSetCoords,
  getPersistanceResults,
  getERA5Data,
  formatPersistenceData,
  callWeatherAPI,
  generateExcel,
} from "./helperFunction";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../../../firebase";
import { ref, getDownloadURL } from "firebase/storage";
import LoadingOverlay from "react-loading-overlay-ts";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { v4 as uuidv4 } from "uuid";
import CapacityFactorSingleLine from "./CapacityFactorSingleLine";
import ExcelGenerator from "./ExcelGenerator";
import { PrimeButton, StyledIconButton } from "../../../styles/styles";
import MapPlot from "./MapPlot";
import YieldStats from "./Stats";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleDown,
  faDownload,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"; // Import the refresh icon
import Joyride, { STATUS } from "react-joyride";
import { InputSwitch } from "primereact/inputswitch";
import styled from "styled-components";
import { collection, getDocs, query, where } from "firebase/firestore";
import { InputNumber } from "primereact/inputnumber";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const PanelControls = styled("div")`
  display: flex;
  gap: 8px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 100%;

  input {
    width: 100%;
    height: 3rem;
    font-family: "Montserrat";
    font-weight: medium;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    border-radius: 4px;
    color: hsl(0, 0%, 20%);
  }
`;

function Yield() {
  /////////////////////////////////////////// USE STATES /////////////////////////////////////////

  const [coordsData, setCoordsData] = useState({ coords: [] });
  const { platformData, setPlatformData } = useContext(PlatformData);
  const { loadingStatus, setLoadingStatus } = useContext(LoadingStatus);
  const [weatherSelect, setWeatherSelect] = useState("pickSiteCoords");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const percentile = [
    { value: 10, label: "10%" },
    { value: 20, label: "20%" },
    { value: 30, label: "30%" },
    { value: 40, label: "40%" },
    { value: 50, label: "50%" },
    { value: 60, label: "60%" },
    { value: 70, label: "70%" },
    { value: 80, label: "80%" },
    { value: 90, label: "90%" },
  ];
  const [user, loading, error] = useAuthState(auth);
  const [cfvalues, setCFValues] = useState([]);
  const [examplesiteoptions, setExampleSiteOptions] = useState("");
  const defaultOption = { label: "100", value: 100 };
  const defaultTurbineOption = {
    value: "NREL_Reference_8MW",
    label: "NREL Reference 8MW",
  };
  const [turbineparams, setTurbineParams] = useState({
    turb_hub: 100,
    turb_diameter: 230,
    turb_name: "NREL_Reference_8MW",
  });
  const [powerCurveData, setPowerCurveData] = useState(powerCurveExample);
  const url = "https://storage.googleapis.com/turbinesdb/";
  const defaultWeatherTypeOption = { label: "Auto", value: "Auto" };
  const [vektawindresponse, setVektaWindResponse] = useState([]);
  const [sumaep, setSumAep] = useState(0);
  const [sumgross, setSumGross] = useState(0);
  const [selectedOption, setSelectedOption] = useState(100);
  const [selectedTurbineTypeOption, setselectedTurbineTypeOption] = useState();
  const [selectedWeatherTypeOption, setselectedWeatherTypeOption] = useState(
    defaultWeatherTypeOption
  );
  const [selectedSiteOption, setselectedSiteOption] = useState("");
  const [resultsopen, setResultsOpen] = useState(false);
  const [siteCables, setSiteCables] = useState([]);
  const [siteElevation, setSiteElevation] = useState({ Data: [], Msg: null });
  const [lonArray, setLonArray] = useState([]);
  const [latArray, setLatArray] = useState([]);
  const [elevationArray, setElevationArray] = useState([]);
  const [aepArray, setAEPArray] = useState([]);
  const [avgcfvalues, setavgCFValues] = useState();
  const [grossArray, setGROSSArray] = useState([]);
  const [selectedPlotOption, setselectedPlotOption] = useState({
    label: "Net AEP",
    value: "AEP",
  }); // Initial selected option
  const [site, setSite] = useState({ cables: [], osp: [] });
  const [meta, setMeta] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const [isSiteVisible, setIsSiteVisible] = useState(true);
  const [isResultsVisible, setIsResultsVisible] = useState(true);
  const [isUploadPick, setIsUploadPick] = useState(false);

  /////////////////////////////////////////// JOY RIDE ////////////////

  const [runJoyride, setRunJoyride] = useState(false);

  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    widgetRun: false,
    steps: [
      {
        content: (
          <div>
            <h2>Yield</h2>
            <p>
              Upload your own site or select an existing offshore wind farm and
              run a PyWake NOJ yield analysis. Get statistics on total gross
              annualised energy production (AEP), Net AEP, wake effect and
              average capacity factor. Move and delete Wind Turbine Generators
              (WTG) to see the effect on yield and download the data as an Excel
              spreadsheet.{" "}
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "right-start",
        target: "#weatherAnalysis_widget",
        disableBeacon: true,
      },
      {
        title: "Upload Site/Pick a Site",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/uploadasite.gif"
            />
            <br></br>
            <p>
              Upload a site using our simple CSV template, please make sure it
              matches the column names and value format in the template
              (download using the button below). Otherwise, toggle to "pick a
              site" and select between over 100 available sites or one that
              you’ve built using our site builder tool (in the draw tools on the
              right hand side of the screen).
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "right-start",
        target: "#uploadedCoord_pickSiteCoords",
      },
      {
        title: "Weather Source",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/yield_pickaweatherpoint.gif"
            />
            <br></br>
            <p>
              By default, the weather source is "Auto", which takes the closest
              ERA5 wind speed weather point and extrapolates it to your hub
              height. Toggle to "Manual" and select the ERA5 point that you
              would like to use (blue dots will appear on the map to represent
              these)
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#weatherSourceSelection",
      },
      {
        title: "Turbine Type",
        content: (
          <div>
            <br></br>
            <p>
              Select between National Renewable Energy Laboratory (NREL)
              standard turbines. We have converted Cp curves to Ct curves using
              Ct= 3(Cp)/2 method. For further information consult the
              information sheets on the{" "}
              <a href="https://nrel.github.io/turbine-models/index.html">
                NREL github pages
              </a>
              .
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#turbineTypeSelection",
      },
      {
        title: "Hub Height",
        content: (
          <div>
            {/* <img style={{ borderRadius: "8px", width: "-webkit-fill-available" }} src="/video/uploadweather.gif" /> */}
            <br></br>
            <p>Select the hub height (metres) of the turbine.</p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#hubHeightSelection",
      },
      {
        title: "Yield (reset)",
        content: (
          <div>
            {/* <img style={{ borderRadius: "8px", width: "-webkit-fill-available" }} src="/video/persistenceinput.gif" /> */}
            <br></br>
            <p>
              Click here to remove the figures and plots created from the yield
              analysis.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#resetYieldAnalysis",
      },
      {
        title: "Run Analysis",
        content: (
          <div>
            {/* <img style={{ borderRadius: "8px", width: "-webkit-fill-available" }} src="/video/persistenceinput.gif" /> */}
            <br></br>
            <p>
              Click here to run the yield analysis and then check below the
              input fields for the results. N.b. PyWake analysis settings are as
              follows: Uniform Weibull Sites with 30 degree segments, NOJ
              Deficit wake deficit model, Linear Sum superposition model and
              Frøya wind profile model.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: "#runYieldAnalysis",
      },
      {
        title: "Capacity Factor",
        content: (
          <div>
            {/* <img style={{ borderRadius: "8px", width: "-webkit-fill-available" }} src="/video/persistenceinput.gif" /> */}
            <br></br>
            <p>
              For the capacity factor calculation a Weibull distribution is
              fitted for each month. This is multiplied by the wind turbine
              power curve and the resultant curve is integrated to produce the
              expected yield for each month. These values are then divided by
              the maximum possible yield for each month. Only wake loss was
              considered, no other losses were applied.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".weatherAnalysis_windSpeed",
      },
    ],
  });

  /////////////////////////////////////////// OPTIONS /////////////////////////////////////////

  // putting in default options
  const exampleWindHeightOptions = [
    { value: 100, label: "100" },
    { value: 125, label: "125" },
    { value: 150, label: "150" },
    { value: 175, label: "175" },
  ];

  const exampleTurbineTypeOptions = [
    { value: "6MW 155m NREL Reference", label: "6MW 155m NREL Reference" },
    { value: "8MW 180m NREL Reference", label: "8MW 180m NREL Reference" },
    { value: "10MW 205m NREL Reference", label: "10MW 205m NREL Reference" },
    {
      value: "12MW 214m NREL ATB Reference",
      label: "12MW 214m NREL ATB Reference",
    },
    { value: "15MW 248m NREL Reference", label: "15MW 248m NREL Reference" },
    {
      value: "18MW 263m NREL ATB Reference",
      label: "18MW 263m NREL ATB Reference",
    },
  ];

  const exampleWeatherTypeOptions = [
    { value: "Auto", label: "Auto" },
    { value: "Manual", label: "Manual" },
  ];

  /////////////////////////////////////////// USE EFFECTS /////////////////////////////////////////
  useEffect(() => {
    // Extracting keys from the JSON object
    const keys = Object.keys(windfarmturbines[0]).sort();
    const transformedArray = keys
      .map((item) => ({
        value: item,
        label: item,
      }));

    const renderedSites = [];
    Object.keys(platformData.siteBuilder).map((key) => {
      const site = platformData.siteBuilder[key];
      renderedSites.push({ label: site.siteName, value: key });
    });
    setExampleSiteOptions([
      { label: "Already Rendered Sites", options: renderedSites },
      { label: "Existing Sites", options: transformedArray },
    ]);
    /// set the selectedsite here
    setselectedSiteOption(renderedSites[renderedSites.length - 1]);

    if (renderedSites.length > 0) {
      setCoordsData({
        coords: [
          ...platformData.siteBuilder[
            renderedSites[renderedSites.length - 1].value
          ].siteTurbs,
        ],
      });
      setSite({
        cables:
          platformData.siteBuilder[
            renderedSites[renderedSites.length - 1].value
          ].siteCables,
        osp: platformData.siteBuilder[
          renderedSites[renderedSites.length - 1].value
        ].siteOSP,
      });
    }
  }, [Object.keys(platformData.siteBuilder).length]);

  const yieldOutput = () => {
    let newDataFlag = true;

    let coords = vektawindresponse.coords;
    if (!coords) {
      coords = platformData?.yield?.response?.coords;
      newDataFlag = false;
    }

    let cf = vektawindresponse.cf;
    if (!cf) {
      cf = platformData?.yield?.response?.cf;
      newDataFlag = false;
    }

    if (coords) {
      if (siteElevation.Data.length !== 0) {
        for (let i = 0; i < coords.length; i++) {
          // Update each object in vektawindresponse.coords with the corresponding elevation and geometry
          coords[i].elevation = siteElevation.Data[i].Elevation;
        }
      }

      const coordsresponse = coords;
      const totalAep = coordsresponse.reduce(
        (accumulator, currentValue) => accumulator + currentValue.aep,
        0
      );
      const totalGross = coordsresponse.reduce(
        (accumulator, currentValue) => accumulator + currentValue.gross,
        0
      );
      setElevationArray(coordsresponse.map((item) => item.elevation));
      setAEPArray(coordsresponse.map((item) => item.aep));
      setLatArray(coordsresponse.map((item) => item.lat));
      setLonArray(coordsresponse.map((item) => item.lon));
      setGROSSArray(coordsresponse.map((item) => item.gross));

      // Now, update the state using setAep and setGross
      setSumAep(totalAep);
      setSumGross(totalGross);

      if (cf) {
        const newCFArray = cf
          .flatMap((innerArray) => innerArray)
          .map((value) => value);

        setCFValues(newCFArray);
        // Calculate the sum of all elements in the array
        const sum = newCFArray.reduce((acc, curr) => acc + curr, 0);
        // Calculate the average
        const averageCFArray = sum / newCFArray.length;
        const formattedCFArrayValue = averageCFArray.toFixed(2);
        setavgCFValues(formattedCFArrayValue);
      }

      if (newDataFlag) {
        setPlatformData((platformData) => ({
          ...platformData,
          yield: {
            response: vektawindresponse,
            settings: {
              site: weatherSelect,
              siteName: selectedSiteOption,
              weather: selectedWeatherTypeOption,
              turbine: selectedTurbineTypeOption,
              hub: selectedOption,
            },
          },
        }));
      }
    }
  };

  /**
   *
   * Handling when a new response from API
   *
   */
  useEffect(() => {
    yieldOutput();
  }, [vektawindresponse]);

  /**
   *
   * Handling when loading from platform data
   *
   */
  useEffect(() => {
    yieldOutput();
  }, []);

  useEffect(() => {
    setLoadingStatus({ loading: false, loadingMsg: "" });
  }, [platformData.weatherAnalysis.windRoseData]);

  /////////////////////////////////////////// HANDLES /////////////////////////////////////////

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && selectedOption === null) {
      // If the input is empty and "Enter" is pressed, create an option with the current input
      const newOption = {
        label: event.target.value,
        value: event.target.value,
      };
      setSelectedOption(newOption);
    }
  };

  // Handle input value change
  const handleInputChange = (inputValue) => {
    var windHeight = inputValue;
    setSelectedOption(windHeight.value);

    if (/^[0-9]*$/.test(windHeight.value) && windHeight.value > 0) {
      setTurbineParams((prevParams) => ({
        ...prevParams,
        turb_hub: windHeight.value,
      }));
    }
  };

  // Handle input value change
  const handleWeatherTypeChange = (inputValue) => {
    var weatherType = inputValue;
    setselectedWeatherTypeOption({
      label: weatherType.label,
      value: weatherType.value,
    });

    if (inputValue.value === "Manual") {
      if (location.pathname === "/vekta-digital/Yield") {
        $(".leaflet-era5-grid-pane").show();
      }
    } else {
      $(".leaflet-era5-grid-pane").hide();
    }
  };

  const handleSiteChange = (inputValue) => {
    setselectedSiteOption(inputValue);

    // Extract the data for "Aberdeen Offshore W/F"
    // Filter windfarmturbines based on the key (inputValue)
    const data = Object.keys(windfarmturbines[0])
      .filter((key) => key === inputValue.value)
      .reduce((acc, key) => {
        acc[key] = windfarmturbines[0][key];
        return acc;
      }, {});

    let transformedData = Object.keys(data).map((key) =>
      data[key][0].ids.map((id, index) => ({
        ids: id.toString(), // Convert ids to string
        lon: data[key][0].lon[index],
        lat: data[key][0].lat[index],
        class: isNaN(id) ? "OSP" : "turbine",
        elevation: 1,
        gross: 1,
        aep: 1,
      }))
    )[0];

    if (transformedData === undefined) {
      transformedData = platformData.siteBuilder[inputValue.value].siteTurbs;
      setSite({
        cables: platformData.siteBuilder[inputValue.value].siteCables,
        osp: platformData.siteBuilder[inputValue.value].siteOSP,
      });

      setCoordsData({ coords: [...transformedData] });
    } else {
      const osps = [
        ...transformedData.filter((element) => element.ids.includes("osp")),
      ];
      for (let i = 0; i < osps.length; i++) {
        const element = osps[i];
        transformedData.splice(transformedData.indexOf(element), 1);
      }

      setPlatformData((platformData) => ({
        ...platformData,
        siteBuilder: {
          ...platformData.siteBuilder,
          [uuidv4()]: {
            siteTurbs: transformedData,
            siteName: inputValue.value,
            siteSettings: { siteName: inputValue.value },
            siteOSP: osps,
            siteCables: [],
            datetime: Date(),
          },
        },
      }));
    }
  };

  const handleRunAnalysisClick = (event) => {
    setLoadingStatus({ loading: true, loadingMsg: "Processing Site . . ." });

    const formattedcoords = formatDataToGeoJSON(
      platformData.siteBuilder[selectedSiteOption.value].siteTurbs
    );
    callElevationAPI(formattedcoords, setSiteElevation);
  };

  useEffect(() => {
    if (selectedTurbineTypeOption) {
      const turbines = collection(db, "turbinedb");
      const q = query(
        turbines,
        where("Name", "==", selectedTurbineTypeOption.value)
      );
      getDocs(q)
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            // If no matching document is found, set to 100
            setSelectedOption(100);
            setPlatformData((platformData) => ({
              ...platformData,
              weatherAnalysis: {
                ...platformData.weatherAnalysis,
                windHeight: 100,
              },
            }));
          } else {
            querySnapshot.forEach((doc) => {
              const hubHeight = doc.data()["Hub Height"];
              setSelectedOption(hubHeight);
              setPlatformData((platformData) => ({
                ...platformData,
                weatherAnalysis: {
                  ...platformData.weatherAnalysis,
                  windHeight: hubHeight,
                  ratedPower: doc.data()["Rated Power"],
                },
              }));
            });
          }
        })
        .catch((error) => {
          console.error("Error getting documents: ", error);
          // Handle the error if needed
        });
    }
  }, [selectedTurbineTypeOption]);

  useEffect(() => {
    if (siteElevation.Data.length > 0) {
      // Define constants
      // const deficitModel = ["NOJDeficit"];
      // const superposition = ["LinearSum"];
      const hub = [turbineparams.turb_hub];
      // const dia = [turbineparams.turb_diameter];
      const turb_name = [turbineparams.turb_name];

      var weatherPoint = null;
      if (selectedWeatherTypeOption.value === "Manual") {
        if (Array.isArray(platformData.weatherAnalysis.pointClick)) {
          weatherPoint = {
            lon: platformData.weatherAnalysis.pointClick[0],
            lat: platformData.weatherAnalysis.pointClick[1],
          };
        }
      }

      // Combine the data into the desired JSON structure
      const data_yieldAPI = {
        coords: platformData.siteBuilder[selectedSiteOption.value].siteTurbs,
        // deficitModel: deficitModel,
        // superposition: superposition,
        hub: hub,
        // turbName: turb_name,
        // turbName: ["OR Cost Reference 15 MW"],
        turbName: turbineparams["turb_name"],
        weatherSource: "era5",
        uid: user.uid,

        model: "NOJ",
        ...(weatherPoint && { weatherPoint: weatherPoint }),
        neighbours: [],
      };

      setMeta({ weatherPoint: data_yieldAPI.weatherPoint });

      handleWindApi(data_yieldAPI);
      // sendVektaWindAPIRequest(
      //   combinedJSON,
      //   setVektaWindResponse,
      //   setLoadingStatus
      // );
    }
  }, [siteElevation]);

  const handleWindApi = async (combinedJSON) => {
    await sendVektaWindAPIRequest(
      combinedJSON,
      setVektaWindResponse,
      setLoadingStatus
    );
  };

  //////////// TODO: fix this - the wether rose should change when
  /////////// a new site is selected

  // Handle input value change
  const handleTurbineTypeChange = (inputValue) => {
    setselectedTurbineTypeOption(inputValue);

    setTurbineParams((turbineparams) => ({
      ...turbineparams,
      turb_name: inputValue.value, // Assuming turb_hub is an array with a single value
    }));
    // }
  };

  // remove input when on focus
  const handleInputFocus = () => {
    // Clear the input value when it is focused (cursor is shown)
    // setSelectedOption('');
  };

  const handleDownloadClick = () => {
    /**
     *
     * GOOGLE ANALYTICS
     *
     */
    logEvent(analytics, "select_content", {
      content_type: "Yield data download",
    });
  };

  const handleUploadedCoords = (event) => {
    const file = event.target.files[0];
    parseCSVAndSetCoords(
      file,
      platformData,
      setPlatformData,
      uuidv4,
      setCoordsData
    ); // Call the helper function
    event.target.value = "";
  };

  // Function to handle change in dropdown selection
  const handleSelectPlotChange = (event) => {
    setselectedPlotOption(event);
  };

  const handleClearAll = (type) => {
    setPlatformData((platformData) => ({
      ...platformData,
      [type]: {},
    }));

    if (type === "yield") {
      setElevationArray([]);
      setAEPArray([]);
      setLatArray([]);
      setLonArray([]);
      setGROSSArray([]);

      // Now, update the state using setAep and setGross
      setSumAep(0);
      setSumGross(0);

      setCFValues([]);
      setavgCFValues(0);
    }
  };

  ///////////////////// FUNCTIONS/LOGIC //////////////////////////

  // Dynamically select values based on the selected option
  let selectedPlotValues;
  if (selectedPlotOption.value === "Elevation") {
    selectedPlotValues = elevationArray;
  } else if (selectedPlotOption.value === "AEP") {
    selectedPlotValues = aepArray;
  } else {
    // Handle other options as needed
    selectedPlotValues = []; // Default to an empty array if no matching option
  }

  ///////////////////// JSX //////////////////////////

  return (
    <LoadingOverlay
      active={loadingStatus.loading}
      classNamePrefix="main_sidebarLoader_"
      className="widgets"
      spinner={<ScaleLoader color="white" />}
    >
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
          },
        }}
      />
      <div id="weatherAnalysis_widget" className="weatherAnalysis">
        <Header>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              width: "100%",
            }}
          >
            <h2>Yield</h2>
            <StyledIconButton className="widgetTour" onClick={handleTutorial}>
              <FontAwesomeIcon icon={faQuestion} />
            </StyledIconButton>
          </div>
          <PanelControls>
            <StyledIconButton
              onClick={() =>
                generateExcel(
                  meta,
                  vektawindresponse,
                  site,
                  handleDownloadClick
                )
              }
              title="Download Yield Data"
            >
              <FontAwesomeIcon color="black" icon={faDownload} />
            </StyledIconButton>

            <StyledIconButton
              id="restYieldAnalysis"
              title="Clear Yield and Sites"
              onClick={() => {
                handleClearAll("siteBuilder");
                handleClearAll("yield");
              }}
            >
              <FontAwesomeIcon color="black" icon={faSyncAlt} />
            </StyledIconButton>
          </PanelControls>
        </Header>

        <hr />

        <div className="weatherInputs inputSection">
          <div className="inputSection_heading">
            <div style={{ display: "flex", paddingLeft: "10px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" />
              </svg>
              <h4>Site</h4>
            </div>
            <button
              className={
                isSiteVisible ? "inputControlsHide open" : "inputControlsHide"
              }
              onClick={() => setIsSiteVisible(!isSiteVisible)}
              style={{ marginLeft: "10px", outline: "none" }}
            >
              <span className="left-bar"></span>
              <span className="right-bar"></span>
            </button>
          </div>
          {isSiteVisible && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <label className="labelInput" style={{ margin: "0" }}>
                  Location
                </label>
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      marginBottom: "8px",
                    }}
                  >
                    <label style={{ margin: "0" }}>Pick Site</label>
                    <InputSwitch
                      checked={isUploadPick}
                      onChange={(e) => {
                        setIsUploadPick((temp) => !temp);
                        setWeatherSelect(
                          e.value ? "uploadedCoords" : "pickSiteCoords"
                        );
                      }}
                    />
                    <label style={{ margin: "0" }}>Upload Site</label>
                  </div>
                </div>
              </div>

              <div id="uploadedCoord_pickSiteCoords">
                {weatherSelect === "uploadedCoords" && (
                  <div style={{ paddingBottom: "25px" }}>
                    <div>
                      <input
                        id="selectDataPoint"
                        onChange={handleUploadedCoords}
                        accept=".csv"
                        type="file"
                      />
                    </div>
                    <div style={{ paddingTop: "16px" }}>
                      <CSVLink
                        data={siteProforma}
                        filename={"SiteData.csv"}
                        style={{ textDecoration: "none", paddingTop: "16px" }}
                      >
                        <PrimeButton style={{ width: "100%" }}>
                          <FontAwesomeIcon
                            size="xl"
                            color="white"
                            icon={faCircleDown}
                          />
                          Template
                        </PrimeButton>
                      </CSVLink>
                    </div>
                  </div>
                )}

                {(weatherSelect === "pickSiteCoords" ||
                  platformData?.yield?.settings?.site === "pickSiteCoords") && (
                  <div style={{ paddingBottom: "25px" }}>
                    <CreatableSelect
                      value={selectedSiteOption}
                      // value={platformData?.yield?.settings?.siteName ? platformData?.yield?.settings?.siteName : selectedSiteOption}
                      options={examplesiteoptions}
                      onChange={handleSiteChange}
                      onFocus={handleInputFocus}
                      onKeyDown={handleKeyDown}
                      placeholder="Available Site . . ."
                      // onCreateOption={handleInputChange} // This is necessary for creating new options
                      className="weatherOption"
                      // formatCreateLabel={(inputValue) => `${inputValue}`
                      // }
                    />
                  </div>
                )}

                {/* No input element for selectDataPoint */}
                {weatherSelect === "selectDataPoint" && (
                  <div>{/* Placeholder or additional content */}</div>
                )}
              </div>

              <div id="weatherSourceSelection" className="turbinetypeinput">
                <label className="labelInput">Weather Source</label>
                <CreatableSelect
                  value={selectedWeatherTypeOption}
                  // value={platformData?.yield?.settings?.weather ? platformData?.yield?.settings?.weather : selectedWeatherTypeOption}
                  options={exampleWeatherTypeOptions}
                  onChange={handleWeatherTypeChange}
                  onFocus={handleInputFocus}
                  onKeyDown={handleKeyDown}
                  // onCreateOption={handleInputChange} // This is necessary for creating new options
                  className="weatherOption"
                  formatCreateLabel={(inputValue) => `${inputValue}`}
                />
              </div>

              <div id="turbineTypeSelection" className="windheightinput">
                <label className="labelInput">Turbine Type</label>
                <CreatableSelect
                  value={selectedTurbineTypeOption}
                  // value={platformData?.yield?.settings?.turbine ? platformData?.yield?.settings?.turbine : selectedTurbineTypeOption}
                  options={exampleTurbineTypeOptions}
                  onChange={handleTurbineTypeChange}
                  onFocus={handleInputFocus}
                  onKeyDown={handleKeyDown}
                  // onCreateOption={handleInputChange} // This is necessary for creating new options
                  className="weatherOption"
                  formatCreateLabel={(inputValue) => `${inputValue}`}
                />
              </div>

              <div
                id="hubHeightSelection"
                style={{ display: "flex", flexDirection: "column" }}
                className="input windheightinput"
              >
                <label className="labelInput">Hub Height (m)</label>
                {/* <CreatableSelect
                  value={selectedOption}
                  // value={platformData?.yield?.settings?.hub ? platformData?.yield?.settings?.hub : selectedOption}
                  options={exampleWindHeightOptions}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onKeyDown={handleKeyDown}
                  // onCreateOption={handleInputChange} // This is necessary for creating new options
                  className="weatherOption"
                  // formatCreateLabel={(inputValue) => `${inputValue}`
                  // }
                /> */}
                <StyledInputNumber
                  value={selectedOption}
                  onValueChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              </div>

              <div
                className="weatherDownload"
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <PrimeButton
                  id="runYieldAnalysis"
                  style={{ width: "48%" }}
                  onClick={handleRunAnalysisClick}
                  disabled={
                    !turbineparams["turb_name"] ||
                    coordsData.coords.length === 0
                  }
                >
                  Run
                </PrimeButton>
              </div>
            </div>
          )}
        </div>

        <div className="weatherOutput inputSection">
          <div className="inputSection_heading">
            <div style={{ display: "flex", paddingLeft: "10px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
              </svg>
              <h4>Results</h4>
            </div>

            <button
              className={
                isResultsVisible
                  ? "inputControlsHide open"
                  : "inputControlsHide"
              }
              onClick={() => setIsResultsVisible(!isResultsVisible)}
              style={{ marginRight: "10px", outline: "none" }}
            >
              <span className="left-bar"></span>
              <span className="right-bar"></span>
            </button>
          </div>

          {isResultsVisible && (
            <div
              id="weatherAnalysis_plotlyPlots"
              className={
                !isResultsVisible
                  ? "weatherOutput_content hide_block"
                  : "weatherOutput_content"
              }
            >
              <YieldStats
                TotalGross={sumgross.toFixed(0)}
                TotalNet={sumaep.toFixed(0)}
                WakeEffect={
                  isNaN((((sumgross - sumaep) / sumgross) * 100).toFixed(2))
                    ? 0
                    : (((sumgross - sumaep) / sumgross) * 100).toFixed(2)
                }
                AvgCF={avgcfvalues ? avgcfvalues : 0}
              />

              <div>
                <CapacityFactorSingleLine months={months} cfvalues={cfvalues} />

                <div>
                  <CreatableSelect
                    value={selectedPlotOption}
                    options={[
                      { label: "Net AEP", value: "AEP" },
                      { label: "Elevation", value: "Elevation" },
                    ]}
                    onChange={handleSelectPlotChange}
                    onFocus={handleInputFocus}
                    onKeyDown={handleKeyDown}
                    // onCreateOption={handleInputChange} // This is necessary for creating new options
                    className="weatherOption"
                  />
                </div>
              </div>

              <MapPlot
                latitudes={latArray}
                longitudes={lonArray}
                values={selectedPlotValues} // Pass the selected values to the MapPlot component
                name={
                  selectedPlotOption.value.charAt(0).toUpperCase() +
                  selectedPlotOption.value.slice(1)
                } // Set the name based on the selected option
              />

              <div className="yieldDownload">
                <PrimeButton
                  onClick={() =>
                    generateExcel(
                      meta,
                      vektawindresponse,
                      site,
                      handleDownloadClick
                    )
                  }
                  style={{ width: "48%" }}
                >
                  <FontAwesomeIcon
                    size="xl"
                    color="white"
                    icon={faCircleDown}
                  />
                  Data
                </PrimeButton>

                <PrimeButton
                  style={{ width: "48%" }}
                  onClick={() => {
                    if (selectedWeatherTypeOption.value === "Auto") {
                      const latauto = calculateMean(coordsData.coords, "lat");
                      const lonauto = calculateMean(coordsData.coords, "lon");

                      setPlatformData((platformData) => ({
                        ...platformData,
                        weatherAnalysis: {
                          ...platformData.weatherAnalysis,
                          windHeight: turbineparams.turb_hub,
                          pointClick: [lonauto, latauto],
                        },
                      }));
                    }

                    navigate("/vekta-digital/WeatherAnalysis");
                  }}
                >
                  View Wind Rose
                </PrimeButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default Yield;
