/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Modal from "react-modal";
import { BounceLoader, BeatLoader, ScaleLoader } from "react-spinners";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { CSVLink } from "react-csv";

import WindRose from "./WindRose";
import WindSpeed from "./WindSpeed";
import CustomSingleLine from "./CustomSingleLine";
import { PlatformData, LoadingStatus } from "../../../imports/Context";
import { weatherProforma } from "../../../data/WeatherProforma";

import {
  getPersistanceResults,
  getERA5Data,
  formatPersistenceData,
  getUploadedWeatherData,
  callWeatherAPI,
} from "./helperFunction";
import { overlay } from "leaflet.wms";
import $ from "jquery";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth, storage } from "../../../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import LoadingOverlay from "react-loading-overlay-ts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Joyride, { STATUS } from "react-joyride";

import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";
import { PrimeButton, StyledIconButton } from "../../../styles/styles";

import { faCircleDown, faQuestion } from "@fortawesome/free-solid-svg-icons";

function WeatherAnalysis() {
  const { platformData, setPlatformData } = useContext(PlatformData);
  const { loadingStatus, setLoadingStatus } = useContext(LoadingStatus);

  const [windRose, setWindRose] = useState(false);
  const [persistenceCalc, setPersistenceCalc] = useState(false);
  const [weatherSelect, setWeatherSelect] = useState("selectDataPoint");
  const [downloadingData, setDownloadingData] = useState(false);

  const [weatherWindowOptions, setWeatherWindowOptions] = useState([]);
  const [waveHeightOptions, setWaveHeightOptions] = useState([]);
  const [wavePeriodOptions, setWavePeriodOptions] = useState([]);
  const [windSpeedOptions, setWindSpeedOptions] = useState([]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const percentile = [
    { value: 10, label: "10%" },
    { value: 20, label: "20%" },
    { value: 30, label: "30%" },
    { value: 40, label: "40%" },
    { value: 50, label: "50%" },
    { value: 60, label: "60%" },
    { value: 70, label: "70%" },
    { value: 80, label: "80%" },
    { value: 90, label: "90%" },
  ];
  const [persistenceResults, setPersistenceResults] = useState(false);
  const [percentileSelected, setPercentileSelected] = useState(
    percentile[0].value
  );
  const [customOptions, setCustomOptions] = useState({
    window: { label: "1000", value: "1000", __isNew__: true },
    wind: { label: "1000", value: "1000", __isNew__: true },
    wave: { label: "1000", value: "1000", __isNew__: true },
    tp: { label: "1000", value: "1000", __isNew__: true },
  });

  const [user, loading, error] = useAuthState(auth);

  // putting in default options
  const exampleWindHeightOptions = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 150, label: "150" },
    { value: 200, label: "200" },
  ];

  const exampleWeatherWindowOptions = [
    { value: 8, label: "8" },
    { value: 12, label: "12" },
  ];

  const exampleWaveHeightOptions = [
    { value: 1.75, label: "1.75" },
    { value: 2, label: "2" },
  ];

  const exampleWavePeriodOptions = [{ value: 10, label: "10" }];

  const exampleWindSpeedOptions = [
    { value: 8, label: "8" },
    { value: 10, label: "10" },
  ];

  //// joy ride stuff
  const [runJoyride, setRunJoyride] = useState(false);

  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    widgetRun: false,
    steps: [
      {
        content: (
          <div>
            <h2>Weather</h2>
            <p>
              Select a weather data source to visualise the data. Run a
              persistence accessibility analysis and output the results in the
              browser or pdf report
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "bottom",
        target: ".weatherAnalysis",
        disableBeacon: true,
      },
      {
        title: "Select ERA5 data point",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/selectERA5.gif"
            />
            <br></br>
            <p>
              If you selected ECMWF Reanalysis v5 (ERA5) go to the map and click
              on a blue point.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "bottom",
        target: ".sourceDataSelect",
      },
      {
        title: "Modify the wind height (optional)",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/windheight.gif"
            />
            <br></br>
            <p>
              The wind data is presented by default at 100m but if you would
              like to change this either select one of the values or type in
              value.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".windheightinput",
      },
      {
        title: "Download data",
        content: (
          <div>
            <br></br>
            <p>
              At this point if you want to download the raw data then click on
              the "Download Weather Data" button.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".weatherbutton",
      },
      {
        title: "Upload custom weather data",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/uploadweather.gif"
            />
            <br></br>
            <p>
              If you selected "Upload Custom Wind" then click "Choose file" and
              find the csv data on your file system. Make sure the file is
              called "WeatherData.csv" and follows the Pro forma (Download the
              Pro forma to check)
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".sourceDataSelect",
      },
      {
        title: "View and download plots",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/toggleweatherplots.gif"
            />
            <br></br>
            <p>
              Monthly wind speed distribution and wind rose plots can be toggled
              on and off and downloaded as png image files.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".weatherOutput.inputSection",
      },
      {
        title: "Run a persistence analysis",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/persistenceinput.gif"
            />
            <br></br>
            <p>
              Toggle the persistence analysis and then either select values or
              input values for weather window, significant wave height, wave
              period and mean wind speed.<br></br>
              Then when you are happy with your inputs click "Run Analysis",
              after around 10 seconds a popup with the results will be shown.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".persistenceInput.inputSection",
      },
    ],
  });

  ////////////////////////////// to allow enter to input value in input box
  const defaultOption = { label: "100", value: 100 };
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && selectedOption === null) {
      // If the input is empty and "Enter" is pressed, create an option with the current input
      const newOption = {
        label: event.target.value,
        value: event.target.value,
      };
      setSelectedOption(newOption);
    }
  };

  // Handle input value change
  const handleInputChange = (inputValue) => {
    var windHeight = inputValue;

    if (/^[0-9]*$/.test(windHeight.value) && windHeight.value > 0) {
      setSelectedOption({
        label: windHeight.value.toString(),
        value: windHeight.value,
      });
    } else {
      // Input is not numeric, reset to default placeholder
      setSelectedOption(defaultOption);
      windHeight = 100;
    }

    setPlatformData({
      ...platformData,
      weatherAnalysis: {
        ...platformData.weatherAnalysis,
        windHeight: windHeight.value,
      },
    });
  };

  // remove input when on focus
  const handleInputFocus = () => {
    // Clear the input value when it is focused (cursor is shown)
    setSelectedOption("");
  };

  useEffect(() => {
    if (platformData.weatherAnalysis.pointClick.length > 0) {
      setLoadingStatus({
        loading: true,
        loadingMsg: "Processing ERA5 Data . . .",
      });
      callWeatherAPI(platformData, setPlatformData, setLoadingStatus);
    }
  }, [platformData.weatherAnalysis.windHeight]);
  ///////////////////////////////////////////////////////////

  const handleWeatherSelect = (event) => {
    setWeatherSelect(event.target.value);
  };

  const handleDownloadReport = (type) => {
    var report;
    if (type === "csv") {
      report = ref(storage, "userReports/" + user.uid + "/persistence.csv");
    } else if (type === "pdf") {
      report = ref(storage, "userReports/" + user.uid + "/persistence.pdf");
    }

    // Get the download URL
    getDownloadURL(report)
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "PersistenceReport." + type;
        a.target = "_blank";
        a.click();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });

    /**
     *
     * GOOGLE ANALYTICS
     *
     */
    logEvent(analytics, "select_content", {
      content_type: "Download persistence " + type,
    });
  };

  const persistenceAPICall = () => {
    setLoadingStatus({
      loading: true,
      loadingMsg: "Calculating Persistence . . .",
    });
    const options = [
      weatherWindowOptions,
      waveHeightOptions,
      wavePeriodOptions,
      windSpeedOptions,
    ];
    var window = [],
      height = [],
      period = [],
      speed = [];

    for (let i = 0; i < options.length; i++) {
      for (let j = 0; j < options[i].length; j++) {
        switch (i) {
          case 0:
            window.push(parseFloat(options[i][j].value));
            break;
          case 1:
            height.push(parseFloat(options[i][j].value));
            break;
          case 2:
            period.push(parseFloat(options[i][j].value));
            break;
          case 3:
            speed.push(parseFloat(options[i][j].value));
            break;
          default:
            console.log("Error");
            break;
        }
      }
    }

    getPersistanceResults({
      window,
      height,
      period,
      speed,
      weatherSelect,
      platformData,
      loadingStatus,
      setLoadingStatus,
      user,
    })
      .then((data) => {
        setPlatformData({
          ...platformData,
          weatherAnalysis: {
            ...platformData.weatherAnalysis,
            pointClick: platformData.weatherAnalysis.pointClick,
            windSpeedData: platformData.weatherAnalysis.windSpeedData,
            windRoseData: platformData.weatherAnalysis.windRoseData,
            persistanceData: formatPersistenceData(data, percentile, months),
          },
        });

        /////
        document
          .getElementById("viewPersistenceResults")
          .classList.remove("disabledButton");
        document.getElementById("viewPersistenceResults").disabled = false;
        /////

        setPersistenceResults(true);
        setLoadingStatus({ loading: false, loadingMsg: "" });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDownloadClick = () => {
    setDownloadingData(true);
    getERA5Data({ platformData, setDownloadingData });

    /**
     *
     * GOOGLE ANALYTICS
     *
     */
    logEvent(analytics, "select_content", {
      content_type: "Weather data download",
    });
  };

  const handleViewResults = () => {
    setPersistenceResults(true);
  };

  const handleNumberOnlyInput = (e) => {
    // Ensuring that the user can only enter positive floats
    if (
      !(
        e.nativeEvent.code.includes("Digit") ||
        e.nativeEvent.code === "Period" ||
        e.nativeEvent.code === "Backspace" ||
        e.nativeEvent.code === "Enter"
      )
    ) {
      e.preventDefault();
    }
  };

  const handleUploadedWeather = () => {
    var file = document.getElementById("uploadedWeather").files[0];
    var reader = new FileReader();
    reader.onload = function () {
      const storageRef = ref(
        storage,
        "userUploads/" + user.uid + "/" + file.name
      );

      uploadBytes(storageRef, file).then((snapshot) => {
        getUploadedWeatherData(user.uid, setPlatformData, setLoadingStatus);
      });
    };
    reader.readAsDataURL(file);

    /**
     *
     * GOOGLE ANALYTICS
     *
     */
    logEvent(analytics, "select_content", {
      content_type: "Upload weather data",
    });
  };

  const setDefault = (options) => {
    if (options.length > 0) {
      return options[0];
    } else {
      return { label: "1000", value: "1000" };
    }
  };

  useEffect(() => {
    if (platformData.weatherAnalysis.persistanceData.length === 0) {
      document.getElementById("viewPersistenceResults").disabled = true;
    }
  }, [persistenceResults]);

  useEffect(() => {
    setWindRose(true);
    setLoadingStatus({ loading: false, loadingMsg: "" });
  }, [platformData.weatherAnalysis.windRoseData]);

  // on changing the input values change platform data
  useEffect(() => {
    setPlatformData({
      ...platformData,
      weatherAnalysis: {
        ...platformData.weatherAnalysis,
        persistanceOptions: {
          window: weatherWindowOptions,
          wave: waveHeightOptions,
          tp: wavePeriodOptions,
          wind: windSpeedOptions,
        },
      },
    });
  }, [
    weatherWindowOptions,
    waveHeightOptions,
    wavePeriodOptions,
    windSpeedOptions,
  ]);

  return (
    <LoadingOverlay
      active={loadingStatus.loading}
      classNamePrefix="main_sidebarLoader_"
      className="widgets"
      spinner={<ScaleLoader color="white" />}
    >
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
          },
        }}
      />
      <div id="weatherAnalysis_widget" className="weatherAnalysis">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h2>Weather</h2>
          <StyledIconButton className="widgetTour" onClick={handleTutorial}>
            <FontAwesomeIcon icon={faQuestion} />
          </StyledIconButton>
        </div>

        <hr />

        <div className="weatherInputs inputSection">
          <div className="inputSection_heading">
            <div style={{ display: "flex", paddingLeft: "10px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                <path d="M448 80v48c0 44.2-100.3 80-224 80S0 172.2 0 128V80C0 35.8 100.3 0 224 0S448 35.8 448 80zM393.2 214.7c20.8-7.4 39.9-16.9 54.8-28.6V288c0 44.2-100.3 80-224 80S0 332.2 0 288V186.1c14.9 11.8 34 21.2 54.8 28.6C99.7 230.7 159.5 240 224 240s124.3-9.3 169.2-25.3zM0 346.1c14.9 11.8 34 21.2 54.8 28.6C99.7 390.7 159.5 400 224 400s124.3-9.3 169.2-25.3c20.8-7.4 39.9-16.9 54.8-28.6V432c0 44.2-100.3 80-224 80S0 476.2 0 432V346.1z" />
              </svg>
              <h4>Data</h4>
            </div>
          </div>

          <div className="input">
            <label className="labelInput">Source</label>
            <div className="sourceDataSelect">
              <select
                onChange={(e) => setWeatherSelect(e.target.value)}
                value={weatherSelect}
              >
                <option value="selectDataPoint">
                  ERA5 0.5 degrees (wind and metocean)
                </option>
                <option value="uploadWeather">Upload Custom Wind</option>
              </select>
            </div>
          </div>
          <div
            className={
              weatherSelect !== "uploadWeather"
                ? "uploadWeather"
                : "uploadWeather toggle_weather"
            }
          >
            <div>
              <input
                id="uploadedWeather"
                onChange={handleUploadedWeather}
                accept=".csv"
                type="file"
              />
            </div>
          </div>
          {/* 
          <div className="input">
            <label>Wind Height (m)</label>
            <input type="number" min="0" defaultValue="100" />            
          </div>
*/}

          <div className="windheightinput">
            <label className="labelInput">Wind Height (m)</label>
            <CreatableSelect
              value={selectedOption}
              options={exampleWindHeightOptions}
              onChange={handleInputChange}
              onFocus={handleInputFocus}
              onKeyDown={handleKeyDown}
              isClearable
              // onCreateOption={handleInputChange} // This is necessary for creating new options
              className="weatherOption"
              formatCreateLabel={(inputValue) => `${inputValue}`}
            />
          </div>

          <div className="weatherDownload">
            <PrimeButton
              style={{ width: "48%" }}
              className={
                platformData.weatherAnalysis.pointClick.length === 0
                  ? "disabledButton"
                  : "weatherbutton"
              }
              disabled={
                platformData.weatherAnalysis.pointClick.length === 0
                  ? true
                  : false
              }
              onClick={handleDownloadClick}
            >
              {!downloadingData ? (
                <>
                  <FontAwesomeIcon
                    size="xl"
                    color="white"
                    icon={faCircleDown}
                  />
                  Weather Data
                </>
              ) : (
                <BeatLoader color="white" size={15} />
              )}
            </PrimeButton>
            <CSVLink
              data={weatherProforma}
              filename={"WeatherData.csv"}
              style={{ textDecoration: "none", width: "48%" }}
            >
              <PrimeButton style={{ width: "100%" }}>
                <FontAwesomeIcon size="xl" color="white" icon={faCircleDown} />
                Template
              </PrimeButton>
            </CSVLink>
          </div>
        </div>

        <div className="weatherOutput inputSection">
          <div className="inputSection_heading">
            <div style={{ display: "flex", paddingLeft: "10px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                <path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z" />
              </svg>
              <h4>Plots</h4>
            </div>

            <button
              className={
                !windRose ? "inputControlsHide" : "inputControlsHide open"
              }
              onClick={() => {
                setWindRose(!windRose);
                $(document).trigger("shown");
              }}
            >
              <span className="left-bar"></span>
              <span className="right-bar"></span>
            </button>
          </div>

          <div
            id="weatherAnalysis_plotlyPlots"
            className={
              !windRose
                ? "weatherOutput_content hide_block"
                : "weatherOutput_content"
            }
          >
            {platformData.weatherAnalysis.pointClick.length > 0
              ? [
                  <WindSpeed
                    data={platformData.weatherAnalysis.windSpeedData}
                    month={months}
                  />,
                  <WindRose
                    data={
                      platformData.weatherAnalysis.windRoseData.WindRoseData
                    }
                  />,
                ]
              : null}
          </div>
        </div>

        <div className="persistenceInput inputSection">
          <div className="inputSection_heading">
            <div style={{ display: "flex", paddingLeft: "10px" }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                {/* <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
                <path d="M192 32c0-17.7 14.3-32 32-32H352c17.7 0 32 14.3 32 32V64h48c26.5 0 48 21.5 48 48V240l44.4 14.8c23.1 7.7 29.5 37.5 11.5 53.9l-101 92.6c-16.2 9.4-34.7 15.1-50.9 15.1c-19.6 0-40.8-7.7-59.2-20.3c-22.1-15.5-51.6-15.5-73.7 0c-17.1 11.8-38 20.3-59.2 20.3c-16.2 0-34.7-5.7-50.9-15.1l-101-92.6c-18-16.5-11.6-46.2 11.5-53.9L96 240V112c0-26.5 21.5-48 48-48h48V32zM160 218.7l107.8-35.9c13.1-4.4 27.3-4.4 40.5 0L416 218.7V128H160v90.7zM306.5 421.9C329 437.4 356.5 448 384 448c26.9 0 55.4-10.8 77.4-26.1l0 0c11.9-8.5 28.1-7.8 39.2 1.7c14.4 11.9 32.5 21 50.6 25.2c17.2 4 27.9 21.2 23.9 38.4s-21.2 27.9-38.4 23.9c-24.5-5.7-44.9-16.5-58.2-25C449.5 501.7 417 512 384 512c-31.9 0-60.6-9.9-80.4-18.9c-5.8-2.7-11.1-5.3-15.6-7.7c-4.5 2.4-9.7 5.1-15.6 7.7c-19.8 9-48.5 18.9-80.4 18.9c-33 0-65.5-10.3-94.5-25.8c-13.4 8.4-33.7 19.3-58.2 25c-17.2 4-34.4-6.7-38.4-23.9s6.7-34.4 23.9-38.4c18.1-4.2 36.2-13.3 50.6-25.2c11.1-9.4 27.3-10.1 39.2-1.7l0 0C136.7 437.2 165.1 448 192 448c27.5 0 55-10.6 77.5-26.1c11.1-7.9 25.9-7.9 37 0z" />
              </svg>
              <h4>Persistence Analysis</h4>
            </div>

            <button
              className={
                !persistenceCalc
                  ? "inputControlsHide"
                  : "inputControlsHide open"
              }
              onClick={() => setPersistenceCalc(!persistenceCalc)}
            >
              <span className="left-bar"></span>
              <span className="right-bar"></span>
            </button>
          </div>

          <div
            className={
              !persistenceCalc
                ? "persistenceInput_content hide_block"
                : "persistenceInput_content"
            }
          >
            <div className="multiInput">
              <div className="input">
                <label className="labelInput">Weather Window (hrs)</label>
                <CreatableSelect
                  onKeyDown={handleNumberOnlyInput}
                  defaultValue={weatherWindowOptions}
                  options={exampleWeatherWindowOptions}
                  isClearable
                  isMulti
                  className="persistenceOption"
                  onChange={(e) => {
                    setWeatherWindowOptions(e);
                    if (e.length > 0) {
                      setCustomOptions({ ...customOptions, window: e[0] });
                    } else {
                      setCustomOptions({
                        ...customOptions,
                        window: { label: "1000", value: "1000" },
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="multiInput">
              <div className="input">
                <label className="labelInput">
                  Significant Wave Height (m) [Hs]
                </label>
                <CreatableSelect
                  onKeyDown={handleNumberOnlyInput}
                  defaultValue={waveHeightOptions}
                  options={exampleWaveHeightOptions}
                  isClearable
                  isMulti
                  className="persistenceOption"
                  onChange={(e) => {
                    setWaveHeightOptions(e);
                    if (e.length > 0) {
                      setCustomOptions({ ...customOptions, wave: e[0] });
                    } else {
                      setCustomOptions({
                        ...customOptions,
                        wave: { label: "1000", value: "1000" },
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="multiInput">
              <div className="input">
                <label className="labelInput">Wave period (s) [Tp]</label>
                <CreatableSelect
                  onKeyDown={handleNumberOnlyInput}
                  defaultValue={wavePeriodOptions}
                  options={exampleWavePeriodOptions}
                  isClearable
                  isMulti
                  className="persistenceOption"
                  onChange={(e) => {
                    setWavePeriodOptions(e);
                    if (e.length > 0) {
                      setCustomOptions({ ...customOptions, tp: e[0] });
                    } else {
                      setCustomOptions({
                        ...customOptions,
                        tp: { label: "1000", value: "1000" },
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="multiInput">
              <div className="input">
                <label className="labelInput">
                  Mean Wind Speed (ms<sup>-1</sup>)
                </label>
                <CreatableSelect
                  onKeyDown={handleNumberOnlyInput}
                  defaultValue={windSpeedOptions}
                  options={exampleWindSpeedOptions}
                  isClearable
                  isMulti
                  className="persistenceOption"
                  onChange={(e) => {
                    setWindSpeedOptions(e);
                    if (e.length > 0) {
                      setCustomOptions({ ...customOptions, wind: e[0] });
                    } else {
                      setCustomOptions({
                        ...customOptions,
                        wind: { label: "1000", value: "1000" },
                      });
                    }
                  }}
                />
              </div>
            </div>

            <div className="per_buttons">
              <button
                className="per_custom"
                onClick={() => {
                  setLoadingStatus({
                    loading: true,
                    loadingMsg: "Calculating Persistence . . .",
                  });
                  persistenceAPICall();

                  /**
                   *
                   * GOOGLE ANALYTICS
                   *
                   */
                  logEvent(analytics, "select_content", {
                    content_type: "Persistence calculation",
                  });
                }}
              >
                Run Analysis
              </button>

              <button
                id="viewPersistenceResults"
                className="disabledButton"
                onClick={handleViewResults}
              >
                View Results
              </button>
            </div>
          </div>
        </div>

        <Modal
          className="gis_popups persistence_output"
          isOpen={persistenceResults}
          onRequestClose={() => persistenceResults}
        >
          <div>
            <div className="popup_heading">
              <h3>Persistence Analysis Results</h3>
              <button
                id="popup_close"
                onClick={() => setPersistenceResults(false)}
              >
                X
              </button>
            </div>

            <div className="popup_body">
              <div className="probability_output">
                <h4>Summary Table</h4>

                <div className="per_outputGraphs">
                  <div className="input">
                    <label>Percentile</label>
                    <Select
                      defaultValue={percentile[4]}
                      options={percentile}
                      onChange={(e) => setPercentileSelected(e.value)}
                    />
                  </div>

                  <TableContainer component={Paper} style={{ width: "85%" }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            style={{ fontWeight: 600, fontSize: "15px" }}
                          >
                            Weather Window (h)
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: 600, fontSize: "15px" }}
                          >
                            Wind Speed (ms<sup>-1</sup>)
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: 600, fontSize: "15px" }}
                          >
                            Wave Height (m)
                          </TableCell>
                          <TableCell
                            style={{ fontWeight: 600, fontSize: "15px" }}
                          >
                            Wave Period (s)
                          </TableCell>
                          {months.map((month) => (
                            <TableCell
                              style={{ fontWeight: 600, fontSize: "15px" }}
                            >
                              {month}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {platformData.weatherAnalysis.persistanceData.map(
                          (data, key) => {
                            return data !== null ? (
                              <TableRow key={key}>
                                <TableCell style={{ fontSize: "13px" }}>
                                  {data.window}
                                </TableCell>
                                <TableCell style={{ fontSize: "13px" }}>
                                  {data.wind}
                                </TableCell>
                                <TableCell style={{ fontSize: "13px" }}>
                                  {data.wave}
                                </TableCell>
                                <TableCell style={{ fontSize: "13px" }}>
                                  {data.tp}
                                </TableCell>
                                {data.fullData[percentileSelected / 10 - 1].map(
                                  (values) => (
                                    <TableCell style={{ fontSize: "13px" }}>
                                      {String(values)}
                                    </TableCell>
                                  )
                                )}
                              </TableRow>
                            ) : null;
                          }
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
              <hr></hr>

              <div className="probability_output">
                <h4>Custom Table and Plot</h4>

                <div className="pre_optionsBtns">
                  <div className="input">
                    <label>Weather Window</label>
                    <Select
                      defaultValue={setDefault(weatherWindowOptions)}
                      options={weatherWindowOptions}
                      onChange={(e) =>
                        setCustomOptions({ ...customOptions, window: e })
                      }
                    />
                  </div>
                  <div className="input">
                    <label>Wave Height</label>
                    <Select
                      defaultValue={setDefault(waveHeightOptions)}
                      options={waveHeightOptions}
                      onChange={(e) =>
                        setCustomOptions({ ...customOptions, wave: e })
                      }
                    />
                  </div>
                  <div className="input">
                    <label>Wave Period</label>
                    <Select
                      defaultValue={setDefault(wavePeriodOptions)}
                      options={wavePeriodOptions}
                      onChange={(e) =>
                        setCustomOptions({ ...customOptions, tp: e })
                      }
                    />
                  </div>
                  <div className="input">
                    <label>Wind Speed</label>
                    <Select
                      defaultValue={setDefault(windSpeedOptions)}
                      options={windSpeedOptions}
                      onChange={(e) =>
                        setCustomOptions({ ...customOptions, wind: e })
                      }
                    />
                  </div>
                </div>

                <div className="per_outputGraphs">
                  <TableContainer component={Paper} style={{ width: "60%" }}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>
                          {months.map((month) => (
                            <TableCell
                              style={{ fontWeight: 600, fontSize: "15px" }}
                            >
                              {month}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {percentile.map((percent, key) => (
                          <TableRow>
                            <TableCell
                              component="th"
                              style={{ fontWeight: 600, fontSize: "15px" }}
                            >
                              {percent.label}
                            </TableCell>
                            {platformData.weatherAnalysis.persistanceData.map(
                              (data) => {
                                if (
                                  String(data.window) ===
                                    customOptions.window.label &&
                                  String(data.wind) ===
                                    customOptions.wind.label &&
                                  String(data.wave) ===
                                    customOptions.wave.label &&
                                  String(data.tp) === customOptions.tp.label
                                ) {
                                  return data !== null ? (
                                    <>
                                      {data.fullData[key].map((values) => (
                                        <TableCell style={{ fontSize: "13px" }}>
                                          {String(values)}
                                        </TableCell>
                                      ))}
                                    </>
                                  ) : null;
                                }
                              }
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <CustomSingleLine
                    plotData={platformData.weatherAnalysis.persistanceData}
                    options={customOptions}
                    month={months}
                  />
                </div>
              </div>
              {/* <!-- <div class="weatherWindow_output">
              {{ per_customData }} {{ per_customPlot }}
            </div> --> */}
              {/* <div className="crossPlot_output">
              <div className="probability_output hs_tp">
                <h4>Significant Wave Height - Peak wave period (Hs-Tp)</h4>

                <div className="per_outputGraphs crossPlot_graphs">
                  {{ HsTpTable }} {{ HsTpPlot }}
                </div>
              </div>
              <div className="probability_output hs_ws">
                <h4>Significant Wave Height - Wind Speed (Hs-WS)</h4>

                <div className="per_outputGraphs crossPlot_graphs">
                  {{ HsWSTable }} {{ HsWSPlot }}
                </div>
              </div>
            </div> */}
            </div>

            <div class="popup_footer">
              <footer>
                {/* {{ downloadPresistReport }} */}
                <button onClick={() => handleDownloadReport("csv")}>
                  Download Raw Data
                </button>
                <button onClick={() => handleDownloadReport("pdf")}>
                  Download Persistence Report
                </button>
                <button onClick={() => setPersistenceResults(false)}>
                  Close
                </button>
              </footer>
            </div>
          </div>
        </Modal>
      </div>
    </LoadingOverlay>
  );
}

export default WeatherAnalysis;
