import React from 'react';
import Plot from 'react-plotly.js';

function MapPlot({ latitudes, longitudes, values, name }) {

    // Process data for the scatter plot
    const data = [{
        type: 'scatter',
        mode: 'markers',
        x: longitudes,
        y: latitudes,
        text: values.map(elevation => `${elevation}`), // Specify text for hover
        marker: {
            size: 10,
            color: values,
            colorscale: 'Viridis',
            colorbar: {
                thickness: 10, // Specify thickness of the color bar
                titleside: 'right', // Position of the color bar title
              },
        }
    }];

    const layout = {
        title: {
            text: name + " per turbine",
            font: {
                family: "Montserrat"
            }
        },
        font: { size: 13, family: "Montserrat" },
        autosize: true,
        legend: { font: { size: 13, family: "Montserrat" } },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        xaxis: {
         title: "Month",
         tickangle: 45
          },
          xaxis: {
            title: 'Longitude',
            gridcolor: "#EBECEC"
            
        },
        yaxis: {
            title: "Latitude",
            gridcolor: "#EBECEC"
        },
        //width: document.getElementById("weatherAnalysis_widget").style.width,
        height: "800px",
        legend: {
            orientation: "h", font: { size: 13, family: "Montserrat" },
            x: 0, // Center horizontally (0 is left, 1 is right)
            y: -0.4, // Center vertically (0 is bottom, 1 is top)
        },
        tickangle: -90,
        margin: {
            l: 75, // Adjust the left margin to create space for the legend
            r: 50, // Adjust the right margin to create space for the legend
            t: 75, // Adjust the top margin to create space for the legend
            b: 50, // Adjust the bottom margin to create space for the legend
        }
    }

    const config ={
        modeBarButtonsToRemove: ['pan2d','select2d','lasso2d','resetScale2d','zoomOut2d', 'autoScale2d','zoomIn2d', 'zoom2d'],
        responsive: true,
        toImageButtonOptions: {
            format: 'png', // one of png, svg, jpeg, webp
            filename: name,
            height: 500,
            width: 700,
            scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        }    }

    return (
        <Plot
            useResizeHandler
            style={{ width: '100%', height: '500px' }}
            className='weatherAnalysis_windSpeed'
            data={data}
            layout={layout}
            config={config}
        />
    );
}

export default MapPlot;
