import React, { useEffect, useState } from "react";
import useToggle from "./pricingScetion/useToggle.js";
import BackToTop from "./BackToTop.jsx";
import PricingHomeOne from "./pricingScetion/PricingHomeOne.jsx";
import FooterHomeThree from "./footerSection/FooterHomeThree.jsx";
import ProjectHomeThree from "./footerSection/ProjectHomeThree.jsx";
import HeaderHomeFour from "./navigationSection/HeaderHomeFour.jsx";
import HeroHomeFour from "./navigationSection/HeroHomeFour.jsx";

import "./assets/css/main.css";
import "./assets/css/style.css";
import "./assets/css/default.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom-animated.css";
// import "../../assets/css/font-awesome.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/magnific.dark.css";
import "./assets/css/magnific.rtl.css";

import Demo from "./modals/Demo.js";
// import UserProfile from "./modals/UserProfile.js";
import Login from "./modals/Login.js";
import { getProductInfo } from "./helperFunctions.js";
import FaqHomeOne from "./faqSection/FaqHomeOne.jsx";
import ServicesHomeEight from "./explanationSection/ServicesHomeEight.jsx";
import WorkPartHomeEight from "./explanationSection/WorkPartHomeEight.jsx";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase.js";
import SponserHomeTwo from "./footerSection/SponserHomeTwo.jsx";

function HomeFour({ homePage, setHomePage, authorized }) {
  const [user, laoding, error] = useAuthState(auth);

  const [drawer, drawerAction] = useToggle(false);
  const [demoSelected, setDemoSelected] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [loginSelected, setLoginSelected] = useState(false);

  const [toolInfo, setToolInfo] = useState({});

  useEffect(() => {
    const getToolInfo = async () => {
      const tools = await getProductInfo();

      console.log(tools);
      
      setToolInfo(tools);
    };

    getToolInfo();
  }, []);

  return (
    <div id="landing-page" style={{ overflowY: "scroll", height: "100vh" }}>
      <HeaderHomeFour
        action={drawerAction.toggle}
        setLoginSelected={setLoginSelected}
        setOpenProfile={setOpenProfile}
        homePage={homePage}
        setHomePage={setHomePage}
        authorized={authorized}
      />
      <HeroHomeFour
        setDemoSelected={setDemoSelected}
        setLoginSelected={setLoginSelected}
      />
      {/* // Option One ///////// */}
      {/* <ServicesHomeOne className="pt-190" />
      <ShowCaseHomeThree />
      <FeaturesHomeFour />
      <CounterArea style={{ backgroundColor: "#EEF1F6" }} /> */}
      {/* <VideoPlayerHomeTwo className="pt-100" /> */}
      {/* //////////////////////// */}

      {/* // Option Two ////// */}
      <ServicesHomeEight />
      <WorkPartHomeEight />
      {/* ///////////////////// */}

      <PricingHomeOne
        setLoginSelected={setLoginSelected}
        setDemoSelected={setDemoSelected}
        toolInfo={toolInfo}
        homePage={homePage}
        setHomePage={setHomePage}
      />
      <SponserHomeTwo />
      <FaqHomeOne setDemoSelected={setDemoSelected} />
      <ProjectHomeThree
        className="home-four-project"
        setDemoSelected={setDemoSelected}
      />
      <FooterHomeThree className="home-four-footer" />
      <BackToTop className="back-to-top-3" />

      {/* Modals */}
      <Demo demoSelected={demoSelected} setDemoSelected={setDemoSelected} />
      {/* <UserProfile
        openProfile={openProfile}
        setOpenProfile={setOpenProfile}
        setDemoSelected={setDemoSelected}
      /> */}
      <Login
        loginSelected={loginSelected}
        setLoginSelected={setLoginSelected}
      />
    </div>
  );
}

export default HomeFour;
