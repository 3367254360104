/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useState } from "react";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";
import L from "leaflet"
import { PlatformData, LoadingStatus } from "../../imports/Context";
import { useLocation, useNavigate } from "react-router-dom";

import {
  addLocationMarker,
  drawPoint,
  drawPolygon,
  drawPolyline,
  drawRectangle,
  extractGeometries,
  getSelectedCoords,
  getSelectedLatLngs,
  getSelectedType,
  guideLine,
  guideRectangle,
  guideTriangle,
  highlightSelected,
  logGeometriesFromPane,
  renderGeometries,
  renderSiteCables,
  resetDrawingLayer,
  resetGuideLine,
  resetGuideRectangle,
  resetGuideTriangle,
  updateElevationAnalysis,
  updateElevationAnalysisData,
  createSitePopup,
  placeTurbine,
  extractSiteCables,
  clearMeasurements
} from "./drawFunctions";

import * as turf from "@turf/turf";

import { useRef, useContext } from "react";

function Board({ board, setBoard }) {
  const { platformData, setPlatformData } = useContext(PlatformData);
  const { loadingStatus, setLoadingStatus } = useContext(LoadingStatus);

  const location = useLocation();
  const [mapReady, setMapReady] = useState(false)
  const [sitePopup, setSitePopup] = useState(null)
  const navigate = useNavigate()
  const map = useMap();
  const configRef = useRef({
    map: map,
    mode: board.mode,
    pane: "Board",

    interactions: {
      move: {
        alreaydMoving: false,
      }
    },
    layers: {
      selected: null,
      drawing: uuidv4(),
      guide: "guide",
    },
    style: {
      radius: 5,
      radiusH: 7,
      weight: 3,
      weightH: 5,
      color: "#009ec6",
      fillColor: "#009ec6",
      fillOpacity: 0.3,
      fillOpacityH: 0.6,
      zIndex: 500,
    },
    currentSite: null,
    platformData: platformData,
    setPlatformData: setPlatformData,
    setLoadingStatus: setLoadingStatus,
    updateBoard: () => {
      // EXTRACT GEOMETRIES:
      const geometries = extractGeometries(map, configRef.current.pane);
      setBoard((old_board) => ({
        ...old_board,
        ["points"]: geometries.points,
        ["lines"]: geometries.lines,
        ["polygons"]: geometries.polygons,
      }));

      setPlatformData((platformData) => ({
        ...platformData,
        ["drawnLayers"]: {
          ["points"]: geometries.points,
          ["lines"]: geometries.lines,
          ["polygons"]: geometries.polygons
        }
      }));
    },
    navigate: navigate,
  });

  useEffect(() => {
    configRef.current.platformData = platformData
    configRef.current.setPlatformData = setPlatformData
  }, [setPlatformData, platformData, configRef.current.platformData.siteBuilder])

  useEffect(() => {
    const config = configRef.current;
    const boardPane = map.createPane(config.pane);
    boardPane.style.zIndex = config.style.zIndex;

    configRef.current.map.whenReady(() => {
      setMapReady(true)
    })
  }, []);

  /**
* 
* On load of a saved project, re-populate the points, lines and polygons
* with the saved values.
* 
*/
  useEffect(() => {
    const points = platformData.drawnLayers.points
    const lines = platformData.drawnLayers.lines
    const polygons = platformData.drawnLayers.polygons

    if (points.length > 0 || lines.length > 0 || polygons.length > 0) {
      if (JSON.stringify(points) !== JSON.stringify(board.points) || JSON.stringify(lines) !== JSON.stringify(board.lines) || JSON.stringify(polygons) !== JSON.stringify(board.polygons)) {
        if (map && configRef.current) {
          setBoard({
            ...board,
            points: platformData.drawnLayers.points,
            lines: platformData.drawnLayers.lines,
            polygons: platformData.drawnLayers.polygons
          })

          renderGeometries(configRef, configRef.current.pane, platformData.drawnLayers)
        }
      }
    }
  }, [platformData.drawnLayers, map, configRef.current])

  useEffect(() => {
    configRef.current.mode = board.mode;
    resetDrawingLayer(configRef, map);
    resetGuideLine(configRef);
    resetGuideTriangle(configRef);
    resetGuideRectangle(configRef);
  }, [board.mode]);

  useEffect(() => {
    const mode = board.mode;

    const onClick = (e) => {
      switch (mode) {
        case "point":
          drawPoint(e, configRef);
          break;
        case "line":
          drawPolyline(e, configRef);
          guideLine(e, configRef);
          break;
        case "polygon":
          drawPolygon(e, configRef);
          guideTriangle(e, configRef);
          break;
        case "rectangle":
          drawRectangle(e, configRef);
          guideRectangle(e, configRef);
          resetGuideRectangle(configRef);
          break;
        case "select":
          highlightSelected(configRef);

          if (platformData.activeWidget === "ElevationAnalysis") {
            map.eachLayer((layer) => {
              if (layer._leaflet_id === configRef.current.selected) {
                var elevationArea = 0, elevationLength = 0
                if (layer instanceof L.Polygon || layer instanceof L.CircleMarker) {
                  elevationArea = turf.area(layer.toGeoJSON()) / 1000000
                } else {
                  elevationLength = turf.length(layer.toGeoJSON())
                }

                if (elevationArea <= 1000 && elevationLength <= 750) {
                  updateElevationAnalysisData(configRef, setPlatformData, setLoadingStatus);
                } else {
                  alert("The selected shape is too big! Please try again with a smaller shape")
                }
              }
            })
          }
          break;
        case "siteBuilder":
          setSitePopup(createSitePopup(configRef, e.latlng, "Board"))
          break;
        case "locationMarker":
          addLocationMarker(e, configRef)
          break;
        case "measure":
          highlightSelected(configRef);

          // addMeasureToShape()
          break;
      }

      configRef.current.updateBoard()
    };

    const onMove = (e) => {
      switch (mode) {
        case "point":
          break;
        case "line":
          guideLine(e, configRef);
          break;
        case "polygon":
          guideTriangle(e, configRef);
          break;
        case "rectangle":
          guideRectangle(e, configRef);
          break;
      }
    };

    const onRightClick = (e) => {
      resetDrawingLayer(configRef);
      resetGuideTriangle(configRef);
      resetGuideLine(configRef);
      resetGuideRectangle(configRef);

      configRef.current.updateBoard()

      if (configRef.current.currentSite) {
        const cables = extractSiteCables(configRef.current.map)

        const siteCables = []
        cables.forEach(cable => {
          if (cable.connectedTo.includes(configRef.current.currentSite)) {
            siteCables.push(cable)
          }
        });

        setPlatformData(platformData => ({
          ...platformData,
          ["siteBuilder"]: {
            ...platformData.siteBuilder,
            [configRef.current.currentSite]: {
              ...platformData.siteBuilder[configRef.current.currentSite],
              ["siteCables"]: siteCables
            }
          }
        }))
      }
    };

    const resetMode = (e) => {
      setBoard(board => ({
        ...board,
        ["mode"]: "default"
      }))
    }

    if (mode === "clearMeasure") {
      clearMeasurements(configRef.current.map, configRef.current.pane)
    }

    // map.on('polylinemeasure:start', resetMode);
    map.on("click", onClick);
    map.on("mousemove", onMove);
    map.on("contextmenu", onRightClick);
    return () => {
      map.off("click", onClick);
      map.off("mousemove", onMove);
      map.off("contextmenu", onRightClick);
      // map.off('polylinemeasure:toogle', resetMode);
    };
  }, [map, board.mode]);

  useEffect(() => {
    const turbines = platformData.siteBuilder

    configRef.current.map.eachLayer((layer) => {
      if (layer.options.pane === "site-builder" || layer.options.pane === "site-cables") {
        configRef.current.map.removeLayer(layer);
      }
    });

    if (sitePopup) {
      sitePopup.remove();
      setSitePopup(null)
    }

    Object.keys(turbines).map(uid => {
      if (turbines[uid] && mapReady) {
        if (turbines[uid].siteCables.length > 0 && turbines[uid].siteTurbs.length > 0) {
          const siteCables = []
          turbines[uid].siteCables.forEach(cable => {
            siteCables.push(cable)
          });

          renderSiteCables(configRef, siteCables, uid)
        }

        placeTurbine(turbines[uid].siteTurbs, turbines[uid], configRef, uid, turbines[uid].siteOSP, location.pathname)
      }
    })
  }, [platformData.siteBuilder, Object.keys(platformData.siteBuilder).length, mapReady])

  return null;
}

export default Board;

const test = {
  elevationJson: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [
            [
              [-3.186619245329363, 55.966390814516345],
              [-3.21307154517571, 55.94504688151061],
              [-3.1824968089896704, 55.94004570047028],
            ],
          ],
          type: "Polygon",
        },
      },
    ],
  },
  geoJSONtest: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [
            [
              [-2.223687478460306, 56.41117407644691],
              [-2.223687478460306, 55.93144394643514],
              [-1.2445339343534556, 55.93144394643514],
              [-1.2445339343534556, 56.41117407644691],
              [-2.223687478460306, 56.41117407644691],
            ],
          ],
          type: "Polygon",
        },
      },
    ],
  },
};