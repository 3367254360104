/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useNavigate,
  useLocation,
  useSearchParams,
  redirect,
} from "react-router-dom";
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableFooter, TablePagination } from '@mui/material';
import DataTable from "react-data-table-component";

import WidgetButton from "./WidgetButton";
import { auth, db, logout, storage, analytics, app } from "../../firebase";
// import { ref, uploadBytes } from "firebase/storage";
import {
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  updateDoc,
  deleteField,
  collection,
} from "firebase/firestore";
import Demo from "../../landing/modals/Demo";

import vektaGroup from "../../media/VG_Logo_WhiteVector.png";
import { StoreWidgets } from "../../imports/Context";
import { PlatformData } from "../../imports/Context";

import styled from "styled-components";

import { logEvent } from "firebase/analytics";

import { InputTextarea } from "primereact/inputtextarea";
import { Message } from "primereact/message";
import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  Avatar,
  Badge,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";

import { BounceLoader } from "react-spinners";

import { ref, getDownloadURL } from "firebase/storage";

import HelpModal from "./modals/HelpModal";
import SavePopup from "./modals/saveLoadShare/SavePopup";
import ContextMenu from "./common/ContextMenu";
import UserProfile from "./modals/UserProfile";
import QuickSaveLoad from "./modals/saveLoadShare/QuickSaveLoad";

import {
  formatCreateInput,
  handleLoad,
  handleSave,
} from "./modals/helperFunctions";
import { getPortalUrl } from "./modals/vektaStore/helperFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import ProductKeyEntry from "./modals/vektaStore/sidepanelComponents/ProductKeyEntry";
import InstallTool from "./modals/vektaStore/sidepanelComponents/InstallTool";

// Provided from MUI
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#9feed6",
    color: "#9feed6",
    boxShadow: `0 0 0 2px #009ec6`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const ReplayTour = styled.button`
  margin-right: 25px;
  border: none;
  padding: 16px;
  border-radius: 8px;
  height: 100%;

  &:hover {
    color: white;
    background: #007592;

    img,
    svg {
      filter: invert(1);
    }
  }
`;

function Sidebar({
  sidebarContent,
  setSidebarContent,
  setRunJoyride,
  setRunJoyrideSteps,
  steps,
  stepsMap,
  platformStructure,
}) {
  const [isGreyedOut, setIsGreyedOut] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const { platformData, setPlatformData } = useContext(PlatformData);
  const widgets = useContext(StoreWidgets);

  const [saveLoad, setSaveLoad] = useState({
    open: false,
    currentSelect: "save",
  });
  const [customerService, setCustomerService] = useState(false);
  const [guidance, setGuidance] = useState(false);
  const [isSaving, setIsSaving] = useState("initial");

  const [store, setStore] = useState({
    open: false,
    currentSelect: "allTools",
  });

  const [dot, setDot] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [settingsMenu, setSettingsMenu] = useState({
    save: { open: false, source: null },
    profile: { open: false, source: null },
    upgrade: { open: false, source: null },
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [quickSave, setQuickSave] = useState({ open: false, type: "Save" });
  const [allSaves, setAllSaves] = useState({
    pName: [
      { label: "Personal Projects", options: [] },
      { label: "Shared Projects", options: [] },
    ],
    sName: [
      { label: "Personal Scenarios", options: [] },
      { label: "Shared Scenarios", options: [] },
    ],
  });
  const [projectIds, setProjectIds] = useState({ personal: [], shared: [] });
  const [userAccount, setUserAccount] = useState({ file: null, fileData: {} });
  const [userSaves, setUserSaves] = useState({ personal: {}, shared: {} });
  const [externalInvites, setExternalInvites] = useState([]);
  const [scenarioSnap, setScenarioSnap] = useState({});
  const [recentOpened, setRecentOpened] = useState([]);

  const [openProductKey, setOpenProductKey] = useState({
    open: false,
    type: null,
  });
  const [openInstall, setOpenInstall] = useState({
    open: false,
    type: null,
  });

  const [subscribeUrl, setSubscriptionUrl] = useState(null);
  const [profileImg, setProfileImg] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [promptStatus, setPromptStatus] = useState({
    shown: false,
    success: true,
    message: "",
  });

  const [savedProjects, setSavedProjects] = useState([]);
  const [deletePop, setDeletePop] = useState({
    open: false,
    key: null,
    type: null,
  });

  const navigate = useNavigate();
  const location = useLocation();

  var date = new Date();
  const dateTime =
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " @ " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();

  const [comment, setComment] = useState("");
  const [messageSent, setMessageSent] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);

  const handleAddComment = () => {
    const functions = getFunctions(app, "us-central1");
    const addComment = httpsCallable(functions, "addComment");
    setMessageLoading(true);

    addComment({ comment: comment })
      .then((result) => {
        setMessageSent(result.data.success);
        setComment("");
        setMessageLoading(!result.data.success);
        // handleCloseModal(); // Close modal on successful comment submission
      })
      .catch((error) => {
        // Getting the Error details.
        setMessageSent(false);
        console.log(error.code);
      });
  };

  const handleMetaDownload = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "appMisc/layerDataReference.xlsx"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "LayerSources.xlsx";
        a.target = "_blank";
        a.click();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  const savedTableColumns = [
    {
      name: "Project Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Last Updated",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      width: "180px",
    },
  ];

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    if (isSaving !== "initial") {
      setIsSaving("saved/loaded");
    }
  }, [user, loading, platformData, isSaving]);

  useEffect(() => {
    if (platformData.sidePanelOpen) {
      setSidebarContent(true);
    } else {
      setSidebarContent(false);
    }
  }, [platformData.sidePanelOpen]);

  useEffect(() => {
    if (location.pathname !== "/vekta_digital") {
      setDot(".");
    } else {
      setDot("");
    }
  }, [location]);

  /**
   * Snapshot listeners initialization
   */
  useEffect(() => {
    if (user && !user.isAnonymous) {
      try {
        /**
         * Observing a users personal projects for changes. This saves the project IDs for updating the
         * tables in the popup.
         */
        const pProjects = onSnapshot(
          collection(db, "userSaves", user.uid, "personal"),
          (snap) => {
            try {
              // Resetting useStatue to combat duplication
              setAllSaves((allSaves) => ({
                ...allSaves,
                pName: [
                  { label: "Personal Projects", options: [] },
                  { label: "Shared Projects", options: [] },
                ],
              }));
              setUserSaves((userSaves) => ({ ...userSaves, personal: {} }));
              setProjectIds((projectIds) => ({ ...projectIds, personal: [] }));

              Object.keys(scenarioSnap).map((key) => {
                scenarioSnap[key]();
              });
              setScenarioSnap({});

              snap.forEach((doc) => {
                if (doc.data()) {
                  setProjectIds((projectIds) => ({
                    ...projectIds,
                    personal: [
                      ...projectIds.personal,
                      { id: doc.id, ...doc.data() },
                    ],
                  }));
                }
              });
            } catch (error) {
              console.log(error);
            }
          }
        );

        /**
         * Observing a users personal projects for changes. This saves the project IDs for updating the
         * tables in the popup.
         */
        const sProjects = onSnapshot(doc(db, "userSaves", user.uid), (snap) => {
          // Resetting useStatue to combat duplication
          setAllSaves((allSaves) => ({
            ...allSaves,
            pName: [
              { label: "Personal Projects", options: [] },
              { label: "Shared Projects", options: [] },
            ],
          }));
          setUserSaves((userSaves) => ({ ...userSaves, shared: {} }));
          setProjectIds((projectIds) => ({ ...projectIds, shared: [] }));
          setRecentOpened([]);

          Object.keys(scenarioSnap).map((key) => {
            scenarioSnap[key]();
          });
          setScenarioSnap({});

          if (snap.data().recent) setRecentOpened(snap.data().recent);

          if (snap.data().shared.length > 0) {
            snap.data().shared.forEach(async (projID) => {
              const projRepo = await getDoc(doc(db, "projects", projID));

              const projDoc = await getDoc(projRepo.data().docRef);

              if (projDoc.data()) {
                setProjectIds((projectIds) => ({
                  ...projectIds,
                  shared: [
                    ...projectIds.shared,
                    {
                      id: projDoc.id,
                      proj: projRepo.data().docRef,
                      ...projDoc.data(),
                    },
                  ],
                }));
              }
            });
          }
        });

        /**
         * Signed in users "userAccount" snapshot listener
         */
        const account = onSnapshot(
          doc(db, "userAccounts", user.uid),
          (foundAccount) => {
            setUserAccount({
              file: foundAccount,
              fileData: foundAccount.data(),
            });
          }
        );

        /**
         * Signed in users "invites" snapshot listener - This is to populate the users
         * invitations table for shared projects
         */
        const invitations = onSnapshot(
          collection(db, "userAccounts", user.uid, "invites"),
          (invites) => {
            setExternalInvites([]);
            if (invites.empty) setExternalInvites([]);

            invites.forEach((invite) => {
              const info = invite.data();
              info["id"] = invite.id;

              setExternalInvites((externalInvites) => [
                ...externalInvites,
                info,
              ]);
            });
          }
        );

        const stripeRef = doc(db, "customers", user.uid);
        const stripeAccounts = onSnapshot(stripeRef, async (doc) => {
          const portalUrl = await getPortalUrl();
          setSubscriptionUrl(portalUrl);
        });
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }
  }, [user, refresh]);

  /**
   * Event listener for ctrl+s for saving a project quickly
   */
  useEffect(() => {
    const handleKeyDown = (event) => {
      const code = event.which || event.keyCode;

      let charCode = String.fromCharCode(code).toLowerCase();
      if ((event.ctrlKey || event.metaKey) && charCode === "s") {
        event.preventDefault();
        if (searchParams.get("projectName")) {
          const saveNames = searchParams.get("projectName").split(" - ");

          setPromptStatus(
            handleSave(
              user,
              { value: saveNames[0] },
              { value: saveNames[1] },
              platformData,
              dateTime,
              userSaves,
              "personal"
            )
          );
        } else {
          setQuickSave({ open: true, type: "Save" });
        }
      }
      // else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
      //   // setState('CTRL+C');
      //   alert('CTRL+C Pressed');
      // } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
      //   // setState('CTRL+V');
      //   alert('CTRL+V Pressed');
      // }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [platformData]);

  useEffect(() => {
    if (user) {
      const getUserInfo = async (uid) => {
        try {
          const userInfo = await getDoc(doc(db, "userAccounts", uid));

          return new Promise((resolve, reject) => {
            if (userInfo) {
              resolve(userInfo.data());
            } else {
              reject(new Error("No user info found"));
            }
          });
        } catch (error) {
          console.error(error);
        }
      };

      getUserInfo(user.uid).then((info) => {
        setProfileImg(info.picture);
      });
    }
  }, [user]);

  // useEffect(() => {
  //   if (userSaves.fileData !== undefined) {
  //     setSavedProjects(
  //       Object.keys(userSaves.fileData.personal).map((key, i) => {
  //         return {
  //           id: i,
  //           name: key,
  //           date: userSaves.fileData.personal[key].lastSaved,
  //           actions: [
  //             <button
  //               title="Delete Project"
  //               onClick={() =>
  //                 setDeletePop({ open: true, key: key, type: "personal" })
  //               }
  //               className="saveloadshare_action"
  //             >
  //               <svg
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 height="15px"
  //                 viewBox="0 0 448 512"
  //               >
  //                 {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
  //                 <path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" />
  //               </svg>
  //             </button>,
  //             <button
  //               title="Load Project"
  //               onClick={() => handleLoad(key, "personal")}
  //               className="saveloadshare_action"
  //             >
  //               <svg
  //                 xmlns="http://www.w3.org/2000/svg"
  //                 height="15px"
  //                 viewBox="0 0 576 512"
  //               >
  //                 {/* <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
  //                 <path d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z" />
  //               </svg>
  //             </button>,
  //           ],
  //         };
  //       })
  //     );
  //   }
  // }, [userSaves.fileData]);

  // const handleSave = async () => {
  //   try {
  //     if (!user.isAnonymous) {
  //       document.getElementById("saveLoadBanner").innerHTML =
  //         "Project Saved Successfully";
  //       setIsSaving("saving");

  //       let new_data = {};
  //       const docRef = doc(db, "userSaves", user.uid);
  //       const filename = document.getElementById("projectSaveName").value;

  //       var date = new Date();
  //       const dateTime =
  //         date.getDate() +
  //         "/" +
  //         (date.getMonth() + 1) +
  //         "/" +
  //         date.getFullYear() +
  //         " @ " +
  //         date.getHours() +
  //         ":" +
  //         date.getMinutes() +
  //         ":" +
  //         date.getSeconds();

  //       new_data = {
  //         personal: {
  //           ...userSaves.fileData.personal,
  //           [filename]: {
  //             save: JSON.stringify(platformData),
  //             lastSaved: dateTime,
  //           },
  //         },
  //       };

  //       await setDoc(docRef, new_data, { merge: true });

  //       const param = searchParams.get("projectName");

  //       if (param) {
  //         searchParams.delete("projectName");
  //         setSearchParams(searchParams);
  //       }
  //     }

  //     /**
  //      *
  //      * GOOGLE ANALYTICS
  //      *
  //      */
  //     logEvent(analytics, "select_content", {
  //       content_type: "Save project",
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleLoad = (projectLoad, type) => {
  //   try {
  //     if (!user.isAnonymous) {
  //       document.getElementById("saveLoadBanner").innerHTML =
  //         "Project Loaded Successfully";
  //       setIsSaving("loading");

  //       // const projectLoad = document.getElementById("projectLoadName").value
  //       setSearchParams({ ...searchParams, projectName: projectLoad });
  //       setOpenedProject(projectLoad);
  //       document.getElementById("projectSaveName").value = projectLoad;
  //       setPlatformData(JSON.parse(userSaves.fileData[type][projectLoad].save));
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleDelete = async (projectDelete, type) => {
  //   try {
  //     if (!user.isAnonymous) {
  //       document.getElementById("saveLoadBanner").innerHTML =
  //         "Project Deleted Successfully";
  //       setIsSaving("loading");

  //       const docRef = doc(db, "userSaves", user.uid);

  //       const temp = userSaves.fileData[type];
  //       delete temp[projectDelete];

  //       await updateDoc(docRef, {
  //         ...userSaves.fileData,
  //         [type]: temp,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const resetUseState = (item) => {
    setSettingsMenu((settingsMenu) => ({
      ...settingsMenu,
      [item]: { open: false, source: null },
    }));
  };

  const handleLogout = () => {
    setIsGreyedOut(true);

    setPlatformData(platformStructure);
    logout();
  };

  return (
    <div className="sidebarButtons">
      <div className="widgetBtns">
        {widgets.map((val, key) => {
          return val !== 0 ? (
            <WidgetButton
              dot={dot}
              name={val.name}
              svg={val.svg}
              title={val.title}
              currentWidget={platformData.activeWidget}
              sidebarContent={sidebarContent}
              setSidebarContent={setSidebarContent}
            />
          ) : null;
        })}
      </div>

      <div className="settingsBtns">
        {/* {user && !user.isAnonymous ? ( */}
        {null && (
          <button
            title="Coming Soon - Vekta Store"
            disabled
            style={{ opacity: "0.6" }}
            onClick={() => setStore({ ...store, open: true })}
          >
            <img src={dot + "./svg/store-solid.svg"} />
          </button>
        )}
        <button
          title="Upgrade to Vekta Digital + now"
          onClick={(e) =>
            setSettingsMenu((settingsMenu) => ({
              ...settingsMenu,
              upgrade: { open: true, source: e.target },
            }))
          }
        >
          {/* <img src={dot + "./svg/store-solid.svg"} /> */}
          <FontAwesomeIcon icon={faCartPlus} />
        </button>
        <ContextMenu
          status={settingsMenu.upgrade.source}
          item={"upgrade"}
          reset={resetUseState}
          sectionOne={[
            {
              name: <strong>Upgrade Now</strong>,
              callback: () => {
                // setOpenProfile(true);
                setOpenInstall({ open: true, type: null });
                resetUseState("upgrade");
              },
            },
          ]}
          sectionTwo={[
            {
              name: <strong>Activate with Product Key</strong>,
              callback: () => {
                setOpenProductKey({ open: true, type: null });
                resetUseState("upgrade");
              },
            },
          ]}
        />

        {user && !user.isAnonymous ? (
          <>
            <button
              id="save-btn"
              title="Save/Load"
              onClick={(e) =>
                setSettingsMenu((settingsMenu) => ({
                  ...settingsMenu,
                  save: { open: true, source: e.target },
                }))
              }
            >
              {/* <img src={dot + "./svg/saveLoad.svg"} /> */}
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                variant="dot"
                invisible={externalInvites.length === 0}
              >
                <Avatar alt="Remy Sharp" src={dot + "./svg/saveLoad.svg"} />
              </StyledBadge>
            </button>
            <ContextMenu
              status={settingsMenu.save.source}
              // status={saveButton}
              item={"save"}
              reset={resetUseState}
              sectionOne={[
                {
                  name: (
                    <div>
                      <strong>Open</strong>
                    </div>
                  ),
                  callback: () => {
                    setQuickSave({ open: true, type: "Open" });
                    resetUseState("save");
                  },
                },
                {
                  name: (
                    <div style={{ width: "100%" }}>
                      <strong>Save</strong>{" "}
                      <span style={{ float: "right" }}>Ctrl+S</span>
                    </div>
                  ),
                  callback: () => {
                    setQuickSave({ open: true, type: "Save" });
                    resetUseState("save");
                  },
                },
                {
                  name: (
                    <div>
                      <strong>Save and Close Project</strong>
                    </div>
                  ),
                  callback: () => {
                    setQuickSave({ open: true, type: "Save and Close" });
                    resetUseState("save");
                  },
                },
                {
                  name: (
                    <div title="Close current project and reset">
                      <strong>Close Project</strong>
                    </div>
                  ),
                  callback: () => {
                    // setQuickSave({ open: true, type: "Save and Close" });
                    setPlatformData(platformStructure);
                    setSearchParams({});
                    resetUseState("save");
                  },
                },
                {
                  name: (
                    <div title="Reset Map">
                      <strong>Reset Map</strong>
                    </div>
                  ),
                  callback: () => {
                    // setQuickSave({ open: true, type: "Save and Close" });
                    setPlatformData(platformStructure);
                    setSearchParams({});
                    resetUseState("save");
                  },
                },
              ]}
              sectionTwo={[
                {
                  name: <strong>All Projects</strong>,
                  callback: () => {
                    setSaveLoad({ ...saveLoad, open: true });
                    resetUseState("save");
                  },
                },
              ]}
            />
          </>
        ) : null}

        <button
          id="profile-btn"
          title="Profile"
          onClick={(e) =>
            // setProfileButton(e.target)
            setSettingsMenu((settingsMenu) => ({
              ...settingsMenu,
              profile: { open: true, source: e.target },
            }))
          }
        >
          {/* <FontAwesomeIcon icon={faUserCircle} /> */}
          <Avatar
            className="profilePicture"
            sx={{
              background: "white",
              color: "#00A0C6",
              // width: "30px",
              // height: "30px",
            }}
            src={profileImg && profileImg}
          />
        </button>
        <ContextMenu
          status={settingsMenu.profile.source}
          item={"profile"}
          reset={resetUseState}
          sectionOne={[
            {
              name: <strong>View Profile</strong>,
              callback: () => {
                setOpenProfile(true);
                resetUseState("profile");
              },
            },
            { name: <strong>Sign Out</strong>, callback: handleLogout },
          ]}
          sectionTwo={[
            {
              name: <strong>Help</strong>,
              callback: () => {
                setCustomerService(true);
                resetUseState("profile");
              },
            },
            {
              name: <strong>Guidance & Data</strong>,
              callback: () => {
                setGuidance(true);
                resetUseState("profile");
              },
            },
          ]}
        />

        {isGreyedOut && (
          <div className="grey-screen-overlay">
            <div className="loggingout-text">Logging Out...</div>
          </div>
        )}
      </div>

      {/* <Modal
        className="saveLoad_popup"
        isOpen={saveLoad.open}
        onRequestClose={() => saveLoad.open}
      >
        <span
          id="saveLoadBanner"
          className={
            isSaving !== "saved/loaded"
              ? "saveLoad_span"
              : "saveLoad_span toggle_display"
          }
        >
          Project Saved Successfully
        </span>
        <div className="popup_heading">
          <button
            className="popup_close"
            onClick={() => {
              setSaveLoad({ ...saveLoad, open: false });
              setIsSaving("initial");
            }}
          >
            X
          </button>
        </div>

        <div className="popup_body">
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div className="saveCurrent_project">
              <div className="input">
                <label>Project Name</label>
                <input
                  value={openedProject && openedProject}
                  id="projectSaveName"
                  type="text"
                  placeholder="Project Name"
                />
              </div>
              <ReplayTour style={{ marginLeft: "8px" }} onClick={handleSave}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="16"
                  width="14"
                  viewBox="0 0 448 512"
                >
                  <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V173.3c0-17-6.7-33.3-18.7-45.3L352 50.7C340 38.7 323.7 32 306.7 32H64zm0 96c0-17.7 14.3-32 32-32H288c17.7 0 32 14.3 32 32v64c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V128zM224 288a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
              </ReplayTour>
            </div>
          </div>

          <div>
            <DataTable
              title="Personal Projects"
              columns={savedTableColumns}
              data={savedProjects}
              pagination
            />
          </div>
        </div>

        <div className="popup_footer">
          <footer>
            <button
              onClick={() => {
                setSaveLoad({ ...saveLoad, open: false });
                setIsSaving("initial");
              }}
            >
              Done
            </button>
          </footer>
        </div>
      </Modal> */}

      {/* <Modal
        className="shareWith_internalPopup"
        isOpen={deletePop.open}
        onRequestClose={() => deletePop.open}
      >
        <div className="popup_heading">
          <h3>Are You Sure?</h3>
          <button
            className="popup_close"
            onClick={() => {
              setDeletePop({ open: false, key: null });
            }}
          >
            X
          </button>
        </div>

        <p>
          You are about to delete a project! Onced delete this project cannot be
          recovered.
        </p>

        <footer className="popup_footer">
          <button
            className="cancel_button"
            onClick={() => {
              setDeletePop({ open: false, key: null });
            }}
          >
            Cancel
          </button>
          <button
            className="share_button"
            onClick={() => {
              handleDelete(deletePop.key, deletePop.type);
              setDeletePop({ open: false, key: null });
            }}
          >
            Delete
          </button>
        </footer>
      </Modal> */}

      <SavePopup
        saveLoad={saveLoad}
        setSaveLoad={setSaveLoad}
        projectIds={projectIds}
        allSaves={allSaves}
        setAllSaves={setAllSaves}
        userAccount={userAccount}
        userSaves={userSaves}
        setUserSaves={setUserSaves}
        externalInvites={externalInvites}
        scenarioSnap={scenarioSnap}
        setScenarioSnap={setScenarioSnap}
        recentOpened={recentOpened}
        setRefresh={setRefresh}
      />

      <UserProfile
        userAccount={userAccount}
        userSaves={userSaves}
        openProfile={openProfile}
        subUrl={subscribeUrl}
        setOpenProfile={setOpenProfile}
        setCustomerService={setCustomerService}
      />

      <QuickSaveLoad
        openQuick={quickSave}
        setOpenQuick={setQuickSave}
        userSaves={userSaves}
        allSaves={allSaves}
        platformStructure={platformStructure}
        setSearchParams={setSearchParams}
        setPromptStatus={setPromptStatus}
      />

      <Demo
        demoSelected={customerService}
        setDemoSelected={setCustomerService}
      />

      <HelpModal
        customerService={guidance}
        setCustomerService={setGuidance}
        setRunJoyride={setRunJoyride}
        setRunJoyrideSteps={setRunJoyrideSteps}
        steps={steps}
        stepsMap={stepsMap}
      />

      {openProductKey.open && (
        <ProductKeyEntry setSidePanel={setOpenProductKey} />
      )}
      {openInstall.open && (
        <InstallTool
          info={{
            info: {
              description: "BlahBlahBlah",
              name: "Vekta Digital +",
              metadata: [],
            },
            prices: [
              { id: 0, tiers: [{ unit_amount: 0 }] },
              { id: 1, tiers: [{ unit_amount: 1 }] },
            ],
          }}
          setSidePanel={setOpenInstall}
        />
      )}
    </div>
  );
}

export default Sidebar;
