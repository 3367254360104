import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
// import { ModalClose, PrimeButton, StyledIconButton } from '../../../styles/styles'
import CloseIcon from "@mui/icons-material/Close";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { useAuthState } from "react-firebase-hooks/auth";
import { doc, setDoc } from "firebase/firestore";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

import Select from "react-select";
import { auth, db } from "../../firebase";
import { ModalClose, PrimeButton, StyledIconButton } from "../../styles/styles";

const InfoPrompt = styled("span")`
  width: 95%;
  height: 30px;
  padding: 8px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.success ? "rgba(0, 196, 84, 1)" : "rgb(206, 69, 69)"};

  display: flex;
  align-items: center;
  justify-content: center;
`;

function Demo({ demoSelected, setDemoSelected }) {
  const [user, loading, error] = useAuthState(auth);
  const [validContact, setValidContact] = useState(true);
  const [commentSent, setCommentSent] = useState(false);
  const [contact, setContact] = useState({
    name: null,
    email: null,
    org: null,
    comment: null,
    type: null,
  });

  const validateContact = () => {
    console.log(contact.type);
    if (
      contact.name === null ||
      contact.email === null ||
      contact.comment === null ||
      contact.type === null
    ) {
      setValidContact(true);
      return;
    }

    setValidContact(false);
    return;
  };

  const handleContact = () => {
    setDoc(doc(db, "contact_us", uuidv4()), contact)
      .then((done) => {
        setCommentSent(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    validateContact();
  }, [contact]);

  useEffect(() => {
    if (commentSent) {
      setTimeout(() => {
        setCommentSent(false);
      }, 5000);
    }
  }, [commentSent]);

  return (
    <Modal
      open={demoSelected}
      onClose={() => setDemoSelected(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: 2,
            position: "fixed",
            inset: "auto 35%",
            gap: "16px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          {commentSent && (
            <InfoPrompt success={true}>Thank you for your feedback!</InfoPrompt>
          )}

          <header>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <h1>Contact Us!</h1>
              <ModalClose onClick={() => setDemoSelected(false)}>
                <StyledIconButton>
                  <CloseIcon />
                </StyledIconButton>
              </ModalClose>
            </div>
          </header>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <span
                className="p-float-label"
                style={{ width: "100%", marginBottom: "24px", height: "3rem" }}
              >
                <InputText
                  id="toolLogin_email"
                  style={{ width: "100%", height: "3rem", padding: "8px" }}
                  onChange={(e) => {
                    setContact((contact) => ({
                      ...contact,
                      name: e.target.value.length > 0 ? e.target.value : null,
                    }));
                  }}
                />
                <label htmlFor="toolLogin_email">Name *</label>
              </span>

              <span
                className="p-float-label"
                style={{ width: "100%", marginBottom: "24px", height: "3rem" }}
              >
                <InputText
                  id="toolLogin_email"
                  style={{ width: "100%", height: "3rem", padding: "8px" }}
                  onChange={(e) => {
                    setContact((contact) => ({
                      ...contact,
                      email: e.target.value.length > 0 ? e.target.value : null,
                    }));
                  }}
                />
                <label htmlFor="toolLogin_email">Email *</label>
              </span>

              <span
                className="p-float-label"
                style={{ width: "100%", marginBottom: "24px", height: "3rem" }}
              >
                <InputText
                  id="toolLogin_email"
                  style={{ width: "100%", height: "3rem", padding: "8px" }}
                  onChange={(e) => {
                    setContact((contact) => ({
                      ...contact,
                      org: e.target.value.length > 0 ? e.target.value : null,
                    }));
                  }}
                />
                <label htmlFor="toolLogin_email">Company/Organisation</label>
              </span>

              {/* <Select
                                className="cableSelect"
                                classNamePrefix="select"
                                // placeholder={placeholder}
                                // isClearable
                                isSearchable
                                // styles={customStyles}
                                value={{ label: "Tool Feedback", value: "feedback" }}
                                defaultValue={{ label: "Tool Feedback", value: "feedback" }}
                                onChange={(e) => {
                                    setContact(contact => ({
                                        ...contact,
                                        type: e
                                    }))
                                }}
                                options={[{ label: "Issue with Tool", value: "bug" }, { label: "Tool Feedback", value: "feedback" }, { label: "Book a Meeting", value: "meeting" }]}
                            /> */}

              <span
                style={{ width: "100%", height: "4rem", marginBottom: "24px" }}
              >
                <label htmlFor="userOrgType" style={{ top: "-20px" }}>
                  Contact Type
                </label>
                <Select
                  id="userOrgType"
                  defaultValue={{ label: "Tool Feedback", value: "feedback" }}
                  isSearchable
                  options={[
                    { label: "Issue with Tool", value: "bug" },
                    { label: "Tool Feedback", value: "feedback" },
                    { label: "Book a Meeting", value: "meeting" },
                  ]}
                  styles={{ height: "3rem" }}
                  onChange={(e) => {
                    setContact((contact) => ({
                      ...contact,
                      type: e,
                    }));
                  }}
                />
              </span>

              <span
                className="p-float-label"
                style={{ width: "100%", marginTop: "24px" }}
              >
                <InputTextarea
                  id="username"
                  rows={5}
                  cols={30}
                  style={{ width: "100%", padding: "8px" }}
                  onChange={(e) => {
                    setContact((contact) => ({
                      ...contact,
                      comment:
                        e.target.value.length > 0 ? e.target.value : null,
                    }));
                  }}
                />
                <label htmlFor="username">Comment * </label>
              </span>

              <PrimeButton
                disabled={validContact}
                style={{ width: "100%", marginTop: "16px" }}
                onClick={handleContact}
              >
                Submit Request
              </PrimeButton>
            </div>

            {/* <video style={{ borderRadius: "8px" }} width={"60%"} height={"100%"} src='' controls /> */}
          </div>
        </Box>
      </div>
    </Modal>
  );
}

export default Demo;
