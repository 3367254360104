import React from "react";
import workThumb from "../../media/VD_illustration.png";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";

function WorkPartHomeEight() {
  const handleDownloadValidation = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "validation_docs/VCAB-VGED-TEC-001.pdf"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "CIGRE-Validation.pdf";
        a.target = "_blank";
        a.click();
        a.remove();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    <>
      <div className="appie-how-it-work-area pt-10 pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="how-it-work-thumb text-center">
                <img src={workThumb} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="appie-how-it-work-content">
                <h2 className="title" style={{ textTransform: "none" }}>
                  How it all connects
                </h2>
                <p>
                  our user vision is to enable you to have a project engineering
                  platform that stays with you for the whole development life
                  cycle.
                </p>

                <div className="row">
                  <div>
                    <div className="how-it-work-box">
                      <span>1</span>
                      <h5 className="title">
                        Access our extensive collection of trusted global
                        datasets or upload your own data.
                      </h5>
                    </div>
                  </div>
                  <div>
                    <div className="how-it-work-box">
                      <span>2</span>
                      <h5 className="title">
                        Easily and quickly build a site, either using a polygon
                        or just pick any point.
                      </h5>
                    </div>
                  </div>
                  <div>
                    <div className="how-it-work-box">
                      <span>3</span>
                      <h5 className="title">
                        Analyse weather and/or elevation data to help you assess
                        your site specific conditions.
                      </h5>
                    </div>
                  </div>
                  <div>
                    <div className="how-it-work-box">
                      <span>4</span>
                      <h5 className="title">
                        Run yield analysis to help take your understanding of
                        site potential to the next level.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkPartHomeEight;
