import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createNewProject,
  getPurchasedTools,
  getSharingGroup,
  getSubUsers,
  getUserProfile,
  removeUser,
} from "./vektaStore/helperFunctions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, app, storage } from "../../../firebase";
import DataTable from "react-data-table-component";

import { Box, Divider, Modal, Avatar, Badge } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPen as EditControl,
  faEllipsisVertical,
  faUserMinus,
} from "@fortawesome/free-solid-svg-icons";
import { faComments as ChatIcon } from "@fortawesome/free-regular-svg-icons";

import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";

import SettingsMenu from "../common/SettingsMenu";
import IconButton from "@mui/material/IconButton";
import { getFunctions, httpsCallable } from "firebase/functions";

import { Password } from "primereact/password";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { TabMenu } from "primereact/tabmenu";

import { TabView, TabPanel } from "primereact/tabview";
import {
  ModalClose,
  PrimeButton,
  SecButton,
  StyledIconButton,
} from "../../../styles/styles.js";
import ContextMenu from "../common/ContextMenu.js";
import { MultiSelect } from "primereact/multiselect";

import Select from "react-select";
import { formatCreateInput } from "./helperFunctions.js";

import CloseIcon from "@mui/icons-material/Close";
import { sendPasswordResetEmail } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { BeatLoader } from "react-spinners";

const EditIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  background-color: ${(props) =>
    props.active ? "#007592" : "white"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;
  border: 2px solid #009ec6 !important;

  &:hover {
    background-color: "#007592" !important;
    transform: scale(1.1);
  }
`;

const StandardContainerColumn = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 16px;
`;

const StandardContainerRow = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 8px;
`;

const InfoPrompt = styled("span")`
  width: 95%;
  height: 30px;
  padding: 8px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.success ? "rgba(0, 196, 84, 1)" : "rgb(206, 69, 69)"};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: 2,
    bottom: 10,
  },
}));

const NavOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  /* background: ${(props) => (props.active ? "#00A0C6" : "none")}; */
  font-weight: ${(props) => (props.active && !props.disabled ? "600" : "100")};
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-bottom: ${(props) =>
    props.active && !props.disabled ? "3px solid #00A0C6" : "3px solid white"};
  /* color: ${(props) => (props.active ? "white" : "black")}; */
  color: black;

  padding: 8px;
  /* border-radius: 8px; */
`;

function UserProfile({
  userAccount,
  setUserAccount,
  openProfile,
  setOpenProfile,
  subUrl,
  purchased,
  subUsers,
  setCustomerService,
}) {
  const [user, error] = useAuthState(auth);
  const [navigation, setNavigation] = useState("profile");
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const [editInfo, setEditInfo] = useState({ personal: true, company: true });
  const [yourInfo, setYourInfo] = useState({
    info: {},
    subscriptions: [],
    admin: false,
  });

  const [shareGroup, setShareGroup] = useState([]);

  const defaultTeams = [
    { label: "Whole Company", value: "whole" },
    { label: "Electrical", value: "electrical" },
    { label: "GIS", value: "gis" },
    { label: "O&M", value: "om" },
    { label: "Project Management", value: "pm" },
  ];

  const orgTypes = [
    { value: "sme", label: "SME" },
    { value: "large_enterprise", label: "Large Enterprise" },
    { value: "uni", label: "University" },
    { value: "research", label: "Research Organization" },
    { value: "gov", label: "Government Agency" },
    { value: "non_profit", label: "Non-profit Organization" },
    { value: "other", label: "Other (Please Specify)" },
  ];

  useEffect(() => {
    const tools = [];
    for (let i = 0; i < userAccount?.fileData?.owned_tools?.length; i++) {
      const tool = userAccount.fileData.owned_tools[i];

      getDoc(tool.prodID).then((toolInfo) => {
        tools.push(toolInfo.data().name);
      });
    }

    setYourInfo((yourInfo) => ({
      ...yourInfo,
      info: userAccount.fileData,
      subscriptions: tools,
    }));

    for (let i = 0; i < userAccount?.fileData?.CID?.length; i++) {
      const compnayUsers = onSnapshot(
        doc(db, "company", userAccount.fileData.CID[i]),
        (group) => {
          const users = group.data() ? group.data().users : [];
          setShareGroup([]);

          if (
            users.find((item) => item?.userRef?.id === user.uid && item.admin)
          ) {
            setYourInfo((yourInfo) => ({ ...yourInfo, admin: true }));

            users.forEach(async (companyUser) => {
              const id = companyUser.userRef.id;
              const functions = getFunctions(app, "us-central1");
              const info = httpsCallable(functions, "getUserInfo");

              info({ uid: id })
                .then((result) => {
                  // Read result of the Cloud Function.
                  setShareGroup((shareGroup) => [
                    ...shareGroup,
                    {
                      id: id,
                      ref: id,
                      email: result.data.email,
                      admin: { admin: companyUser.admin, index: i },
                      groups: { users: companyUser.teams, index: i },
                      access: { tools: result.data.owned },
                      CID: userAccount.fileData.CID[i],
                    },
                  ]);
                })
                .catch((error) => {
                  // Getting the Error details.
                  console.log("ERROR");
                });
            });
          }
        }
      );
    }
    // }
  }, [userAccount.fileData]);

  useEffect(() => {
    if (emailSent) {
      setTimeout(() => {
        setEmailSent(false);
      }, 5000);
    }
  }, [emailSent]);

  const handleRemoveAccess = (row) => {
    const confirm = window.confirm(
      "You are away remove access to one of your users, Are you sure?"
    );

    if (confirm) {
      const functions = getFunctions(app, "us-central1");
      const removeAccess = httpsCallable(functions, "removeUserAccess");

      removeAccess({ uid: row.id, CID: row.CID })
        .then((result) => {
          // console.log(result.data);
        })
        .catch((error) => {
          // Getting the Error details.
          console.log("ERROR");
        });
    }
  };

  const handleProfilePicture = () => {
    const handleImage = (e) => {
      const image = e.target.files[0];

      if (!image) return;

      const storageRef = ref(storage, `images/profile/${user.uid}`);
      const uploadTask = uploadBytesResumable(storageRef, image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // console.log(snapshot);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
            setYourInfo((yourInfo) => ({
              ...yourInfo,
              info: {
                ...yourInfo.info,
                picture: downloadURL,
              },
              original: {
                ...yourInfo.original,
                picture: downloadURL,
              },
            }));

            await updateDoc(doc(db, "userAccounts", user.uid), {
              picture: downloadURL,
            });
          });
        }
      );
    };

    const a = document.createElement("input");

    a.id = "profile-image";
    a.type = "file";
    a.accept = "image/gif, image/jpeg, image/png";
    a.onchange = (e) => handleImage(e);
    a.click();
  };

  const handleTeamAssignment = (teams, CID, admin, uid) => {
    if (admin && teams.length > 0) {
      const functions = getFunctions(app, "us-central1");
      const update = httpsCallable(functions, "updateCompany");

      update({ CID: CID, teams: teams, admin: null, uid: uid })
        .then((result) => {
          // console.log(result.data);
        })
        .catch((error) => {
          // Getting the Error details.
          console.log("ERROR");
        });
    }
  };

  const handleToolAccess = (tools) => {
    if (yourInfo.admin) {
    }
  };

  const handleAdmin = (isAdmin, makeAdmin, CID, uid) => {
    if (isAdmin) {
      const functions = getFunctions(app, "us-central1");
      const update = httpsCallable(functions, "updateCompany");

      update({ CID: CID, teams: null, admin: makeAdmin, uid: uid })
        .then((result) => {
          // console.log(result.data);
        })
        .catch((error) => {
          // Getting the Error details.
          console.log("ERROR");
        });
    }
  };

  const handleUserUpdate = (edit) => {
    if (
      JSON.stringify(userAccount.fileData) !== JSON.stringify(yourInfo.info)
    ) {
      updateDoc(doc(db, "userAccounts", user.uid), yourInfo.info).then(() => {
        setEditInfo((editInfo) => ({ ...editInfo, [edit]: true }));
      });
    } else {
      setYourInfo((yourInfo) => ({
        ...yourInfo,
        info: userAccount.fileData,
      }));
      setEditInfo((editInfo) => ({ ...editInfo, [edit]: true }));
    }
  };

  const handlePasswordChange = () => {
    sendPasswordResetEmail(auth, user.email).then(() => {
      // setForgottenPassword(false)
      setEmailSent(true);
      setLoading(false);
    });
  };

  const handleDeleteAccount = () => {
    const confirm = window.confirm(
      "You are about to permanently delete your account, Are you sure?"
    );

    if (confirm) {
      user.delete();
    }
  };

  const sharedTableColumns = [
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Company",
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: "Admin",
      cell: (row) => (
        <div>
          <input
            checked={row.admin.admin}
            type="checkbox"
            onChange={(e) => {
              handleAdmin(
                yourInfo.admin,
                e.target.checked,
                userAccount.fileData.CID[row.admin.index],
                row.id
              );
            }}
          />
          <label>Admin</label>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Company Group",
      cell: (row) => (
        <Select
          defaultValue={row.groups.users}
          isMulti
          name="colors"
          options={defaultTeams}
          className="basic-multi-select"
          classNamePrefix="select"
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          onChange={(e) =>
            handleTeamAssignment(
              e,
              userAccount.fileData.CID[row.groups.index],
              yourInfo.admin,
              row.id
            )
          }
        />
      ),
      sortable: true,
      grow: 2,
    },
    {
      name: "Tool Access",
      cell: (row) => row.access.tools,
      // <Select
      //   defaultValue={formatCreateInput(row.access.tools)}
      //   isMulti
      //   name="colors"
      //   options={formatCreateInput(yourInfo.subscriptions)}
      //   className="basic-multi-select"
      //   classNamePrefix="select"
      //   styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      //   menuPortalTarget={document.body}
      //   onChange={(e) => handleToolAccess(e)}
      // />
      sortable: true,
      grow: 2,
    },
    {
      cell: (row) => (
        <StyledIconButton
          title="Scenario Settings"
          onClick={() => handleRemoveAccess(row)}
        >
          <FontAwesomeIcon size="xs" icon={faUserMinus} />
        </StyledIconButton>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  return (
    <Modal
      open={openProfile}
      onClose={() => setOpenProfile(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className="modal-popups"
          sx={{
            inset: "1rem",
            overflow: "hidden",
          }}
        >
          <header>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <ModalClose onClick={() => setOpenProfile(false)}>
                <StyledIconButton>
                  <CloseIcon />
                </StyledIconButton>
              </ModalClose>
            </div>
          </header>

          <StandardContainerRow
            // className="sidePanel_body"
            style={{ justifyContent: "normal", height: "98%" }}
          >
            <StandardContainerColumn
              style={{
                width: "25%",
                minWidth: "25%",
                height: "100%",
                gap: "16px",
              }}
            >
              <StandardContainerColumn
                className="userAvatar"
                style={{
                  justifyContent: "center",
                  background: "rgba(211, 211, 211, 0.3294117647)",
                  borderRadius: "8px",
                  height: "100%",
                }}
              >
                <h4>Profile</h4>
                <StyledBadge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <EditIconButton onClick={handleProfilePicture}>
                      <FontAwesomeIcon size="xs" icon={EditControl} />
                    </EditIconButton>
                  }
                >
                  <Avatar
                    sx={{
                      width: "7rem",
                      height: "7rem",
                      background: "#009ec6",
                      border: "2px solid #009ec6",
                    }}
                    src={yourInfo?.info?.picture && yourInfo?.info?.picture}
                  ></Avatar>
                </StyledBadge>
                <h3
                  style={{
                    maxWidth: "80%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {yourInfo?.info?.firstname && yourInfo?.info?.surname
                    ? yourInfo?.info.firstname + " " + yourInfo?.info.surname
                    : user?.email}
                </h3>
              </StandardContainerColumn>

              <StandardContainerColumn
                className="userHelp"
                style={{
                  justifyContent: "center",
                  background: "rgba(211, 211, 211, 0.3294117647)",
                  borderRadius: "8px",
                  height: "100%",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h4 style={{ margin: "0 8px 0 0" }}>Need Help ?</h4>
                  <FontAwesomeIcon size="2xl" icon={ChatIcon} color="#009ec6" />
                </div>

                <p style={{ textAlign: "center", margin: "0" }}>
                  Have questions or concerns regarding your Vekta Cables account
                  or the platform?
                  <br />
                  <br />
                  Our Experts are here to help!
                </p>

                <SecButton
                  style={{ width: "50%" }}
                  onClick={() => setCustomerService(true)}
                >
                  Send Message
                </SecButton>
              </StandardContainerColumn>
            </StandardContainerColumn>

            <StandardContainerColumn style={{ height: "100%" }}>
              <nav
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "8px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <NavOption
                  active={navigation === "profile"}
                  onClick={() => setNavigation("profile")}
                >
                  Profile
                </NavOption>
                {yourInfo.admin && (
                  <NavOption
                    active={navigation === "subscriptions"}
                    onClick={() => setNavigation("subscriptions")}
                  >
                    Subscriptions
                  </NavOption>
                )}
              </nav>

              {navigation === "profile" && (
                <StandardContainerRow style={{ height: "100%" }}>
                  <StandardContainerColumn
                    style={{
                      height: "100%",
                      overflowY: "scroll",
                    }}
                  >
                    <StandardContainerRow>
                      <h3 style={{ margin: "0 0 8px 0" }}>Personal Details</h3>
                      <StyledIconButton
                        onClick={() =>
                          setEditInfo({ personal: false, company: true })
                        }
                      >
                        <FontAwesomeIcon size="xs" icon={EditControl} />
                      </StyledIconButton>
                    </StandardContainerRow>

                    <div className="input" style={{ width: "100%" }}>
                      <label htmlFor="projectName">Firstname</label>
                      <InputText
                        disabled={editInfo.personal}
                        value={userAccount.fileData && yourInfo?.info?.firstname}
                        style={{
                          width: "100%",
                          height: "4rem",
                          padding: "8px",
                        }}
                        onChange={(e) =>
                          setYourInfo((yourInfo) => ({
                            ...yourInfo,
                            info: {
                              ...yourInfo.info,
                              firstname: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>
                    <div className="input" style={{ width: "100%" }}>
                      <label htmlFor="projectName">Surname</label>
                      <InputText
                        disabled={editInfo.personal}
                        value={userAccount.fileData && yourInfo?.info?.lastname}
                        style={{
                          width: "100%",
                          height: "4rem",
                          padding: "8px",
                        }}
                        onChange={(e) =>
                          setYourInfo((yourInfo) => ({
                            ...yourInfo,
                            info: {
                              ...yourInfo.info,
                              lastname: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>
                    <div className="input" style={{ width: "100%" }}>
                      <label htmlFor="projectName">Birth Data</label>
                      <InputText
                        disabled={editInfo.personal}
                        value={userAccount.fileData && yourInfo?.info?.birthDate}
                        style={{
                          width: "100%",
                          height: "4rem",
                          padding: "8px",
                        }}
                        onChange={(e) =>
                          setYourInfo((yourInfo) => ({
                            ...yourInfo,
                            info: {
                              ...yourInfo.info,
                              birthDate: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>
                    <div className="input" style={{ width: "100%" }}>
                      <label htmlFor="projectName">Email</label>
                      <InputText
                        disabled={editInfo.personal}
                        value={userAccount.fileData && yourInfo?.info?.email}
                        style={{
                          width: "100%",
                          height: "4rem",
                          padding: "8px",
                        }}
                        onChange={(e) =>
                          setYourInfo((yourInfo) => ({
                            ...yourInfo,
                            info: {
                              ...yourInfo.info,
                              email: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>
                    <div className="input" style={{ width: "100%" }}>
                      <label htmlFor="projectName">Contact Number</label>
                      <InputText
                        disabled={editInfo.personal}
                        value={userAccount.fileData && yourInfo?.info?.number}
                        style={{
                          width: "100%",
                          height: "4rem",
                          padding: "8px",
                        }}
                        onChange={(e) =>
                          setYourInfo((yourInfo) => ({
                            ...yourInfo,
                            info: {
                              ...yourInfo.info,
                              number: e.target.value,
                            },
                          }))
                        }
                      />
                    </div>

                    {!editInfo.personal && (
                      <PrimeButton onClick={() => handleUserUpdate("personal")}>
                        Save Changes
                      </PrimeButton>
                    )}
                  </StandardContainerColumn>

                  <Divider orientation="vertical" flexItem />

                  <StandardContainerColumn
                    style={{ height: "100%", justifyContent: "space-between" }}
                  >
                    <StandardContainerColumn style={{ height: "100%" }}>
                      <StandardContainerRow>
                        <h3 style={{ margin: "0 0 8px 0" }}>
                          Organisation Details
                        </h3>
                        <StyledIconButton
                          onClick={() =>
                            setEditInfo({ personal: true, company: false })
                          }
                        >
                          <FontAwesomeIcon size="xs" icon={EditControl} />
                        </StyledIconButton>
                      </StandardContainerRow>

                      <div className="input" style={{ width: "100%" }}>
                        <label htmlFor="projectName">Organisation Name</label>
                        <InputText
                          disabled={editInfo.company}
                          value={
                            userAccount.fileData &&
                            yourInfo?.info?.company_univeristy
                          }
                          style={{
                            width: "100%",
                            height: "4rem",
                            padding: "8px",
                          }}
                          onChange={(e) =>
                            setYourInfo((yourInfo) => ({
                              ...yourInfo,
                              info: {
                                ...yourInfo.info,
                                company_univeristy: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                      {/* <span
                        className="p-float-label"
                        style={{ marginBottom: "8px", width: "85%" }}
                      >
                        <InputText
                          disabled={editInfo.company}
                          id="userOrgName"
                          value={
                            userAccount.fileData &&
                            yourInfo.info.company_univeristy
                          }
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            setYourInfo((yourInfo) => ({
                              ...yourInfo,
                              info: {
                                ...yourInfo.info,
                                company_univeristy: e.target.value,
                              },
                            }))
                          }
                        />
                        <label htmlFor="userOrgName">Organisation Name</label>
                      </span> */}

                      <div className="input" style={{ width: "100%" }}>
                        <label>Organisation Type</label>
                        <Select
                          isDisabled={editInfo.company}
                          defaultValue={
                            yourInfo?.info?.company_type
                              ? yourInfo?.info.company_type
                              : orgTypes[0]
                          }
                          isClearable
                          isSearchable
                          options={orgTypes}
                          onChange={(e) =>
                            setYourInfo((yourInfo) => ({
                              ...yourInfo,
                              info: {
                                ...yourInfo.info,
                                company_type: e.value,
                              },
                            }))
                          }
                        />
                      </div>

                      <div className="input" style={{ width: "100%" }}>
                        <label htmlFor="projectName">Job Title</label>
                        <InputText
                          disabled={editInfo.company}
                          value={
                            userAccount.fileData && yourInfo?.info?.job_title
                          }
                          style={{
                            width: "100%",
                            height: "4rem",
                            padding: "8px",
                          }}
                          onChange={(e) =>
                            setYourInfo((yourInfo) => ({
                              ...yourInfo,
                              info: {
                                ...yourInfo.info,
                                job_title: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                      {/* <span
                        className="p-float-label"
                        style={{ marginBottom: "8px", width: "85%" }}
                      >
                        <InputText
                          disabled={editInfo.company}
                          id="userOrgTitle"
                          style={{ width: "100%" }}
                          value={
                            userAccount.fileData && yourInfo.info?.job_title
                          }
                          onChange={(e) =>
                            setYourInfo((yourInfo) => ({
                              ...yourInfo,
                              info: {
                                ...yourInfo.info,
                                job_title: e.target.value,
                              },
                            }))
                          }
                        />
                        <label htmlFor="userOrgTitle">Job Title</label>
                      </span> */}

                      {!editInfo.company && (
                        <PrimeButton
                          style={{ width: "fit-content" }}
                          onClick={() => handleUserUpdate("company")}
                        >
                          Save Changes
                        </PrimeButton>
                      )}
                    </StandardContainerColumn>

                    <Divider flexItem />

                    {/* <StandardContainerColumn>
                      <h3 style={{ margin: "0 0 8px 0" }}>Account Actions</h3>

                      <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
                        <SecButton onClick={handlePasswordChange}>Change Password</SecButton>
                        <SecButton onClick={handleDeleteAccount}>Delete Account</SecButton>
                      </div>
                    </StandardContainerColumn> */}

                    <StandardContainerColumn style={{ height: "100%" }}>
                      <StandardContainerRow style={{ paddingLeft: "8px" }}>
                        <h3 style={{ margin: "0 0 8px 0" }}>Account Actions</h3>
                        <div></div>
                      </StandardContainerRow>

                      <div
                        style={{
                          height: "60%",
                          width: "40%",
                          display: "flex",
                          justifyContent: "space-evenly",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <SecButton
                          style={
                            emailSent
                              ? {
                                  color: "white",
                                  fontWeight: "600",
                                  background: "rgb(97, 162, 41)",
                                  width: "100%",
                                  border: "none",
                                }
                              : { width: "100%" }
                          }
                          onClick={() => {
                            setLoading(true);
                            handlePasswordChange();
                          }}
                        >
                          {!loading ? (
                            !emailSent ? (
                              "Change Password"
                            ) : (
                              "Email Sent"
                            )
                          ) : (
                            <BeatLoader color="#00A0C6" />
                          )}
                        </SecButton>
                        {/* onClick={() => window.location.href = subUrl} */}
                        <SecButton
                          style={{ width: "100%" }}
                          onClick={() => {
                            if (subUrl) window.location.href = subUrl;
                          }}
                        >
                          Manage Subscription
                        </SecButton>
                        <PrimeButton
                          style={{ width: "100%" }}
                          onClick={handleDeleteAccount}
                        >
                          Delete Account
                        </PrimeButton>
                      </div>
                    </StandardContainerColumn>
                  </StandardContainerColumn>
                </StandardContainerRow>
              )}

              {yourInfo?.admin && navigation === "subscriptions" && (
                <div style={{ display: "flex", height: "100%", width: "100%" }}>
                  <StandardContainerColumn
                    style={{ width: "20%", height: "100%" }}
                  >
                    <h3>Active Subscriptions</h3>

                    {yourInfo.subscriptions.map((tool) => {
                      return (
                        <div>
                          <h4>{tool}</h4>
                        </div>
                      );
                    })}
                    <a href={subUrl} target="_blank" rel="noreferrer">
                      Manage Subscription
                    </a>
                  </StandardContainerColumn>

                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ margin: "0 8px" }}
                  />

                  <div style={{ width: "80%", padding: "8px" }}>
                    <h3>User Management</h3>
                    <DataTable
                      columns={sharedTableColumns}
                      data={shareGroup}
                      pagination
                    />
                  </div>
                </div>
              )}
            </StandardContainerColumn>
          </StandardContainerRow>
        </Box>
      </div>
    </Modal>
  );
}

export default UserProfile;
