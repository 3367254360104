import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Plot from 'react-plotly.js';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import * as XLSX from 'xlsx';
import SearchCountryDropdown from "./SearchCountryDropdown.js";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
    FormControl,
    MenuItem,
    Select,
    IconButton,
    Button,
    TextField
} from '@mui/material';
import {
    FilterAlt as FilterAltIcon,
    AddCircleOutline as AddCircleOutlineIcon,
    RemoveCircleOutline as RemoveCircleOutlineIcon,
    HighlightOff as HighlightOffIcon,
    Search as SearchIcon,
    RunCircle as RunCircleIcon,
    Close as CloseIcon,
    Download as DownloadIcon,
    Map as MapIcon
} from '@mui/icons-material';

import SettingsIcon from '@mui/icons-material/Settings';

// import './FilterData.css'; // Import CSS file for styling
import Slider from "@mui/material/Slider";
import { PlatformData } from "../../../../imports/Context";
import layerdata from './layersPG.json';


////////////////// TESTING CONSTS 
const filterrange = 10
//. make it to nearest 0.1, 1, 10, 100, 1000
const datastep = 100
const datamin = 0
const datamax = 2000

///// ENSURE LAYOUT_MANNY IS OUTPUT FROM THE API ////
const layout_manny = {
    margin: { b: 105, l: 55, r: 70, t: 0 },
    width: 280,
    height: 240,
    xaxis: {
        tickangle: 45, // Tilted x-axis labels by 45 degrees
        // Optionally, you can set other properties for x-axis here
    },
    yaxis: { title: 'Frequency' },
    showlegend: false, // Hides legend
};
const layout_configgy = {
    displayModeBar: false
    //modeBarButtonsToRemove: ['pan2d','select2d','lasso2d','resetScale2d','zoomOut2d', 'autoScale2d','zoomIn2d', 'zoom2d']
};

////////////////////////////////////////////////////

////// STYLISED COMPONENTS ///////////////////

const StyledSettingsIcon = styled(SettingsIcon)`
  margin-left: 10px; /* Add margin to the left of the icon */
  `;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  top: -125px;
  left:0px;
  margin-left: 10px;
`;
const StyledButton = styled.button`
margin-top: 10px;
  background-color: #009ec6; /* Blue */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
  cursor: pointer;

  &:hover {
    background-color: #005f77; /* Darker Blue */
  }
`;

const Container = styled.div`
  width: 600px; /* Set the width of the container */
  height: 500px; /* Set the height of the container */
  position: fixed;
  top: 50%;
  left: 65%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 10px; /* Increase padding for larger container */
  border-radius: 20px; /* Increase border-radius for larger container */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); /* Increase box shadow for larger container */
  display: flex; /* Add display flex to align items */
  justify-content: space-between; /* Align items to the space-between */
  align-items: center; /* Align items to the center */
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InnerBox = styled.div`
  width: 415px; /* Set the width of the container */
  height: 450px; /* Set the height of the container */
  margin: 20px; /* Increase margin for larger box */
  padding: 30px; /* Increase padding for larger box */
  background-color: #f0f0f0;
  border: 2px solid #ccc; /* Increase border size for larger box */
  border-radius: 10px; /* Increase border-radius for larger box */
  font-size: 24px; /* Increase font size for larger box */
`;

const LeftButtonWrapper = styled.div`
 left:10px;
`;

const RightButtonWrapper = styled.div`
 right:10px;
`;

////// TEST META DATA //////
// const jsonData = 
// [
// {
//     "schema_name": "Power_Infrastructure",
//     "table_name": "GLOBAL_Powerplants",
//     "datatype": "",
//     "table": {
//     "n": 34936,
//     "n_var": 37,
//     "memory_size": 10341188,
//     "record_size": 296.0037783375315,
//     "n_cells_missing": 455858,
//     "n_vars_with_missing": 24,
//     "n_vars_all_missing": 0,
//     "p_cells_missing": 0.35265876134893764,
//     "types": {
//         "Numeric": 18,
//         "Text": 8,
//         "Categorical": 11
//     },
//     "n_duplicates": 0,
//     "p_duplicates": 0.0
// },
//     "description": "The Global Power Plant Database is a comprehensive, open source database of power plants around the world. It centralizes power plant data to make it easier to navigate, compare and draw insights for one’s own analysis. The database covers approximately 35,000 power plants from 167 countries and includes thermal plants (e.g. coal, gas, oil, nuclear, biomass, waste, geothermal) and renewables (e.g. hydro, wind, solar). Each power plant is geolocated and entries contain information on plant capacity, generation, ownership, and fuel type. It will be continuously updated as data becomes available."
// }
// ]


const LayerOptionsContainer = (selectedLayer) => {

    ////////////// UseStates
    const [filterbyVal, setFilterbyVal] = useState([filterrange[0], filterrange[1]]); // Initialize the state with a default value
    const [data, setData] = useState([]);
    const [layout, setLayout] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [filterObsNum, setfilterObsNum] = useState();

    const { platformData, setPlatformData } = useContext(PlatformData);
    const [jsonData, setJsonData] = useState([]); // State to hold JSON data 
    const [filterdata, setFilterData] = useState(null);
    // set opacity to the active layer opacity if not then 100
    const [dataTypeVal, setDataTypeVal] = useState(platformData.layerControl.activeLayers.length > 0 ? platformData.layerControl.activeLayers[0].type : "wms");
    const [opacityVal, setOpacityVal] = useState(
        platformData.layerControl.activeLayers[0].opacity ? platformData.layerControl.activeLayers[0].opacity : 100
    );
    const [currentBoxIndex, setCurrentBoxIndex] = useState(0);
    const [uniqueValues, setUniqueValues] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedKey, setSelectedKey] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [isOpen, setIsOpen] = useState(true);

    const [expandedPanel, setExpandedPanel] = React.useState(null);
    const [inputvectoranalysisjson, setInputvectoranalysisjson] = useState({
        "Layer": {
            "schema_name": "",
        },
        "Filter": {},
        "Plot": {
            "x": ""
        }
    });
    const [metadata, setMetadata] = useState(null);


    // TODO: Plot API is being called at teh strat becasue Plot is ther ebut it's not been fillter in yet
    // maybe Plot x is not equal to 0


    //////////////////////////////////////////// HOLDING AREA ///////////////////////////////////////////////////////////

    /* const containerRef = useRef(null);
    const modalContentRef = useRef(null);
    
    const handleClose = () => {
      setIsOpen(false); // Close the container
    };
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        // Check if the click target is within the container
        if (containerRef.current && containerRef.current.contains(event.target)) {
          // Check if the click target is within a dropdown
          if (event.target.closest('.MuiFormControl-root')) {
            return; // Don't close the container if the click occurs within a dropdown
          }
          // Click occurred inside the container but not within a dropdown
          return;
        }
    
        // Close the container if clicked outside
        handleClose();
      };
    
      if (isOpen) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
    
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isOpen]); */


    ////////////////////////////////////////////HOLDING AREA ///////////////////////////////////////////////////////////


    /////////// Use effects


    useEffect(() => {
        const fetchMetadata = async () => {
            try {
                const storage = getStorage();
                const fileRef = ref(storage, 'https://storage.cloud.google.com/vekta-digital.appspot.com/appMisc/VD-VGED_REG-004%20Reference%20List.xlsx');
                const url = await getDownloadURL(fileRef);
                const response = await fetch(url);
                const data = await response.arrayBuffer();
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[2];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(worksheet, { range: 7 });
                setMetadata(json);
            } catch (error) {
                console.error('Error fetching metadata:', error);
            }
        };

        fetchMetadata();
    }, []);


    useEffect(() => {
        console.log("Data type is:", dataTypeVal);
    }, [dataTypeVal]);


    /// TODO: Make Container modal be removed by clicking outside of modal - maybe have to pass isOpen between components?

    // get the unique data for dropdown filters from GCP
    useEffect(() => {
        const str = selectedLayer;
        const parts = String(str.selectedLayer).split(':'); // Ensure str is treated as a string using String() constructor
        const schema_name = parts[0]; // Get the second part (index 1) after splitting
        const table_name = parts[1]; // Get the second part (index 1) after splitting
        // console.log(table_name)


        // Iterate over the keys of the object
        for (const key in metadata) {
            // Check if the key matches "Layer Name" or "Schema / Workspace"
            if (key === "Layer Name" || key === "Schema / Workspace") {
                // Replace spaces with underscores in the value
                const modifiedValue = data[key].replace(/ /g, '_');
                // Update the value in the object
                data[key] = modifiedValue;
            }
        }


        console.log(metadata)

        /// TODO: ADD METADATA TO BELOW chaneg table_name to Layer Name and schema_name below to the other one

        // Find an entry in layerdata with a matching table_name
        const filteredData = layerdata.find(entry => entry.table_name === table_name);


        // console.log(filteredData)

        // Update the inputjson object
        setInputvectoranalysisjson({
            ...inputvectoranalysisjson,
            Layer: {
                ...inputvectoranalysisjson.Layer,
                schema_name: schema_name,
                table_name: table_name
            }
        });


        const fetchData = async () => {
            try {
                if (!filteredData || filteredData.length === 0) {
                    // If filteredData is null or empty, do not fetch data
                    return;
                }

                //const { schema_name, table_name } = filteredData[0];
                // Construct the URL string
                const url = `https://storage.googleapis.com/vekta_uploaded/analytics_metadata/${schema_name}-${table_name}-unique.json`;
                // const url = `https://storage.googleapis.com/vekta_uploaded/analytics_metadata/Management_Areas_Marine-GLOBAL_Internal-Waters-(MR)-unique.json`
                // console.log(url);

                // Fetch the JSON data from GCP
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }

                // Parse the JSON response
                const uniquedata = await response.json();
                // console.log(uniquedata);
                // Set the fetched JSON data

                setFilterData(uniquedata);
                const keys = Object.keys(uniquedata);
                // console.log(keys);
                setOptions(keys);


            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [selectedLayer]);


    // get the values for the CQL filter...
    useEffect(() => {
        if (inputvectoranalysisjson.Filter) {
            const newDataSearch = {};
            Object.entries(inputvectoranalysisjson.Filter).forEach(([key, value]) => {
                newDataSearch[key] = { value: value.filter_value };
            });

            setPlatformData(prevData => ({
                ...prevData,
                datasearch: newDataSearch
            }));
        }


    }, [inputvectoranalysisjson.Filter])



    // get the opacity values 
    useEffect(() => {
        // Check if layerControl and activeLayers exist
        // Iterate over activeLayers
        platformData.layerControl.activeLayers.forEach(layer => {
            // console.log(platformData.layerControl.activeLayers[0].opacity)
            // console.log(layer.id)
            // console.log(selectedLayer.selectedLayer)
            // Check if the id matches selectedLayer
            // console.log(layer.id === selectedLayer.selectedLayer)
            if (layer.id === selectedLayer.selectedLayer) {
                // Update the opacity value
                layer.opacity = opacityVal;
            }
        });
    }, [opacityVal])


    useEffect(() => {
        console.log(inputvectoranalysisjson.Filter)
        console.log(options[0])
    }, [inputvectoranalysisjson])


    useEffect(() => {
        if (inputvectoranalysisjson.Plot) {
            if (inputvectoranalysisjson.Filter && Object.keys(inputvectoranalysisjson.Filter).length === 0) {
                // Remove the Filter property from inputvectoranalysisjson
                delete inputvectoranalysisjson.Filter;
            }

            // Assume apiUrl is the endpoint URL where you want to send the inputjson
            const apiUrl = 'https://europe-west1-vekta-functions-380610.cloudfunctions.net/test_vectoranalysis';

            // Send inputjson to the API
            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(inputvectoranalysisjson)
            })
                .then(response => {
                    // Check if the request was successful (status code 2xx)
                    if (response.ok) {
                        // Parse the response JSON
                        return response.json();
                    } else {
                        // Throw an error with the response status
                        throw new Error(`Request failed with status ${response.status}`);
                    }
                })
                .then(data => {
                    setData(data.Univariate_Categorical.data);
                    setLayout(data.Univariate_Categorical.layout);
                    const sumY = data.Univariate_Categorical.data.reduce((acc, item) => {
                        return acc + item.y.reduce((subAcc, val) => subAcc + val, 0);
                    }, 0);
                    setfilterObsNum(sumY);
                    setShowModal(false);
                })
                .catch(error => {
                    // Log any errors that occurred during the request
                    console.error('Error:', error);
                });
        }
    }, [inputvectoranalysisjson.Plot]);


    /////////// handles


    const handleFilterbyInputChange = (e) => {
        setFilterbyVal(e.target.value);
    };
    /* 
        const handleDownloadClick = async () => {
            try {
                // Replace 'YOUR_GCP_BUCKET_URL/data.csv' with the actual URL of your data hosted on GCP
                const response = await fetch('https://storage.googleapis.com/vekta_uploaded/analytics_metadata/filtereddata.csv');
                const blob = await response.blob();
    
                // Create a temporary anchor element
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
    
                // Set the filename for the downloaded file
                link.setAttribute('download', 'FilteredData_VektaDigital.csv');
    
                // Append the anchor element to the body
                document.body.appendChild(link);
    
                // Trigger the download
                link.click();
    
                // Clean up
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading file:', error);
            }
        }; */

    // handle accordian when clicked, open out
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedPanel(panel === expandedPanel ? null : panel);
    };

    // move forward one box
    const replaceNextBox = () => {
        setCurrentBoxIndex(prevIndex => (prevIndex + 1) % 3);
    };

    // go back one box
    const replacePreviousBox = () => {
        setCurrentBoxIndex(prevIndex => (prevIndex - 1 + 3) % 3); // Ensure the index wraps around properly
    };

    // Function to handle selection of value from the dropdown
    const handleValueChange = (event) => {
        setSelectedValue(event.target.value);
    };

    // Function to handle selection of value from the dropdown
    const handleXChange = (event) => {
        setInputvectoranalysisjson({
            ...inputvectoranalysisjson,
            Plot: {
                x: event.target.value // Assuming the dropdown value is the desired value
            }
        });
    };

    // Function to remove filter 
    const removeFilter = (key) => {
        // Create a copy of the inputjson object
        const updatedInputjson = { ...inputvectoranalysisjson };
        // Remove the filter entry with the given key
        delete updatedInputjson.Filter[key];
        // Update the state with the modified inputjson object
        setInputvectoranalysisjson(updatedInputjson);

        const newDataSearch = {};
        Object.entries(inputvectoranalysisjson.Filter).forEach(([key, value]) => {
            newDataSearch[key] = { value: value.filter_value };
        });

        // console.log(newDataSearch)
        setPlatformData(prevData => ({
            ...prevData,
            datasearch: newDataSearch
        }));
    };

    // Function to add filter
    const handleAddFilterClick = () => {
        setInputvectoranalysisjson({
            ...inputvectoranalysisjson,
            "Filter": {
                ...inputvectoranalysisjson.Filter,
                [selectedKey]: {
                    "filter_value": [selectedValue]
                }
            }
        });
    };


    // Populate the values dropdown.. 
    const handleKeyChange = (event) => {

        /// TODO: if null then stop red screenhere.. 
        setSelectedKey(event.target.value);
        setSelectedValue(''); // Reset selected value when key changes

        if (filterdata[event.target.value]) {
            setUniqueValues(filterdata[event.target.value].unique_values);
        }

    };


    // Set the opacity based on the slider
    const handleOpacityInputChange = (e) => {
        const newOpacity = e.target.value; // 
        setOpacityVal(newOpacity);
    };

    const scrollToContent = (index) => {
        setCurrentBoxIndex(index);
    };

    ///// Get the content for each panel (i.e. content for jsx)
    const getContentWMS = index => {
        switch (index) {
            case 0:
                return (
                    <div>
                        {/* <h4 style={{ paddingBottom: "10px" }}>Map Display Options</h4> */}
                        <div className="LayerOptionsInsideBoxStyle">
                            <label style={{ fontWeight: 100, fontSize: "14px" }}> Opacity (%)</label>
                            <Slider
                                value={opacityVal}
                                onChange={handleOpacityInputChange}
                                step={1}
                                valueLabelDisplay="auto"
                                className="custom-slider"
                                style={{ marginTop: "5px", bottom: "8px", marginLeft: "60px", width: "120px", color: '#007592' }}
                            />
                            {/* <input
                     className="InputStyle"
                     type="number"
                     onChange={handleOpacityInputChange}
                     value={opacityVal}
                   /> */}
                        </div>
                        <Accordion expanded={expandedPanel === 'panel4'} onChange={handleAccordionChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Add Filters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>
                                    <FormControl>
                                        <Select style={{ width: '135px', height: '30px' }} onChange={handleKeyChange}>
                                            <MenuItem value="" style={{ width: '140px', height: '30px' }}>Select Variable</MenuItem>
                                            {options.map((option, index) => (
                                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {selectedKey && (
                                        <FormControl>
                                            <Select style={{ width: '135px', height: '30px', marginLeft: "5px", }} onChange={handleValueChange}>
                                                <MenuItem value="">Select Value</MenuItem>
                                                {uniqueValues.map((value, index) => (
                                                    <MenuItem key={index} value={value}>{value}</MenuItem>
                                                ))}
                                                {uniqueValues.length === 0 && <MenuItem value="default">No Values</MenuItem>}
                                            </Select>
                                        </FormControl>
                                    )}
                                    <IconButton className="plus-button" onClick={handleAddFilterClick} style={{ marginLeft: "5px", marginTop: "5px" }}><AddCircleOutlineIcon /></IconButton>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expandedPanel === 'panel5'} onChange={handleAccordionChange('panel5')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Delete Filters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>

                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif', paddingRight: "50px" }}>Name</th>
                                                <th style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif', paddingRight: "50px" }}>Value</th>
                                                <th style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif', paddingRight: "50px" }}>Delete</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inputvectoranalysisjson.Filter && Object.entries(inputvectoranalysisjson.Filter).map(([key, value]) => (
                                                <tr key={key}>
                                                    <td>{key}</td>
                                                    <td>{value.filter_value}</td>
                                                    <td><IconButton onClick={() => removeFilter(key)}><RemoveCircleOutlineIcon /></IconButton></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>




                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                );
            case 1:
                return (
                    <div>
                        {/* <h4 style={{ paddingBottom: "10px" }}>Graph Display Options</h4> */}
                        <Accordion expanded={expandedPanel === 'panel6'} onChange={handleAccordionChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Drill down (one variable)</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>

                                    <div className="dropdowns">
                                        <FormControl>
                                            <Select
                                                style={{ width: '250px', height: '30px' }}
                                                onChange={handleXChange}>
                                                <MenuItem value="">Select Display Variable</MenuItem>
                                                {options.map((option, index) => (
                                                    <MenuItem key={index} value={option}>{option}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </div>
                                    {/* <Button onClick={handleRunClick} style={{ height: "30px", marginLeft: '5px', marginTop: '20px', backgroundColor: "#009ec6" }} variant="contained" endIcon={<RunCircleIcon />}>Run
                    </Button> */}
                                    <div className="inner-container">
                                        {(data.length > 0 && Object.keys(layout).length > 0) && (
                                            <div>
                                                <Plot
                                                    data={data}
                                                    layout={layout_manny}
                                                    config={layout_configgy}
                                                />
                                                <Button style={{ height: "30px", marginLeft: '10px', position: "relative", bottom: '50px', left: '-25px', backgroundColor: "#009ec6" }} variant="contained" endIcon={<AddCircleOutlineIcon />}>Intelligence
                                                </Button>

                                            </div>
                                        )}
                                        {/*
            <Box className="filterNumber">
              <p>n={filterObsNum}</p>
            </Box>
            */}
                                    </div>

                                </Typography>

                            </AccordionDetails>
                        </Accordion>


                    </div>
                );
            case 2:
                return (
                    <div>
                        {/* <h4 style={{ paddingBottom: "10px" }}>Meta Data</h4> */}
                        <Accordion expanded={expandedPanel === 'panel1'} onChange={handleAccordionChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Description</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}></Typography>
                                <div className="scrollable-container">
                                    {jsonData.length > 0 && (
                                        <div className="item">
                                            <p> {jsonData[0].description}</p>
                                        </div>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedPanel === 'panel2'} onChange={handleAccordionChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Data Summary</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}></Typography>

                                <div className="scrollable-container">

                                    {jsonData.length > 0 && (
                                        <div className="item">
                                            {jsonData[0].table && jsonData[0].table.n !== undefined && (
                                                <TableContainer component={Paper}>
                                                    <Table >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}><strong>Attribute</strong></TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}><strong>Value</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Observations</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.n}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.n_var}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Numeric Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Numeric}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Categorical Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Categorical}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Text Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Text}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Boolean Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Boolean}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Missing</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{(jsonData[0].table.p_cells_missing * 100).toFixed(2)}%</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedPanel === 'panel3'} onChange={handleAccordionChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Source</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}></Typography>

                                <div className="scrollable-container">
                                    <div className="item">
                                        {jsonData.length > 0 && (
                                            <p>
                                                <a href={jsonData[0].link} target="_blank" rel="noopener noreferrer" >
                                                    {jsonData[0].source}
                                                </a>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                );
            default:
                return null;
        }
    };

    const getContentCOG = index => {
        switch (index) {
            case 0:
                return (
                    <div>
                        {/* <h4 style={{ paddingBottom: "10px" }}>Map Display Options</h4> */}
                        <div className="LayerOptionsInsideBoxStyle">
                            <label style={{ fontWeight: 100, fontSize: "14px" }}> Opacity (%)</label>
                            <Slider
                                value={opacityVal}
                                onChange={handleOpacityInputChange}
                                step={1}
                                valueLabelDisplay="auto"
                                className="custom-slider"
                                style={{ marginTop: "5px", bottom: "8px", marginLeft: "60px", width: "120px", color: '#007592' }}
                            />
                            {/* <input
                     className="InputStyle"
                     type="number"
                     onChange={handleOpacityInputChange}
                     value={opacityVal}
                   /> */}
                        </div>
                        <Accordion expanded={expandedPanel === 'panel4'} onChange={handleAccordionChange('panel4')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Add Filters</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>

                                    <div>

                                        <div className="LayerOptionsInsideBoxStyle">
                                            <label style={{ fontWeight: 100, fontSize: "14px" }}> Value</label>
                                            <Slider
                                                value={filterbyVal}
                                                onChange={handleFilterbyInputChange}
                                                min={datamin}
                                                max={datamax}
                                                step={datastep}
                                                valueLabelDisplay="auto"
                                                className="custom-slider"
                                                style={{ marginTop: "5px", bottom: "8px", marginLeft: "60px", width: "120px", color: '#007592' }}
                                            />
                                        </div>
                                        <div className="LayerOptionsInsideBoxStyle">
                                            <label style={{ fontWeight: 100, fontSize: "14px" }}> Geography</label>
                                            <SearchCountryDropdown />
                                        </div>
                                    </div>

                                </Typography>
                            </AccordionDetails>
                        </Accordion>


                    </div>
                );
            case 1:
                return (
                    <div>
                        {/* <h4 style={{ paddingBottom: "10px" }}>Graph Display Options</h4> */}
                        <Accordion expanded={expandedPanel === 'panel6'} onChange={handleAccordionChange('panel6')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Add Histogram</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>

                                    <div className="inner-container">
                                        <h6>Coming soon...</h6>
                                    </div>

                                </Typography>

                            </AccordionDetails>
                        </Accordion>


                    </div>
                );
            case 2:
                return (
                    <div>
                        {/* <h4 style={{ paddingBottom: "10px" }}>Meta Data</h4> */}
                        <Accordion expanded={expandedPanel === 'panel1'} onChange={handleAccordionChange('panel1')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Description</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}></Typography>
                                <div className="scrollable-container">
                                    {jsonData.length > 0 && (
                                        <div className="item">
                                            <p> {jsonData[0].description}</p>
                                        </div>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedPanel === 'panel2'} onChange={handleAccordionChange('panel2')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Data Summary</Typography>
                            </AccordionSummary>
                            <AccordionDetails>

                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}></Typography>

                                <div className="scrollable-container">

                                    {jsonData.length > 0 && (
                                        <div className="item">
                                            {jsonData[0].table && jsonData[0].table.n !== undefined && (
                                                <TableContainer component={Paper}>
                                                    <Table >
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}><strong>Attribute</strong></TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}><strong>Value</strong></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Observations</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.n}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.n_var}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Numeric Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Numeric}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Categorical Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Categorical}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Text Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Text}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Boolean Variables</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{jsonData[0].table.types.Boolean}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>Missing</TableCell>
                                                                <TableCell style={{ fontSize: "12px", fontFamily: 'Montserrat, sans-serif' }}>{(jsonData[0].table.p_cells_missing * 100).toFixed(2)}%</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expandedPanel === 'panel3'} onChange={handleAccordionChange('panel3')}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}>Source</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Typography style={{ fontSize: "14px", fontFamily: 'Montserrat, sans-serif' }}></Typography>

                                <div className="scrollable-container">
                                    <div className="item">
                                        {jsonData.length > 0 && (
                                            <p>
                                                <a href={jsonData[0].link} target="_blank" rel="noopener noreferrer" >
                                                    {jsonData[0].source}
                                                </a>
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                );
            default:
                return null;
        }
    };

    /////////// JSX

    return (
        <>
            {isOpen && (
                <Container isOpen={isOpen}>
                    {/* <LeftButtonWrapper>
                        <IconButton onClick={replacePreviousBox}>
                            <ArrowBackIcon />
                        </IconButton>
                    </LeftButtonWrapper> */}
                    <ButtonContainer>
                        <h4>Layer Options</h4>
                        <StyledButton onClick={() => scrollToContent(0)}>
                            Filter
                            <StyledSettingsIcon sx={{ fontSize: 20 }} /> {/* Add SettingsIcon inside the button */}
                        </StyledButton>
                        <StyledButton onClick={() => scrollToContent(1)}>
                            Plot
                            <StyledSettingsIcon sx={{ fontSize: 20 }} /> {/* Add SettingsIcon inside the button */}
                        </StyledButton>
                        <StyledButton onClick={() => scrollToContent(2)}>
                            Metadata
                        </StyledButton>
                    </ButtonContainer>
                    <Box>
                        <InnerBox>
                            {dataTypeVal === "wms" ? getContentWMS(currentBoxIndex) : getContentCOG(currentBoxIndex)}
                        </InnerBox>
                    </Box>
                    {/* <RightButtonWrapper>
                        <IconButton onClick={replaceNextBox}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </RightButtonWrapper> */}

                    {/* <FileDownloader /> */}
                </Container>
            )}
        </>
    );
};

export default LayerOptionsContainer;
