import React, { useState } from 'react';
import styled from 'styled-components';
import copy from 'clipboard-copy';


const Container = styled.div`
  text-align: left;
  padding-left: 20px; /* Adjust padding as needed */
  padding-bottom: 20px; /* Adjust padding as needed */
  width:100%;
  //margin-top: 10px; /* Add margin at the top for space */
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
`;

const Th = styled.th`
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left; /* Center the column headings */
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

const CopyButton = styled.button`
  cursor: pointer;
  width:100%;
  padding: 8px;
  background-color: ${({ clicked }) => (clicked ? '#007592' : '#007592')};
  color: white;
  border: none;
  border-radius: 4px;
`;

function ElevationStats({ Medelevation, Minelevation, Maxelevation }) {
  const [copiedStates, setCopiedStates] = useState([false, false, false]);

  const handleCopy = (value, index) => {
    copy(value);

    // Create a copy of the array
    const updatedStates = [...copiedStates];

    // Update the state for the clicked button
    updatedStates[index] = true;

    // Update the state
    setCopiedStates(updatedStates);

    // Reset the state after a delay
    setTimeout(() => {
      updatedStates[index] = false;
      setCopiedStates([...updatedStates]); // Ensure we create a new array to trigger a re-render
    }, 500); // Auto-hide after 1 second
  };

  return (
    <Container>
      <Table>
        <tbody>
          <tr>
            <Th>Statistic</Th>
            <Th>Elevation</Th>
            <Th></Th>
          </tr>
          <tr>
            <Td>Median</Td>
            <Td><CopyButton onClick={() => handleCopy(Medelevation, 0)} clicked={copiedStates[0]}>
                {copiedStates[0] ? 'Copied' : `${Medelevation} m`} 
              </CopyButton></Td>
          </tr>
          <tr>
            <Td>Minimum</Td>
            <Td>
              <CopyButton onClick={() => handleCopy(Minelevation, 1)} clicked={copiedStates[1]}>
                {copiedStates[1] ? 'Copied' : `${Minelevation} m`}
              </CopyButton>
            </Td>
          </tr>
          <tr>
            <Td>Maximum</Td>
            <Td>
              <CopyButton onClick={() => handleCopy(Maxelevation, 2)} clicked={copiedStates[2]}>
                {copiedStates[2] ? 'Copied' : `${Maxelevation} m`}
              </CopyButton>
            </Td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default ElevationStats;
