import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { app, auth, db } from "../../../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { v4 as uuid } from "uuid";

export const handleSave = async (
  user,
  saveSettings,
  platformData,
  dateTime,
  userSaves,
  location
) => {
  try {
    if (!user.isAnonymous) {
      // Setting up save structure for scenario and project.
      // Change structure here to modify saves.
      const scn_data = {
        save: JSON.stringify(platformData),
        createdBy: user.email,
        lastSaved: dateTime,
        tags: saveSettings.scenarioTags,
        base: saveSettings.baseScenario,
        hidden: false,
        locked: saveSettings.baseScenario, // Automatically lock the base scenario so its read only for shared people
      };

      let proj_data = {
        lastSaved: dateTime,
        sharedStatus: {
          shared: true,
          users: {},
        },
        createdBy: user.email,
        projID: uuid(),
        tags: saveSettings.projectTags,
        description: null,
        stage: null,
        discipline: null,
        lifetime: null,
        status: {
          schedule: "green",
          budget: "green",
          risks: "green",
        },
        cost: {
          capex: null,
          opex: null,
          substation: null,
          discount: null,
          availability: null,
        },
        key_dates: {
          start: { value: null, label: "Project Start" },
          planning: { value: null, label: "Planning Determination" },
          fid: { value: null, label: "Final Investment Decision (FID)" },
          grid: { value: null, label: "Grid Connection" },
          cod: {
            value: null,
            label: "Commercial Operational Date (COD)",
          },
        },
        budget: [
          {
            actual: 0,
            mtp: 0,
            variance: 0,
            forecast: 0,
            mtp_full: 0,
            variance_full: 0,
          },
        ],
      };

      const saveCheck = checkPermissionStatus(
        user.uid,
        saveSettings.projectName,
        userSaves
      );

      if (saveCheck.permission && saveCheck.shared) {
        const existing = (
          await getDoc(userSaves.shared[saveSettings.projectName.value].proj)
        ).data();
        let shared = null;
        if (existing) {
          shared = existing.sharedStatus;

          if (
            shared.users[user.uid].scenarios.indexOf(
              saveSettings.scenarioName.value
            ) === -1
          ) {
            shared.users[user.uid].scenarios.push(
              saveSettings.scenarioName.value
            );
            await updateDoc(
              userSaves.shared[saveSettings.projectName.value].proj,
              {
                sharedStatus: shared,
              }
            );
          }
        }

        await setDoc(
          doc(
            userSaves.shared[saveSettings.projectName.value].proj,
            "scenarios",
            saveSettings.scenarioName.value
          ),
          scn_data
        );
      } else {
        let savePersonal = false;

        if (saveCheck.shared && !saveCheck.permission)
          savePersonal = window.confirm(
            "You do not have the permissions to save to this shared project, Do you wish to save it to your personal projects?"
          );

        if (savePersonal || !saveCheck.shared) {
          const scnRef = doc(
            db,
            "userSaves",
            user.uid,
            location,
            saveSettings.projectName.value,
            "scenarios",
            saveSettings.scenarioName.value
          );

          const projRef = doc(
            db,
            "userSaves",
            user.uid,
            location,
            saveSettings.projectName.value
          );

          const existing = (await getDoc(projRef)).data();

          if (existing) {
            proj_data.sharedStatus = existing.sharedStatus;
            proj_data.projID = existing.projID;
          }

          await setDoc(projRef, proj_data);
          await setDoc(scnRef, scn_data);
        } else {
          return {
            shown: false,
            success: true,
            message: "Project Successfully Saved",
          };
        }
      }

      return {
        shown: true,
        success: true,
        message: "Project Successfully Saved",
      };
    }
  } catch (error) {
    console.log(error);
    return {
      shown: true,
      success: false,
      message: "Project Save Failed - Try again",
    };
  }
};

/**
 *
 * Checking if the user is trying to save to a shared project. If so their permissions to that project are checked.
 * If editor status is true else false.
 *
 * @param {*} uid --> User attempting the save
 * @param {*} projName --> Name of save project
 * @param {*} saves --> Object of users saved projects {personal: {}, shared: {}}
 * @returns
 */
const checkPermissionStatus = (uid, projName, saves) => {
  let shareEditor = { permission: false, shared: false };

  Object.keys(saves.shared).map((key, i) => {
    if (key === projName.value) {
      const check = saves.shared[key].sharedStatus.users;

      Object.keys(check).map((key) => {
        if (uid === key && check[key].permissions === "editor") {
          shareEditor = { permission: true, shared: true };
        } else if (uid === key) {
          shareEditor = { permission: false, shared: true };
        }
      });
    }
  });

  return shareEditor;
};

export const formatCreateInput = (names) => {
  const formatted = [];
  for (let i = 0; i < names?.length; i++) {
    formatted.push({ value: names[i], label: names[i] });
  }

  return formatted;
};

export const handleLoad = async (user, ref, scenarioName, setPlatformData) => {
  try {
    if (!user.isAnonymous) {

      const load = await getDoc(doc(ref, "scenarios", scenarioName));

      setPlatformData(JSON.parse(load.data().save));

      const recent = (await getDoc(doc(db, "userSaves", user.uid))).data()
        .recent;

      if (recent && recent.length >= 5) recent.shift();
      recent.push(ref.id + "_" + scenarioName);

      await updateDoc(doc(db, "userSaves", user.uid), {
        recent: recent,
      });
      return { success: true, message: "Project Successfully Loaded" };
    }
  } catch (error) {
    console.log(error);
    return { success: false, message: "Project Load Failed - Try again" };
  }
};

export const handleShare = async (
  user,
  shareSettings,
  proj,
  externals,
  shareScenarios
) => {
  try {
    if (!user.isAnonymous) {
      const user = auth.currentUser;
      const functions = getFunctions(app, "us-central1");

      const projRef = doc(db, "userSaves", user.uid, "personal", proj.name);
      const existingShare = (await getDoc(projRef)).data().sharedStatus.users;

      const newUsers = { ...shareSettings.users };

      Object.keys(newUsers).map((user) => {
        newUsers[user].scenarios = [
          ...newUsers[user].scenarios,
          ...shareScenarios,
        ];
      });

      await updateDoc(projRef, {
        "sharedStatus.shared": false,
        "sharedStatus.users": { ...existingShare, ...newUsers },
      });

      const functionRef = httpsCallable(functions, "sharePeer2Peer");
      functionRef({
        users: shareSettings.users,
        projID: proj.id,
        shareType: shareSettings.shareType,
        projName: proj.name,
      }).then((result) => {
        // console.log(result.data);
      });

      if (externals.length > 0) {
        const sendEmail = httpsCallable(functions, "shareExternal");
        sendEmail({
          externals: externals,
          projID: proj.id,
          projName: proj.name,
          scenarios: shareScenarios,
          from: user.email,
        })
          .then((result) => {
            // Read result of the Cloud Function.
            // console.log(result.data);
          })
          .catch((error) => {
            // Getting the Error details.
            console.log(error.code);
          });
      }
    }

    return { success: true, message: "Project Successfully Shared" };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Failed to Share Project - Please try again",
    };
  }
};
