import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PrimeButton } from "../../styles/styles";
import styled from "styled-components";
import * as XLSX from "xlsx";

const Container = styled.div`
  text-align: left;
  width: 100%;
  margin-top: 16px;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;
`;

const Th = styled.th`
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left; /* Center the column headings */
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

function SiteInfo({ siteInfo }) {
  const [totalLength, setTotalLength] = useState(0);

  useEffect(() => {

    for (let i = 0; i < siteInfo.siteCables.length; i++) {
      const element = siteInfo.siteCables[i];

      setTotalLength((totalLength) => totalLength + element.length);
    }
  }, [siteInfo]);

  const handleDownload = () => {
    try {
      const wb = XLSX.utils.book_new();

      let combinedCoords = [...siteInfo.siteTurbs, ...siteInfo.siteOSP];
      
      // Add 'coords' sheet
      const wsCoords = XLSX.utils.json_to_sheet(combinedCoords);
      XLSX.utils.book_append_sheet(wb, wsCoords, "Turbines");

      // Add 'cables' sheet
      // const wsCables = XLSX.utils.json_to_sheet(site.cables);
      for (let i = 0; i < siteInfo.siteCables.length; i++) {
        siteInfo.siteCables[i].path = String(siteInfo.siteCables[i].path);
        siteInfo.siteCables[i].geo = String(
          siteInfo.siteCables[i].geo.map(
            (coord) => `[${coord.lng}, ${coord.lat}]`
          )
        );
      }

      let wsCables = XLSX.utils.json_to_sheet(siteInfo.siteCables);
      XLSX.utils.book_append_sheet(wb, wsCables, "Cables");

      XLSX.writeFile(wb, siteInfo.siteSettings.siteName + "_siteInfo.xlsx");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <header>
        <p style={{ margin: "0px" }}>Site Name: </p>
        <h4 style={{ margin: "8px", fontWeight: "600" }}>
          {siteInfo.siteSettings.siteName}
        </h4>
      </header>
      <Divider sx={{ bgcolor: "black" }} />

      <Container>
        <Table>
          <tbody>
            <tr>
              <Th>Site information</Th>
              <Th></Th>
            </tr>
            <tr>
              <Td>Number of Turbines</Td>
              <Td>{siteInfo.siteTurbs.length}</Td>
            </tr>
            <tr>
              <Td>Turbine Spacing</Td>
              <Td>
                {siteInfo.siteSettings.minDist
                  ? siteInfo.siteSettings.minDist + "m"
                  : "Unknown "}
              </Td>
            </tr>
            <tr>
              <Td>Total Cable Length</Td>
              <Td>{totalLength.toFixed(2)} km</Td>
            </tr>
          </tbody>
        </Table>
      </Container>

      <footer>
        <PrimeButton style={{ width: "100%" }} onClick={handleDownload}>
          Download Site Info
        </PrimeButton>
      </footer>
    </div>
  );
}

export default SiteInfo;
