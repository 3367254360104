import { Box, Divider, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  PrimeButton,
  StandardContainerRow,
  StyledIconButton,
} from "../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { formatCreateInput } from "../helperFunctions";
import { arrayRemove, doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { faClose, faUserMinus } from "@fortawesome/free-solid-svg-icons";

function ShareSettings({ shareSettingsPop, setShareSettingsPop, sharedWith }) {
  const [user, loading, error] = useAuthState(auth);
  const permissionOptions = [
    { value: "viewer", label: "Viewer" },
    { value: "editor", label: "Editor" },
  ];
  const [saveChanges, setSaveChanges] = useState({
    data: [],
    updateNeeded: false,
    projName: null,
  });
  const sharedWithTableColumns = [
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Permissions",
      cell: (row) => (
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              textTransform: "capitalize",
            }),
          }}
          value={formatCreateInput([row.permissions.permissions])[0]}
          options={permissionOptions}
          onChange={(e) => handlePermissionChange(e, row)}
          menuPortalTarget={document.getElementById("share_settings")}
          menuPosition="fixed"
        />
      ),
      sortable: true,
    },
    {
      name: "Scenarios",
      cell: (row) => (
        <Select
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              textTransform: "capitalize",
            }),
          }}
          isMulti
          value={formatCreateInput(row.permissions.scenarios)}
          options={row.scenarios ? formatCreateInput(row.scenarios.ids): []}
          onChange={(e) => handleScenarioChange(e, row)}
          menuPortalTarget={document.getElementById("share_settings")}
          menuPosition="fixed"
        />
      ),
      sortable: true,
    },
    {
      cell: (row) => (
        <StyledIconButton
          title="Remove Access"
          onClick={() => handleShareRemove(row)}
        >
          <FontAwesomeIcon size="xs" color="#FF6961" icon={faUserMinus} />
        </StyledIconButton>
      ),
      allowOverflow: true,
      button: true,
      width: "100px",
    },
  ];

  const handlePermissionChange = (e, row) => {
    setSaveChanges((saveChanges) => ({
      ...saveChanges,
      updateNeeded: true,
      data: saveChanges.data.map((user) => {
        if (user.id === row.id)
          user.permissions = {
            scenarios: user.permissions.scenarios,
            permissions: e.value,
          };

        return user;
      }),
    }));
  };

  const handleShareRemove = (remove) => {
    setSaveChanges((saveChanges) => ({
      ...saveChanges,
      updateNeeded: true,
      data: saveChanges.data.filter((user) => user.id !== remove.id),
    }));
  };

  const handleScenarioChange = (e, row) => {
    setSaveChanges((saveChanges) => ({
      ...saveChanges,
      updateNeeded: true,
      data: saveChanges.data.map((user) => {
        if (user.id === row.id)
          user.permissions = {
            scenarios: e.map(scenario => scenario.value),
            permissions: user.permissions.permissions,
          };

        return user;
      }),
    }));
  };

  const handleUpdate = async () => {
    setShareSettingsPop(false);
    const temp = {};
    const projRef = doc(
      db,
      "userSaves",
      user.uid,
      "personal",
      saveChanges.projName
    );

    saveChanges.data.map((user) => {
      temp[user.id] = user.permissions;
    });

    await updateDoc(projRef, {
      "sharedStatus.users": temp,
    });
  };

  useEffect(() => {
    setSaveChanges({
      data: sharedWith.selected,
      updateNeeded: false,
      projName:
        sharedWith.selected.length > 0 ? sharedWith.selected[0].projName : "",
    });
  }, [sharedWith]);

  return (
    <Modal
      open={shareSettingsPop}
      onClose={() => setShareSettingsPop(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        id="share_settings"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: 2,
            position: "fixed",
            inset: "15%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <header>
            <StandardContainerRow style={{ overflow: "hidden" }}>
              <h2>Share Settings</h2>
              <StyledIconButton onClick={() => setShareSettingsPop(false)}>
                <FontAwesomeIcon icon={faClose} />
              </StyledIconButton>
            </StandardContainerRow>
            <Divider
              style={{ marginBottom: "16px" }}
              sx={{ bgcolor: "black" }}
            />
          </header>

          <div style={{ width: "100%" }} className="popup_body">
            <DataTable
              columns={sharedWithTableColumns}
              data={saveChanges.data}
              // data={[]}
              responsive
              pagination
            />
          </div>

          <footer style={{ width: "100%", float: "right" }}>
            {saveChanges.updateNeeded && (
              <PrimeButton onClick={handleUpdate}>Save and Close</PrimeButton>
            )}
          </footer>
        </Box>
      </div>
    </Modal>
  );
}

export default ShareSettings;
