/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useEffect, useRef, useState } from 'react';
import Plot from 'react-plotly.js';
import Plotly from "plotly.js-basic-dist-min";

function SingleLinePlot({ data, slope }) {
  const plotRef = useRef();
  const xValues = data.map(x => x.dist);
  const yElevationValues = data.map(d => d.Elevation);
  const yslope = data.map(s => s.slope);
  const [lineElevation, setLineElevation] = useState();

  var layout = {
    //width: window.innerWidth * plotSizeX,
    title: 'Profile',
    height: "500px",
    autosize: true,
    font: { size: 13 },
    xaxis: {
      title: 'Distance (m)',
      showgrid: false,
      zeroline: false,
      autorange: true
    },
    yaxis: {
      title: 'Elevation (m)',
      showgrid: true,
      gridcolor: '#e1e5ed',
      zeroline: false,
      autorange: true,
      overlaying: "y2"
    },
    yaxis2: {
      title: "Slope (degrees)",
      side: "right"
    },
    plot_bgcolor: "rgba(0,0,0,0)",
    paper_bgcolor: "rgba(0,0,0,0)",
    margin: {
      l: 50,
      r: 30,
      b: 50,
      t: 50
    }
  }

  const config = {
    responsive: true,
    toImageButtonOptions: {
      format: 'png', // one of png, svg, jpeg, webp
      filename: 'ElevationProfile',
      height: 500,
      width: 700,
      scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
    }
  };

  useEffect(() => {
    if (plotRef.current) {
      Plotly.Plots.resize(plotRef.current.el)
    }
  }, [])

  useEffect(() => {
    if (slope) {
      setLineElevation([{
        x: xValues,
        y: yElevationValues,
        type: 'scatter',
        mode: 'lines',
        line: {
          color: '#1f77b4',
          width: 2
        },
        name: 'Elevation',
      },
      {
        x: xValues,
        y: yslope,
        type: 'scatter',
        mode: 'lines',
        yaxis: "y2",
        line: {
          color: '#FF0000',
          width: 2
        },
        name: 'Slope',
      }])

      return
    } else {
      setLineElevation([{
        x: xValues,
        y: yElevationValues,
        type: 'scatter',
        mode: 'lines',
        line: {
          color: '#1f77b4',
          width: 2
        },
        name: 'Elevation',
      }])
    }
  }, [slope, data])

  return (
    <Plot
      ref={plotRef}
      useResizeHandler
      style={{ width: "100%", height: "500px" }}
      data={lineElevation}
      layout={layout}
      config={config}
    />
  );
}

export default SingleLinePlot;
