/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useEffect, useState } from 'react'
import Plot from 'react-plotly.js';

function CustomSingleLine({ plotData, options, month }) {
    const [customPlot, setCustomPlot] = useState();

    const colours = [
        "#A8E1FF", "#93D1FE", "#7EC1FD", "#69B2FC", "#54A2FC", "#3F92FB", 
"#2A83FA", "#1573F9", "#0064F9"
      ];
    const layout = {
        font: { size: 13, family: "Montserrat" },
        height: 550,
        width: 600,
        legend: {
            orientation: "h", 
            font: { size: 13, family: "Montserrat" },
            title: { text: "Probability (%)" },
            xanchor: "center",
            x: 0.5,
            y: -0.3
            // x: 0.15, // Center horizontally (0 is left, 1 is right)
            // y: -0.2, // Center vertically (0 is bottom, 1 is top)
        },
        margin: { t: 75 },
        title: "Accessibility",
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        xaxis: { autorange: true, tickvals: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11], title: "Month", ticktext: month, tickmode: "array" },
        yaxis: { autorange: true, title: "Percentage of Month (%)"}
    }

    const config = {
        toImageButtonOptions: {
            format: 'png', // one of png, svg, jpeg, webp
            filename: 'CustomPersistenceProbability',
            height: 500,
            width: 700,
            scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        }
    };

    useEffect(() => {
        var dataFormatted = []
        for (let i = 0; i < plotData.length; i++) {
            if (String(plotData[i].window) === options.window.label &&
                String(plotData[i].wind) === options.wind.label &&
                String(plotData[i].wave) === options.wave.label &&
                String(plotData[i].tp) === options.tp.label) {
                for (let j = 0; j < plotData[i].fullData.length; j++) {
                    dataFormatted.push({
                        x: month,
                        y: plotData[i].fullData[j],
                        name: (j + 1) * 10 + "%",
                        type: "scatter",
                        marker: {
                            color: colours[j % colours.length],
                        },
                    })
                }
            }
        }
        setCustomPlot(dataFormatted)
    }, [options])

    return (
        <Plot data={customPlot} layout={layout} config={config} />
    )
}

export default CustomSingleLine