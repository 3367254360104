import React, { useState } from 'react';
import styled from 'styled-components';
import copy from 'clipboard-copy';


const Container = styled.div`
  text-align: center; /* Center the content horizontally */
  padding-left: 20px; /* Adjust padding as needed */
  padding-bottom: 20px; /* Adjust padding as needed */
  width:100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;
`;

const Th = styled.th`
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left; /* Center the column headings */
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

const CopyButton = styled.button`
  cursor: pointer;
  width:100%;
  padding: 8px;
  background-color: ${({ clicked }) => (clicked ? '#007592' : '#007592')};
  color: white;
  border: none;
  border-radius: 4px;
`;

function YieldStats({ TotalGross, TotalNet, WakeEffect, AvgCF }) {
  const [copiedStates, setCopiedStates] = useState([false, false, false, false]);

  const handleCopy = (value, index) => {
    copy(value);

    // Create a copy of the array
    const updatedStates = [...copiedStates];

    // Update the state for the clicked button
    updatedStates[index] = true;

    // Update the state
    setCopiedStates(updatedStates);

    // Reset the state after a delay
    setTimeout(() => {
      updatedStates[index] = false;
      setCopiedStates([...updatedStates]); // Ensure we create a new array to trigger a re-render
    }, 500); // Auto-hide after 1 second
  };

  return (
    <Container >
      <Table>
        <tbody>
          <tr>
            <Td>Total Gross AEP</Td>
            <Td><CopyButton onClick={() => handleCopy(TotalGross, 0)} clicked={copiedStates[0]}>
                {copiedStates[0] ? 'Copied' : `${TotalGross} GWh`}
              </CopyButton></Td>
          </tr>
          <tr>
            <Td>Total Net AEP</Td>
            <Td>
              <CopyButton onClick={() => handleCopy(TotalNet, 1)} clicked={copiedStates[1]}>
                {copiedStates[1] ? 'Copied' : `${TotalNet} GWh`}
              </CopyButton>
            </Td>
          </tr>
          <tr>
            <Td>Wake Effect</Td>
            <Td>
              <CopyButton onClick={() => handleCopy(WakeEffect, 2)} clicked={copiedStates[2]}>
                {copiedStates[2] ? 'Copied' : `${WakeEffect} %`}
              </CopyButton>
            </Td>
          </tr>
          <tr>
            <Td>Average Capacity Factor</Td>
            <Td>
              <CopyButton onClick={() => handleCopy(AvgCF, 3)} clicked={copiedStates[3]}>
                {copiedStates[3] ? 'Copied' : `${AvgCF}`}
              </CopyButton>
            </Td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default YieldStats;
