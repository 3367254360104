import React, { useEffect, useState } from "react";
import { PrimeButton, StyledIconButton } from "../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RadioButton } from "primereact/radiobutton";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { formatCreateInput, handleSave } from "../helperFunctions";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../firebase";
import Checkbox from "@mui/material/Checkbox";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const StandardContainerColumn = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`;

const StandardContainerRow = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function NewProject({
  platformData,
  userSaves,
  allSaves,
  projectIds,
  setPromptStatus,
  setSaveLoadShare,
  setSaveNew,
}) {
  const [user, loading, error] = useAuthState(auth);

  const [saveSettings, setSaveSettings] = useState({
    projectName: null,
    scenarioName: null,
    baseScenario: false,
    projectTags: [],
    scenarioTags: [],
  });

  const categories = [
    "Geo-Spacial Analysis",
    "Array Layout",
    "Weather Analysis",
    "Turbine",
    "Power Systems",
    "O&M",
  ];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  var date = new Date();
  const dateTime =
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " @ " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();

  const formatTags = (tags) => {
    const temp = [];
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];

      temp.push(tag.value);
    }

    return temp;
  };

  return (
    <div
      id="projectSave"
      className="projectSave"
      style={{ height: "100%", width: "25%" }}
    >
      <StyledIconButton
        title="Close Save"
        style={{ marginLeft: "8px" }}
        onClick={() => {
          setSaveNew(false);
        }}
      >
        <FontAwesomeIcon size="xs" icon={faAnglesRight} />
      </StyledIconButton>

      <StandardContainerColumn style={{ overflow: "hidden", padding: "8px" }}>
        <div className="input" style={{ width: "100%" }}>
          <label className="labelInput">Project Name</label>
          <CreatableSelect
            options={allSaves.pName}
            isClearable
            menuPortalTarget={document.getElementById("projectSave")}
            menuPosition="fixed"
            onChange={(e) =>
              setSaveSettings((saveSettings) => ({
                ...saveSettings,
                projectName: e,
              }))
            }
          />
        </div>
        <div className="input" style={{ width: "100%" }}>
          <label className="labelInput">Scenario Name</label>
          <CreatableSelect
            options={allSaves.sName}
            isClearable
            menuPortalTarget={document.getElementById("projectSave")}
            menuPosition="fixed"
            onChange={(e) =>
              setSaveSettings((saveSettings) => ({
                ...saveSettings,
                scenarioName: e,
              }))
            }
          />
        </div>
        {/* <StandardContainerRow>
                        {categories.map((category) => {
                          return (
                            <StandardContainerRow style={{ justifyContent: "normal" }}>
                              <RadioButton inputId={category} name="category" value={category} onChange={(e) => setSelectedCategory(e.value)} checked={selectedCategory === category} />
                              <label style={{ margin: "0 8px" }} htmlFor={category} className="ml-2">{category}</label>
                            </StandardContainerRow>
                          );
                        })}
                      </StandardContainerRow> */}

        {/* <div> */}
        <StandardContainerRow
          style={{ justifyContent: "normal", overflow: "inherit" }}
        >
          <Checkbox
            id="baseScenario"
            title="Select"
            aria-label="Select"
            icon={<RadioButtonUncheckedIcon fontSize="large" />}
            checkedIcon={
              <RadioButtonCheckedIcon fontSize="large" color="#009ec6" />
            }
            onChange={(e) => {
              setSaveSettings((saveSettings) => ({
                ...saveSettings,
                baseScenario: e.target.checked,
              }));
            }}
          />
          <label
            style={{ margin: "0 8px" }}
            htmlFor="baseScenario"
            className="ml-2"
          >
            Base Scenario
          </label>
        </StandardContainerRow>

        {/* {!projectIds.shared.find(item => item.id === saveSettings.projectName?.value) && !projectIds.personal.find(item => item.id === saveSettings.projectName?.value) ?
                        (<div className="input">
                            <label>Project Tags</label>
                            <Select isMulti isClearable isSearchable options={formatCreateInput(categories)} onChange={(e) => setSaveSettings(saveSettings => ({ ...saveSettings, projectTags: formatTags(e) }))} />
                        </div>) : null
                    }

                    <div className="input">
                        <label>Scenario Tags</label>
                        <Select isMulti isClearable isSearchable options={formatCreateInput(categories)} onChange={(e) => setSaveSettings(saveSettings => ({ ...saveSettings, scenarioTags: formatTags(e) }))} />
                    </div> */}
        {/* </div> */}
      </StandardContainerColumn>

      <div style={{ padding: "8px" }}>
        <PrimeButton
          style={{ width: "100%" }}
          onClick={() => {
            var location = "personal";
            if (projectIds.shared.includes(saveSettings.projectName.value)) {
              location = "shared";
            }

            setSaveNew(false);
            setPromptStatus({
              success: true,
              message: `Project Saved Successfully - View in '${location} projects'`,
            });
            setSaveLoadShare(
              handleSave(
                user,
                saveSettings,
                platformData,
                dateTime,
                userSaves,
                location
              )
            );
          }}
        >
          Save New Project
        </PrimeButton>
      </div>
    </div>
  );
}

export default NewProject;
