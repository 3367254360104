/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

// import { whole } from "../../../data/BaseLayers";
import $, { jstree } from "jquery";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";

import demo from "../../../media/turbine.png";

const apiHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
};

/**
 *
 * Function to format the JSON list of layers returned by the rest API call to GeoServer.
 *
 * @param {*} data --> JSON Data returned from GeoServer Rest API
 * @returns --> Formatted JSON to work with jsTree
 */
const formatLayers = (newData, list, countries) => {
  const jstreeJson = [];
  const missing = [];
  var active = false;

  Object.keys(list).map((key) => {
    if (countries) {
      const country = {
        id: key,
        parent: "#",
        text: key,
        state: {
          checked: false,
          opened: false,
        },
        type: "group",
        a_attr: { type: "group" },
      };

      jstreeJson.push(country);
    }

    for (let k = 0; k < list[key].length; k++) {
      const element = list[key][k];
      const id = newData.find(
        (layer) =>
          layer.name.split(":")[1].replace(/-|_/g, " ") === element.name
      )?.name;

      if (
        newData.some(
          (item) =>
            item.name.split(":")[1].replace(/-|_/g, " ") === element.name
        )
      ) {
        //(element.name === splitName[1].replace(/-|_/g, " ")) {
        let splitName = null;

        for (let i = 0; i < element.parent.length; i++) {
          const parentList = element.parent[i];
          splitName = parentList.replace(/\s/g, "_");

          if (
            !jstreeJson.some(
              (item) =>
                item.id === splitName + ":" + key ||
                item.id === splitName + ":" + element.parent[i - 1]
            )
          ) {
            const group = {
              id:
                i === 0
                  ? splitName + ":" + key
                  : splitName + ":" + element.parent[i - 1],
              parent:
                i === 0
                  ? countries
                    ? key
                    : "#"
                  : element.parent[i - 1].replace(/\s/g, "_") +
                    ":" +
                    (i > 1 ? element.parent[i - 2] : key),
              text: parentList,
              state: {
                checked: false,
                opened: false,
              },
              type: "group",
              a_attr: { type: "group" },
            };

            jstreeJson.push(group);
          }
        }

        let icon, title;
        switch (element.type) {
          case "legend":
            // icon = element.icon
            title = "Legend Layer, @Source - " + element.source;
            break;
          case "cog":
            // icon = element.icon
            title = "Filterable Layer, @Source - " + element.source;
            break;
          default:
            icon =
              `https://${process.env.REACT_APP_GEO_SERVER_URL}wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=20&HEIGHT=20&LAYER=` +
              id;
            title = "WMS Layer, @Source - " + element.source;
            break;
        }

        const layer = {
          id: id,
          parent:
            splitName +
            ":" +
            (element.parent.length > 1
              ? element.parent[element.parent.length - 2]
              : key),
          icon: icon,
          text: element.name,
          state: {
            checked: false,
            disabled: element.disable,
          },
          type: element.type,
          a_attr: {
            type: element.type,
            title: element.disable ? "Layer currently unavailable, check back soon" : title,
            source: element.source,
            sourceLink: element.sourceLink,
            geoserver: element.geoserver,
            dbName: element.dbName,
            legendURL: element.legendURL
              ? element.legendURL
              : `https://${process.env.REACT_APP_GEO_SERVER_URL}wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=${id}`,
          },
        };

        jstreeJson.push(layer);
      } else {
        if (missing.indexOf(element.name) === -1) missing.push(element.name);
      }
      // }
    }
  });

  jstreeJson.sort((a, b) => a.text.localeCompare(b.text));
  return jstreeJson;
};

/**
 *
 * Function to determine the layer ordering of the layers
 * based on their location in the jstree.
 *
 * @returns Array of Layers in Order
 */
const getLayerOrder = () => {
  var jsonData;
  const base = $("#projectJSTree").jstree().get_json("#");
  // const uk = $("#projectJSTreeUK").jstree().get_json("#")

  // jsonData = base.concat(uk)

  function searchForLayers(obj) {
    const order = [];

    for (const key in obj) {
      if (key === "children" && obj.type === "group") {
        for (let i = 0; i < obj[key].length; i++) {
          order.push(searchForLayers(obj[key][i]));
        }
      } else if (key === "id" && obj.type !== "group") {
        order.push(obj[key]);
      }
    }

    return order;
  }

  let order = [];
  for (var i = 0; i < base.length; i++) {
    order = [...order, ...searchForLayers(base[i])];
  }

  return { layer: _.flattenDeep(order), tree: base };
};

/**
 *
 * Add new group to jstree
 *
 */
const addNewGroup = () => {
  $("#projectJSTree")
    .jstree(true)
    .create_node(
      "#",
      {
        id: uuid(),
        text: "New Group",
        parent: "#",
        state: {
          checked: false,
          opened: false,
        },
        type: "group",
        a_attr: { type: "group" },
      },
      "last",
      ($node) => {
        $("#projectJSTree").jstree(true).edit($node);
      },
      true
    );
};

/**
 *
 * Check to make sure the user doesn't delete any of the base layers.
 *
 * @returns True or False to indicate if base layer
 */
const deleteLayers = (whole) => {
  var layers = [];
  const base = $("#projectJSTree").jstree(true).get_selected();
  // const uk = $("#projectJSTreeUK").jstree(true).get_selected();
  // layers = layers.concat(base)

  if (base.length > 0) {
    //$(".projectJSTree").jstree(true).delete_node(layers);
    for (let i = 0; i < base.length; i++) {
      var node = $("#projectJSTree").jstree(true).get_node(base[i]);

      // if (!node) node = $("#projectJSTreeUK").jstree(true).get_node(layers[i]);

      for (let j = 0; j < whole.length; j++) {
        for (let k = 0; k < node.children.length; k++) {
          const child = String(node.children[k].split(":")[1]).replace(
            /-|_/g,
            " "
          );
          if (whole[j].name === child) {
            return false;
          }
        }

        if (
          whole[j].name ===
            String(base[i].split(":")[1]).replace(/-|_/g, " ") ||
          whole[j].parent === String(base[i].split(":")[0]).replace(/_/g, " ")
        ) {
          return false;
        }
      }
    }

    return true;
  }
};

const getUploadedLayerData = (uid, filename) => {
  const uploadJson = {
    uid: uid,
    filename: filename,
  };

  return new Promise((resolve, reject) => {
    fetch(
      "https://us-central1-vekta-digital.cloudfunctions.net/getuploadedshp",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(uploadJson),
      }
    )
      .then((response) => {
        if (response.status === 401) {
          resolve(0);
        } else if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        } else if (response.status === 500) {
          window.alert("Failed shapefile upload - Please try again");
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
};

const searchTree = (val, tree) => {
  $(tree).jstree(true).search(val);
};

const createJSTree = (jsondata, treeName) => {
  $(treeName).jstree({
    core: {
      themes: {
        name: "proton",
        responsive: true,
      },
      data: jsondata,
      check_callback: true,
    },
    plugins: ["contextmenu", "search", "dnd", "checkbox", "types"],
    contextmenu: {
      items: function ($node) {
        var tree = $(treeName).jstree(true);
        var rename = true;

        if ($node.type === "group" || $node.type === "upload") {
          rename = false;
        }

        return {
          Rename:
            $node.type === "group" || $node.type === "upload"
              ? {
                  // _disabled: rename,
                  separator_before: false,
                  separator_after: false,
                  label: "Rename",
                  action: function (obj) {
                    tree.edit($node);
                  },
                }
              : undefined,
          // "Filter": ($node.type === "wms" || $node.type === "cog" || $node.type === "legend" ? {
          //   // _disabled: rename,
          //   separator_before: false,
          //   separator_after: false,
          //   label: "Filter Layer",
          //   action: function (obj) {
          //     console.log(obj);
          //   },
          // } : undefined),
        };
      },
    },
    checkbox: {
      three_state: true,
      whole_node: false,
      tie_selection: false,
    },
    types: {
      wms: {
        valid_children: [],
      },
      cog: {
        valid_children: [],
        icon: "glyphicon glyphicon-list",
      },
      group: {
        valid_children: [
          "wms",
          "upload",
          "draw",
          "global",
          "legend",
          "group",
          "cog",
        ],
        icon: "glyphicon glyphicon-folder-open",
      },
      upload: {
        valid_children: [],
        icon: "glyphicon glyphicon-open",
      },
      draw: {
        valid_children: [],
        icon: "glyphicon glyphicon-pencil",
      },
      global: {
        valid_children: [],
        icon: "glyphicon glyphicon-globe",
      },
      legend: {
        valid_children: [],
        icon: "glyphicon glyphicon-list",
      },
    },
  });
};

const getActiveLayers = (jsTree, platformState, setPlatformData) => {
  var layers = getLayerOrder();
  var tree = $(jsTree).jstree("get_checked");

  const formattedTree = [];
  for (let i = 0; i < tree.length; i++) {
    const node = $(jsTree).jstree().get_node(tree[i]);
    if (
      node.type !== "group" &&
      !platformState.uploadedLayers.layerNames.includes(node.id)
    ) {
      formattedTree.push({
        type: node.type,
        name: node.text,
        id: node.id,
        opacity: 100,
        range: [null, null],
        query: false,
        url: node.a_attr.geoserver.includes("gwc")
          ? node.a_attr.geoserver
          : node.a_attr.geoserver + node.id.split(":")[0] + "/wms?",
        legendURL: node.a_attr.legendURL,
      });
    }
  }

  setPlatformData((platformData) => ({
    ...platformData,
    drawnLayers: platformState.drawnLayers,
    uploadedLayers: platformState.uploadedLayers,
    sidePanelOpen: true,
    layerControl: {
      activeLayers: formattedTree,
      layerOrder: [layers],
    },
  }));

  return formattedTree;
};

const jsTreeEvents = (
  platformData,
  setPlatformData,
  setLayerTree,
  activeLegends,
  removeQueryParams,
  setControl,
  setSelectedLayer,
  jsTree
) => {
  $(jsTree).on("ready.jstree", function (e, data) {
    setTimeout(() => {
      if (platformData.layerControl.layerOrder[0].layer.length === 0) {
        var layers = getLayerOrder();
        setLayerTree({ base: layers.tree });
        setPlatformData({
          ...platformData,
          layerControl: { activeLayers: [], layerOrder: [layers] },
        });
      }

      $(jsTree).on("check_node.jstree", function (e, data) {
        setTimeout(() => {
          var platformState = JSON.parse(
            localStorage.getItem("platformData")
          ).value;

          var dataLayers = platformState.uploadedLayers.data;
          for (let i = 0; i < dataLayers.length; i++) {
            if (data.node.type === "group") {
              for (let j = 0; j < data.node.children.length; j++) {
                const child = data.node.children[j];

                if (child === dataLayers[i].features[0].properties.layerName) {
                  platformState.uploadedLayers.shown[i] = true;
                }
              }
            } else {
              if (
                data.node.id === dataLayers[i].features[0].properties.layerName
              ) {
                platformState.uploadedLayers.shown[i] = true;
              }
            }
          }

          const formattedTree = getActiveLayers(
            jsTree,
            platformState,
            setPlatformData
          );
          activeLegends(formattedTree, jsTree);

          setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
        }, 100);
      });

      $(jsTree).on("uncheck_node.jstree", function (e, data) {
        setTimeout(() => {
          var tree = $(jsTree).jstree("get_checked");
          var platformState = JSON.parse(
            localStorage.getItem("platformData")
          ).value;

          var dataLayers = platformState.uploadedLayers.data;
          for (let i = 0; i < dataLayers.length; i++) {
            if (data.node.type === "group") {
              for (let j = 0; j < data.node.children.length; j++) {
                const child = data.node.children[j];

                if (child === dataLayers[i].features[0].properties.layerName) {
                  platformState.uploadedLayers.shown[i] = false;
                }
              }
            } else {
              if (
                data.node.id === dataLayers[i].features[0].properties.layerName
              ) {
                platformState.uploadedLayers.shown[i] = false;
              }
            }
          }

          var existingQuery = platformState.layerControl.activeLayers.filter(
            (layer) => layer.query
          );
          if (!tree.includes(existingQuery)) {
            existingQuery = null;
            removeQueryParams();
            setControl(null);
          }

          var active = platformState.layerControl.activeLayers;
          for (let i = 0; i < active.length; i++) {
            if (data.node.id === active[i].id) {
              platformState.layerControl.activeLayers.splice(i, 1);
            }
          }

          const formattedTree = getActiveLayers(
            jsTree,
            platformState,
            setPlatformData
          );
          activeLegends(formattedTree, jsTree);

          setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
        }, 100);
      });

      $(jsTree).on("delete_node.jstree", function (e, data) {
        setTimeout(() => {
          var platformState = JSON.parse(
            localStorage.getItem("platformData")
          ).value;

          if (data.node.parent !== "#") {
            var dataLayers = platformState.uploadedLayers;
            for (let i = 0; i < dataLayers.data.length; i++) {
              if (
                data.node.id ===
                dataLayers.data[i].features[0].properties.layerName
              ) {
                dataLayers.data.splice(i, 1);
                dataLayers.layerNames.splice(i, 1);
                dataLayers.shown.splice(i, 1);
              }
            }
          } else {
            var dataLayers = platformState.uploadedLayers;
            for (let i = 0; i < dataLayers.data.length; i++) {
              for (let j = 0; j < data.node.children.length; j++) {
                if (
                  data.node.children[j] ===
                  dataLayers.data[i].features[0].properties.layerName
                ) {
                  dataLayers.data.splice(i, 1);
                  dataLayers.layerNames.splice(i, 1);
                  dataLayers.shown.splice(i, 1);
                }
              }
            }
          }

          getActiveLayers(jsTree, platformState, setPlatformData);
          setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
        }, 100);
      });

      $(document).on("dnd_stop.vakata", function (e, data) {
        setTimeout(() => {
          var platformState = JSON.parse(
            localStorage.getItem("platformData")
          ).value;

          getActiveLayers(jsTree, platformState, setPlatformData);
          setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
        }, 100);
      });

      $(jsTree).on("check_all.jstree", function (e, data) {
        setTimeout(() => {
          var platformState = JSON.parse(
            localStorage.getItem("platformData")
          ).value;

          getActiveLayers(jsTree, platformState, setPlatformData);
          setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
        }, 100);
      });

      $(jsTree).on("uncheck_all.jstree", function (e, data) {
        setTimeout(() => {
          var layers = getLayerOrder();
          var platformState = JSON.parse(
            localStorage.getItem("platformData")
          ).value;

          setPlatformData((platformData) => ({
            ...platformData,
            drawnLayers: platformState.drawnLayers,
            uploadedLayers: platformState.uploadedLayers,
            sidePanelOpen: true,
            layerControl: {
              activeLayers: [],
              layerOrder: [layers],
            },
          }));
        }, 100);
        setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
      });

      $(jsTree).on(
        "rename_node.jstree after_open.jstree after_close.jstree",
        function (e, data) {
          setTimeout(() => {
            var platformState = JSON.parse(
              localStorage.getItem("platformData")
            ).value;

            getActiveLayers(jsTree, platformState, setPlatformData);
            setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
          }, 100);
        }
      );

      $(jsTree).on("select_node.jstree", function (e, data) {
        setSelectedLayer(data.node.id);
        setLayerTree({ base: $("#projectJSTree").jstree().get_json("#") });
      });
    }, 100);
  });
};

export {
  formatLayers,
  createJSTree,
  searchTree,
  addNewGroup,
  deleteLayers,
  getUploadedLayerData,
  getLayerOrder,
  jsTreeEvents,
};
