import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";
import {
  ModalClose,
  PrimeButton,
  SecButton,
  StandardContainerRow,
} from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app, storage } from "../../../firebase";
import { Box, Divider, Modal } from "@mui/material";
import { BounceLoader } from "react-spinners";
import { getDownloadURL, ref } from "firebase/storage";

function HelpModal({
  customerService,
  setCustomerService,
  setRunJoyride,
  setRunJoyrideSteps,
  steps,
  stepsMap,
}) {
  const [messageSent, setMessageSent] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [comment, setComment] = useState("");

  const handleMetaDownload = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "appMisc/layerDataReference.xlsx"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "LayerSources.xlsx";
        a.target = "_blank";
        a.click();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    <Modal
      open={customerService}
      onClose={() => setCustomerService(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: 2,
            position: "fixed",
            inset: "auto 35%",
            gap: "16px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <header>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2>Guidance & Data</h2>
              <ModalClose
                onClick={() => {
                  setCustomerService(false);
                  setMessageSent(false);
                }}
              >
                X
              </ModalClose>
            </div>
            <Divider sx={{ bgcolor: "black" }} />
          </header>

          <body>
            <div>
              <h3>Data Sources</h3>
              <p>
                Copyright and licensing information for the data layers used in
                Vekta Digital can be downloaded here:
              </p>
              <p>
                <a
                  style={{ color: "lightblue", cursor: "pointer" }}
                  onClick={handleMetaDownload}
                >
                  Download Metadata
                </a>
              </p>
            </div>

            <div>
              <h3>In Tool Guidance</h3>
              <p>Re-play tool guidance on how-to use the platform:</p>
              <StandardContainerRow style={{ justifyContent: "space-evenly" }}>
                <SecButton
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    setCustomerService(false);
                    setRunJoyrideSteps(steps);
                    setRunJoyride(true);
                  }}
                >
                  Platform Guidance
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </SecButton>
                <SecButton
                  style={{ width: "fit-content" }}
                  onClick={() => {
                    setCustomerService(false);
                    setRunJoyrideSteps(stepsMap);
                    setRunJoyride(true);
                  }}
                >
                  Draw Tool Guidance
                  <FontAwesomeIcon icon={faQuestionCircle} />
                </SecButton>
              </StandardContainerRow>
            </div>
          </body>

          <footer style={{ display: "flex", justifyContent: "flex-end" }}>
            <PrimeButton onClick={() => setCustomerService(false)}>
              Close
            </PrimeButton>
          </footer>
        </Box>
      </div>
    </Modal>
  );
}

export default HelpModal;
