import React, { useContext, useEffect, useState } from 'react'
import styled from "styled-components";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical as ActionControl,
} from '@fortawesome/free-solid-svg-icons';

const StyledIconButton = styled(IconButton)`
  width:35px;
  height:35px;
  background-color: ${props => props.active ? '#e0e0e0' : 'transparent'} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: rgba(0, 158, 198, 0.255) !important;
    transform: scale(1.1);
  }
`;

function SettingsMenu({ row, onDeleteRow, project }) {
  const [settingsMenu, setSettingsMenu] = useState({ open: null, row: null })

  const handleClose = () => {
    setSettingsMenu(settingsMenu => ({...settingsMenu, open: null }))
  };
  const deleteRow = () => {
    if (onDeleteRow) {
      onDeleteRow(row, project);
      // onDeleteRow();
    }
  };
  return (
    <div>
      <StyledIconButton title='Scenario Settings' onClick={(e) => setSettingsMenu({ open: e.currentTarget, row: row })}>
        <FontAwesomeIcon size='xs' icon={ActionControl} />
      </StyledIconButton>

      <Menu
        id="menu"
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={settingsMenu.open}
        keepMounted
        open={Boolean(settingsMenu.open)}
        onClose={handleClose}
      >
        {/* <MenuItem>Rename</MenuItem> */}

        {/* <Divider /> */}

        <MenuItem onClick={deleteRow}>
          <ListItemIcon>
            <DeleteIcon fontSize="medium"/>
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>
    </div>
  )
}

export default SettingsMenu