import React, { createContext } from 'react'

const ActiveBtnContext = createContext();
const CurrentWidgetContext = createContext();
const SidebarContentContext = createContext();
const StoreWidgets = createContext();
const PlatformData = createContext();
const LoadingStatus = createContext();

export {
    ActiveBtnContext,
    SidebarContentContext,
    StoreWidgets,
    PlatformData,
    LoadingStatus
}