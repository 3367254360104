/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useEffect, useState } from "react";
import L from "leaflet"
import Fuse from "fuse.js";
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Popper,
  List,
  ListItem,
  ListItemText,
  Paper,
  Modal,
} from "@mui/material";
import { Search, Info, Close } from "@mui/icons-material";
import { ClickAwayListener } from "@mui/material";

import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../../firebase";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="right" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#5a5a5a",
    fontSize: "12px",
    border: "1px solid lightgray",
  },
}));

const SearchBar = ({ placeholder, items, onItemSelected, infoTitle }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredItems, setFilteredItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [canSearch, setCanSearch] = useState(true); // 
  const [openModal, setOpenModal] = useState(false);
  const [user,setUser] = useState(null);
  const [id_token,setIdToken] = useState(null);


  const handleOpenModal = () => {
    // setOpenModal(true);
  };

  const handleClearSearch = () => {
    onItemSelected("");
    setSearchText("");
    setFilteredItems([]); // This line will close the Popper after selecting an item.
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const callSearchWindfarmAPI = async (query) => {
    try {
      // const api_url = 'https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_windfarm_api'
      const api_url = 'https://europe-west1-vekta-functions-380610.cloudfunctions.net/search_windfarm_api'
      // const api_url = "http://192.168.200.115:8080"
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query_string: query, id_token: id_token })
      })

      const output = await response.json()
      setFilteredItems(output)
    } catch (err) {
      console.error(err)
    }

  }


  useEffect(()=>{
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;
        setUser(user)
        setIdToken(user.getIdToken())
        // ...
      } else {
        // User is signed out
        setUser(null)
      }
    });
  },[])

  useEffect(()=>{
    const setToken = async () => {
      setIdToken(await user.getIdToken())
    }
    if(user){
      setToken()
    }
  },[user])



   useEffect(() => {

     if (canSearch) {
       callSearchWindfarmAPI(searchText)
     }


   }, [canSearch, searchText,id_token]);



  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (searchText !== "") {
  //       setCanSearch(true)
  //     }
  //   }, 300);
  //   return () => clearTimeout(timer);
  // }, [searchText]);

  // const fuse = new Fuse(items, fuseOptions);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
    setAnchorEl(event.currentTarget);
    // setCanSearch(false)
    callSearchWindfarmAPI(event.target.value)
  };


  const handleItemClick = (item) => {
    onItemSelected(item);
    setSearchText(item.name);
    setFilteredItems([]); // This line will close the Popper after selecting an item.
  };

  const handleClickAway = () => {
    setFilteredItems([]);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: 1.5,
        // m: 1,
      }}
    >
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={{ width: "100%" }}>

          <TextField
            placeholder={placeholder}
            value={searchText}
            onChange={handleSearch}
            variant="outlined"
            size="small"
            autoComplete="off"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="large" />
                </InputAdornment>
              ),
              endAdornment: searchText === "" ? (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small" onClick={handleOpenModal}>
                    <Info fontSize="large" />
                  </IconButton>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <IconButton edge="end" size="small" onClick={handleClearSearch}>
                    <Close fontSize="large" />
                  </IconButton>
                </InputAdornment>
              ),
              style: {
                fontSize: "16px",
                color: "#5a5a5a",
                borderRadius: "4px",
                backgroundColor: "white",
                height: "47px",
              },
            }}
            fullWidth
          />
          <Popper
            open={open && filteredItems.length > 0}
            anchorEl={anchorEl}
            placement="bottom-start"
            style={{ zIndex: "5000" }}
          >
            <Paper
              sx={{

                minWidth: 355,
                maxHeight: 300,
                overflow: "auto",
                borderRadius: 1,
                border: "1px solid lightgray",
                mt: 0.5,
                "&::-webkit-scrollbar": {
                  width: "8px",
                  borderRadius: "4px",
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "4px",
                  backgroundColor: "rgba(0, 0, 0, 0.15)",
                },
                "&::-webkit-scrollbar-corner": {
                  backgroundColor: "transparent",
                },
              }}
            >

              <List>
                {filteredItems.map((item, index) => (
                  <ListItem
                    key={index}
                    onClick={() => {
                      handleItemClick(item);
                    }}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        borderColor: "#5a5a5a",
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                      },
                      fontSize: "16px"
                    }}
                  >
                    <CustomTooltip title={item.description}>
                      <ListItemText
                        sx={{
                          fontSize: "16px",
                          color: "#5a5a5a",
                        }}
                      >
                        <div style={{ fontSize: "14px" }}>
                          {item.name}
                        </div>
                      </ListItemText>
                    </CustomTooltip>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Popper>
        </div>
      </ClickAwayListener>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            minWidth: 300,
            maxWidth: 650,
            borderRadius: 1,
            maxHeight: 800,
            overflowY: "auto",
            zIndex: 1001,
            "&::-webkit-scrollbar": {
              width: "8px",
              borderRadius: "4px",
              backgroundColor: "rgba(0, 0, 0, 0.05)",
            },
            "&::-webkit-scrollbar-thumb": {
              borderRadius: "4px",
              backgroundColor: "rgba(0, 0, 0, 0.15)",
            },
            "&::-webkit-scrollbar-corner": {
              backgroundColor: "transparent",
            },
          }}
        >
          <h2
            id="modal-title"
            style={{ marginTop: 0, marginBottom: 8, color: "#5a5a5a" }}
          >
            {infoTitle}
          </h2>
          <List>
            {items.map((item, index) => (
              <ListItem
                key={index}
                onClick={() => {
                  handleItemClick(item);
                  handleCloseModal();
                }}
                sx={{
                  cursor: "pointer",
                  border: "1px solid lightgray",
                  borderRadius: 1,
                  mb: 1,
                  p: 1,
                  "&:hover": {
                    borderColor: "#5a5a5a",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                  },
                }}
              >
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  secondary={item.description}
                  secondaryTypographyProps={{
                    fontSize: "12px",
                  }}
                  sx={{
                    color: "#5a5a5a",
                    ml: 2,
                    mr: 2,
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </Box>
  );
};

export default SearchBar;
