import React, { useContext, useState } from "react";
import { Box, Divider, Modal, Avatar, Badge } from "@mui/material";
import {
  ModalClose,
  PrimeButton,
  SecButton,
  StyledIconButton,
} from "../../../../styles/styles";
import CloseIcon from "@mui/icons-material/Close";
import CreatableSelect from "react-select/creatable";
import { formatCreateInput, handleLoad, handleSave } from "../helperFunctions";
import { doc } from "firebase/firestore";
import { db, auth } from "../../../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { PlatformData } from "../../../../imports/Context";

function QuickSaveLoad({
  openQuick,
  setOpenQuick,
  userSaves,
  allSaves,
  platformStructure,
  setSearchParams,
  setPromptStatus,
}) {
  const [quickSaveName, setQuickSaveName] = useState({
    project: null,
    scenario: null,
  });
  const [scenarioOptions, setScenarioOptions] = useState([]);
  const [user, loading, error] = useAuthState(auth);
  const { platformData, setPlatformData } = useContext(PlatformData);

  var date = new Date();
  const dateTime =
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " @ " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();

  /**
   * Setting the dropdowns to only show the scenarios associated to that project
   * @param {Name of project} project
   */
  const handleQuickOptions = (project) => {
    setScenarioOptions([]);

    Object.keys(userSaves.personal).map((key) => {
      if (key === project.value) {
        setScenarioOptions((scenarioOptions) => [
          ...scenarioOptions,
          {
            label: "Personal Scenarios",
            options: formatCreateInput(userSaves.personal[key].scn.ids),
          },
        ]);
      }
    });

    Object.keys(userSaves.shared).map((key) => {
      if (key === project.value) {
        setScenarioOptions((scenarioOptions) => [
          ...scenarioOptions,
          {
            label: "Shared Scenarios",
            options: formatCreateInput(userSaves.shared[key].scn.ids),
          },
        ]);
      }
    });
  };

  return (
    <Modal
      open={openQuick.open}
      onClose={() =>
        setOpenQuick((openQuick) => ({
          ...openQuick,
          open: false,
        }))
      }
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          id="quick-load-save"
          className="modal-popups"
          sx={{
            inset: "30%",
            overflow: "hidden",
          }}
        >
          <header>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ margin: "0 0 16px 0" }}>Quick {openQuick.type}</h2>
              <ModalClose
                onClick={() =>
                  setOpenQuick((openQuick) => ({
                    ...openQuick,
                    open: false,
                  }))
                }
              >
                <StyledIconButton>
                  <CloseIcon />
                </StyledIconButton>
              </ModalClose>
            </div>
            <Divider
              style={{ marginBottom: "16px" }}
              sx={{ bgcolor: "black" }}
            />
          </header>

          <div className="input">
            <label className="labelInput">Project Name</label>
            <CreatableSelect
              isClearable
              options={allSaves.pName}
              onChange={(e) => {
                setQuickSaveName((quickSaveName) => ({
                  ...quickSaveName,
                  project: e,
                }));

                // if (quickSave.type === "Open") {
                handleQuickOptions(e);
                // }
              }}
              menuPortalTarget={document.getElementById("quick-load-save")}
              menuPosition="fixed"
            />
          </div>
          <div className="input">
            <label className="labelInput">Scenario Name</label>
            <CreatableSelect
              isClearable
              options={scenarioOptions}
              onChange={(e) =>
                setQuickSaveName((quickSaveName) => ({
                  ...quickSaveName,
                  scenario: e,
                }))
              }
              menuPortalTarget={document.getElementById("quick-load-save")}
              menuPosition="fixed"
            />
          </div>

          <footer
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <SecButton
              style={{ marginRight: "8px" }}
              onClick={() => {
                setOpenQuick({ open: false, type: "Open" });
              }}
            >
              Cancel
            </SecButton>

            <PrimeButton
              onClick={() => {
                if (openQuick.type === "Open") {
                  setSearchParams((searchParams) => ({
                    ...searchParams,
                    projectName:
                      quickSaveName.project.value +
                      " - " +
                      quickSaveName.scenario.value,
                  }));

                  handleLoad(
                    user,
                    doc(
                      db,
                      "userSaves",
                      user.uid,
                      "personal",
                      quickSaveName.project.value
                    ),
                    quickSaveName.scenario.value,
                    setPlatformData
                  );

                  setOpenQuick({ open: false, type: "Open" });
                  return;
                }

                setPromptStatus(
                  handleSave(
                    user,
                    {
                      projectName: quickSaveName.project,
                      scenarioName: quickSaveName.scenario,
                      baseScenario: false,
                      scenarioTags: [],
                      projectTags: [],
                    },
                    platformData,
                    dateTime,
                    userSaves,
                    "personal"
                  )
                );

                if (openQuick.type === "Save and Close") {
                  setPlatformData(platformStructure);
                  setSearchParams({});
                }

                setOpenQuick({ open: false, type: "Open" });
              }}
            >
              {openQuick.type}
            </PrimeButton>
          </footer>
        </Box>
      </div>
    </Modal>
  );
}

export default QuickSaveLoad;
