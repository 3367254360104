import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { app, db } from "../../../firebase";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear as SettingsControl } from "@fortawesome/free-solid-svg-icons";
import { getFunctions, httpsCallable } from "firebase/functions";

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  margin: 8px;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: rgba(0, 158, 198, 0.255) !important;
    transform: scale(1.1);
  }
`;

function UserIcons({
  list,
  setShareSettingsPop,
  status,
  limit,
  setSharedWith,
  projName,
  scenarios,
}) {
  const [users, setUsers] = useState({ raw: [], formatted: [] });

  useEffect(() => {
    setUsers({ raw: [], formatted: [] });

    Object.keys(list).map((key) => {
      const functions = getFunctions(app, "us-central1");
      const info = httpsCallable(functions, "getUserInfo");

      info({ uid: key })
        .then((result) => {

          let name =
            result.data.firstname &&
            result.data.firstname.charAt(0).toUpperCase() +
              " " +
              result.data.lastname.charAt(0).toUpperCase();

          if (!name) name = result.data.email.charAt(0).toUpperCase();

          setUsers((users) => ({
            raw: [...users.raw, { name: name, title: result.data.email }],
            formatted: [
              ...users.formatted,
              {
                id: key,
                projName: projName,
                email: result.data.email,
                permissions: list[key],
                scenarios: scenarios,
              },
            ],
          }));
        })
        .catch((error) => {
          // Getting the Error details.
          console.log(error.code);
        });
    });
  }, [list]);

  useEffect(() => {
    setSharedWith((sharedWith) => ({
      ...sharedWith,
      selected: users.formatted,
    }));
  }, [users.formatted]);

  return (
    <div style={{ display: "flex", alignItems: "center", minHeight: "44px" }}>
      <AvatarGroup max={limit}>
        {users.raw.map((user) => (
          <Avatar
            style={{
              backgroundColor: "#009ec6",
              color: "#ffffff",
              // fontSize: "1rem",
              // width: "2rem",
              // height: "2rem",
              fontWeight: "600",
              fontFamily: "Montserrat",
            }}
            title={user.email}
          >
            {user.name}
          </Avatar>
        ))}
      </AvatarGroup>

      {!status && (
        <StyledIconButton
          onClick={() => {
            setSharedWith((sharedWith) => ({
              ...sharedWith,
              selected: users.formatted,
            }));
            setShareSettingsPop(true);
          }}
        >
          <FontAwesomeIcon size="xs" icon={SettingsControl} />
        </StyledIconButton>
      )}

      {list.length === 0 && <p style={{ margin: "0" }}>No Share Members</p>}
    </div>
  );
}

export default UserIcons;
