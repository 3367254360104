/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useEffect, useState } from "react";
import { sha512 } from "js-sha512";
import {
  auth,
  logInWithEmailAndPassword,
  signInWithGoogle,
  signInWithMicrosoft,
  registerWithEmailAndPassword
} from "../../firebase";

// import google from "../../../media/icons/google.svg"
// import microsoft from "../../../media/icons/windows.svg"

import Divider from '@mui/material/Divider';

import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Box, Modal } from "@mui/material";
import { ModalClose, PrimeButton, SecButton, StyledIconButton } from "../../styles/styles";

import CloseIcon from '@mui/icons-material/Close';
import { useAuthState } from "react-firebase-hooks/auth";
import ChangePassword from "./ChangePassword";
import { useNavigate } from "react-router-dom";

function Login({ loginSelected, setLoginSelected }) {
  const [email, setEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState({ first: null, check: null });

  const [signUp, setSignUp] = useState(false);
  const [continueMobile, setContinueMobile] = useState(false)
  const [loginMobile, setLoginMobile] = useState(false)
  const [signupMobile, setSignupMobile] = useState(false)

  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [forgottenPassword, setForgottenPassword] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const [userAgreement, setUserAgreement] = useState({ popup: false, tc: true, newsletter: true });

  const d = new Date();
  // const { platformData, setPlatformData } = useContext(PlatformData)

  const header = <div>Pick a password</div>;
  const footer = (
    <>
      <Divider />
      <p>Suggestions</p>
      <ul>
        <li>At least one lowercase</li>
        <li>At least one uppercase</li>
        <li>At least one numeric</li>
        <li>Minimum 8 characters</li>
      </ul>
    </>
  );

  const checkPasswordMatch = () => {
    if (signUpPassword.first && signUpPassword.check) {
      const first = sha512(signUpPassword.first)
      const check = sha512(signUpPassword.check)

      if (first === check) {
        return true;
      }

      return false;
    }
  }
  
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      setLoginSelected(false);
      navigate(`/vekta-digital/LayerControl`);
      // setPlatformData(platformData => ({
      //   ...platformData,
      //   ["activeWidget"]: "LayerControl"
      // }))
    }
  }, [user, loading]);

  return (
    <Modal
      open={loginSelected}
      onClose={() => setLoginSelected(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className="GIS_Loader"
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: "16px",
            position: "fixed",
            inset: "auto 35%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            alignItems: "center",
          }}
        >
          <span className={!emailSent ? "emailSent_notification" : "emailSent_notification toggle_display"}>Reset Email Sent, Check Your Inbox!</span>

          <header style={{ marginBottom: "16px", width: "100%" }}>
            <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between" }}>
              <h1>Welcome</h1>
              <ModalClose onClick={() => setLoginSelected(false)}>
                <StyledIconButton>
                  <CloseIcon />
                </StyledIconButton>
              </ModalClose>
            </div>
          </header>

          <div style={{ width: "80%" }}>
            {!signUp ? (
              <div className="toolLogin_form">
                <div className="toolLogin_firebase">
                  <span className="p-float-label" style={{ width: "100%", marginBottom: "24px", height: "3rem" }}>
                    <InputText id="toolLogin_email" style={{ width: "100%", height: "3rem" }} />
                    <label htmlFor="toolLogin_email">Email *</label>
                  </span>

                  <span className="p-float-label" style={{ width: "100%", height: "3rem" }}>
                    <Password inputId="toolLogin_password" feedback={false} toggleMask style={{ width: "100%", height: "3em", fontFamily: "Montserrat", fontSize: "16px" }} />
                    <label htmlFor="toolLogin_password">Password *</label>
                  </span>

                  <a className="forgottenPassword" onClick={() => setForgottenPassword(true)}>Forgot Password?</a>
                  {/* <div className={forgottenPassword && !loginMobile ? "forgottenPassword_popup" : "forgottenPassword_popupMobile"} style={forgottenPassword ? {} : { display: "none" }}>
                    <div className="input">
                      <label>Account Email</label>
                      <input style={{ color: "black" }} id="accountEmail" type="email" placeholder="name@example.com" />
                    </div>

                    <div className="popup_footer">
                      <button onClick={() => setForgottenPassword(false)}>Cancel</button>
                      <button onClick={() => sendPasswordResetEmail(auth, document.getElementById("accountEmail").value).then(() => {
                        setForgottenPassword(false)
                        setEmailSent(true)
                      })}>Send Email</button>
                    </div>
                  </div> */}
                </div>

                <div className="toolLogin_footer">
                  <SecButton style={{ width: "49%" }} onClick={() => setSignUp(true)}>
                    Sign Up
                  </SecButton>
                  <SecButton
                    style={{ width: "49%" }}
                    onClick={() => logInWithEmailAndPassword(document.getElementById("toolLogin_email").value, document.getElementById("toolLogin_password").value)}
                  >
                    Login
                  </SecButton>
                </div>

                <Divider style={{ width: "100%", margin: "32px 0" }}>
                  Or Sign In With
                </Divider>

                <div className="firebase_oAuth">
                  <PrimeButton style={{ width: "49%" }} onClick={signInWithMicrosoft}>
                    {/* <img src={microsoft} width={"16px"} /> */}
                    <img src="./svg/windows.svg" width={24}/>
                  </PrimeButton>
                  <PrimeButton style={{ width: "49%" }} onClick={signInWithGoogle}>
                    {/* <img src={google} width={"16px"} /> */}
                    <img src="./svg/google.svg" width={24}/>
                  </PrimeButton>
                </div>
              </div>
            ) : (
              <>
                <div className="toolSignup_inputs">
                  <form id="{{ ns('toolSignup_inputs') }}">
                    <span className="p-float-label" style={{ marginBottom: "32px", height: "3rem" }}>
                      <InputText id="toolSignup_email" onChange={(e) => { }} style={{ width: "100%", height: "3rem" }} />
                      <label htmlFor="toolSignup_email">Email *</label>
                    </span>

                    <span className="p-float-label" style={{ marginBottom: "32px", height: "3rem" }}>
                      <Password inputId="toolSignup_password" onChange={(e) => setSignUpPassword({ ...signUpPassword, first: e.currentTarget.value })} header={header} footer={footer} style={{ width: "100%", height: "3rem", fontFamily: "Montserrat", fontSize: "16px" }} />
                      <label htmlFor="toolSignup_password">Password *</label>
                    </span>

                    <span className="p-float-label" style={{ marginBottom: "32px", height: "3rem" }}>
                      <Password inputId="toolSignup_passwordCheck" onChange={(e) => setSignUpPassword({ ...signUpPassword, check: e.currentTarget.value })} feedback={false} style={{ width: "100%", height: "3rem", fontFamily: "Montserrat", fontSize: "16px" }} />
                      <label htmlFor="toolSignup_passwordCheck">Confirm Password *</label>
                    </span>
                  </form>
                </div>

                <div className="toolSignup_footer">
                  <SecButton
                    className="toolSignup_cancel"
                    onClick={() => setSignUp(false)}
                  >
                    Cancel
                  </SecButton>

                  <PrimeButton
                    onClick={() => {
                      if (checkPasswordMatch()) {
                        registerWithEmailAndPassword(document.getElementById("toolSignup_email").value, signUpPassword.first)
                      } else {
                        console.log("PASSWORD DOESNT MATCH");
                      }
                    }}
                  >
                    Sign Up
                  </PrimeButton>
                </div>
              </>
            )}
          </div>
        </Box>

        <ChangePassword changePassword={forgottenPassword} setChangePassword={setForgottenPassword} setEmailSent={setEmail} />
      </div>
    </Modal>
  );
}

export default Login;
