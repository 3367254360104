/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */
import * as XLSX from "xlsx";
import Papa from "papaparse";
import { convertToGeoJson, convertToOldSite } from "../../convertors";

const apiHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
};

export const getPersistanceResults = ({
  window,
  height,
  period,
  speed,
  weatherSelect,
  platformData,
  loadingStatus,
  setLoadingStatus,
  user,
}) => {
  var dataloc = "gcp";
  var coordinates = platformData.weatherAnalysis.pointClick;
  if (weatherSelect === "uploadWeather") {
    dataloc = "upload";
    coordinates = [0, 0];
  }

  const persistenceJson = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: coordinates,
    },
    properties: {
      name: "Sample Point",
      dataloc: dataloc,
      window: window,
      wave: height,
      tp: period,
      wind: speed,
      windHeight: isNaN(platformData.weatherAnalysis.windHeight)
        ? 100
        : Number(platformData.weatherAnalysis.windHeight),
      uid: user.uid,
    },
  };

  return new Promise((resolve, reject) => {
    fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/persistence", {
      method: "POST",
      headers: apiHeaders,
      body: JSON.stringify(persistenceJson),
    })
      .then((response) => {
        if (response.status === 401) {
          resolve(0);
        } else if (response.status === 200) {
          response.json().then((data) => {
            resolve(data);
          });
        } else if (response.status === 500) {
          setLoadingStatus({ loading: false, loadingMsg: "" });
          alert("An Error Has Occured, Please Try Again Later");
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const getERA5Data = ({ platformData, setDownloadingData }) => {
  if (platformData.weatherAnalysis.pointClick.length > 0) {
    const pointDataJson = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: platformData.weatherAnalysis.pointClick,
      },
      properties: {
        name: "Sample Point",
        dataloc: "gcp",
        weatherdatatype: "weather",
        windHeight: isNaN(platformData.weatherAnalysis.windHeight)
          ? 100
          : Number(platformData.weatherAnalysis.windHeight),
      },
    };

    const call = new Promise((resolve, reject) => {
      fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
        method: "POST",
        headers: apiHeaders,
        body: JSON.stringify(pointDataJson),
      })
        .then((response) => {
          if (response.status === 401) {
            resolve(0);
          } else if (response.status === 200) {
            response.json().then((data) => {
              const blob = new Blob([Papa.unparse(data)]);
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement("a");

              a.href = url;
              a.download =
                "WeatherPoint[" +
                platformData.weatherAnalysis.pointClick[0] +
                "_" +
                platformData.weatherAnalysis.pointClick[1] +
                "].csv";
              a.click();
              window.URL.revokeObjectURL(url);

              setDownloadingData(false);
            });
          } else if (response.status === 503) {
            alert("Sorry for the inconvendece");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
};

export const formatPersistenceData = (persistence, percentage, months) => {
  var data = [];
  var combo = persistence.PersistenceCombinations;

  for (let i = 0; i < combo.length; i++) {
    const splitPercentile = [[], [], [], [], [], [], [], [], []];

    const percentile =
      persistence.Persistence["W" + combo[i].W.toString()][
        "wind" + combo[i].wind.toString()
      ]["wave" + combo[i].wave.toString()]["tp" + combo[i].tp.toString()];

    for (let j = 0; j < percentage.length; j++) {
      for (let k = 0; k < months.length; k++) {
        for (let l = 0; l < percentile.length; l++) {
          if (
            percentile[l].Percent === percentage[j].value &&
            percentile[l].Month === months[k]
          ) {
            splitPercentile[j].push(percentile[l].Value.toFixed(2));
          }
        }
      }
    }
    data.push({
      window: combo[i].W.toString(),
      wind: combo[i].wind.toString(),
      wave: combo[i].wave.toString(),
      tp: combo[i].tp.toString(),
      fullData: splitPercentile,
    });
  }
  return data;
};

export const getUploadedCoordsData = (
  uid,
  setPlatformData,
  setLoadingStatus
) => {
  const weatherJson = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [0, 0],
    },
    properties: {
      name: "Sample Point",
      dataloc: "upload",
      weatherdatatype: "windrose",
      windHeight: 100,
      uuid: uid,
    },
  };

  return new Promise((resolve, reject) => {
    fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
      method: "POST",
      headers: apiHeaders,
      body: JSON.stringify(weatherJson),
    })
      .then((response) => {
        if (response.status === 401) {
          resolve(0);
        } else if (response.status === 200) {
          response.json().then((windRose) => {
            weatherJson.properties.weatherdatatype = "avgwindspeed";
            fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
              method: "POST",
              headers: apiHeaders,
              body: JSON.stringify(weatherJson),
            })
              .then((response) => {
                if (response.status === 401) {
                  resolve(0);
                } else if (response.status === 200) {
                  response.json().then((windSpeed) => {
                    setPlatformData((platformData) => ({
                      ...platformData,
                      // "activeWidget": "WeatherAnalysis",
                      sidePanelOpen: true,
                      weatherAnalysis: {
                        ...platformData.weatherAnalysis,
                        pointClick: [
                          parseFloat(windRose.ERALong[0]),
                          parseFloat(windRose.ERALat[0]),
                        ],
                        windSpeedData: windSpeed,
                        windRoseData: windRose,
                      },
                    }));

                    setLoadingStatus({ loading: false, loadingMsg: "" });
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

/////////////////// add weather api call for non manual points

export const callWeatherAPI = (
  platformData,
  setPlatformData,
  setLoadingStatus
) => {
  const weatherJson = {
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [
        platformData.weatherAnalysis.pointClick[0],
        platformData.weatherAnalysis.pointClick[1],
      ],
    },
    properties: {
      name: "Sample Point",
      dataloc: "gcp",
      weatherdatatype: "windrose",
      windHeight: isNaN(platformData.weatherAnalysis.windHeight)
        ? 100
        : Number(platformData.weatherAnalysis.windHeight),
    },
  };

  return new Promise((resolve, reject) => {
    fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
      method: "POST",
      headers: apiHeaders,
      body: JSON.stringify(weatherJson),
    })
      .then((response) => {
        if (response.status === 401) {
          resolve(0);
        } else if (response.status === 200) {
          response.json().then((windRose) => {
            weatherJson.properties.weatherdatatype = "avgwindspeed";
            fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
              method: "POST",
              headers: apiHeaders,
              body: JSON.stringify(weatherJson),
            })
              .then((response) => {
                if (response.status === 401) {
                  resolve(0);
                } else if (response.status === 200) {
                  response.json().then((windSpeed) => {
                    setPlatformData((platformData) => ({
                      ...platformData,
                      // "activeWidget": "WeatherAnalysis",
                      sidePanelOpen: true,
                      weatherAnalysis: {
                        ...platformData.weatherAnalysis,
                        pointClick: [
                          parseFloat(windRose.ERALong[0]),
                          parseFloat(windRose.ERALat[0]),
                        ],
                        windSpeedData: windSpeed,
                        windRoseData: windRose,
                      },
                    }));

                    setLoadingStatus({ loading: false, loadingMsg: "" });
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

export const parseCSVAndSetCoords = (
  file,
  platformData,
  setPlatformData,
  uuidv4,
  setCoordsData
) => {
  Papa.parse(file, {
    header: true,
    complete: (results) => {
      const jsonData = results.data
        .map((row, index) => ({
          ids: isNaN(row.ids) ? row.ids : index.toString(), // Use row.ids directly if it's not a number (for "osp1")
          lon: parseFloat(row.lon),
          lat: parseFloat(row.lat),
        }))
        .filter((row) => !isNaN(row.lon) && !isNaN(row.lat)); // Filter out rows where lon or lat is NaN

      const osps = [
        ...jsonData.filter((element) => element?.ids?.includes("osp")),
      ];
      jsonData.filter((element, i) => {
        if (element?.ids?.includes("osp")) {
          jsonData.splice(i, 1);
        }
      });

      const checkForUndefined = (data) => {
        let error;
        for (let i = 0; i < data.length; i++) {
          const obj = data[i];
          error = Object.keys(obj).map((key) => {
            if (obj[key] === undefined) return true;
          });
        }

        return error.includes(true);
      };

      /// to draw
      if (jsonData.length > 0 && !checkForUndefined(jsonData)) {
        setPlatformData((platformData) => ({
          ...platformData,
          siteBuilder: {
            ...platformData.siteBuilder,
            [uuidv4()]: {
              siteTurbs: jsonData,
              siteName: file.name.split(".")[0],
              siteSettings: { siteName: file.name.split(".")[0] },
              siteCables: [],
              siteOSP: osps,
              datetime: Date(),
            },
          },
        }));

        setCoordsData({ coords: [...jsonData] });
      } else {
        window.alert("Invalid CSV Uploaded - Please Use Template");
      }
    },
    error: (error) => {
      console.error("Error parsing CSV:", error);
    },
  });
};

export const parseCSVAndSetPowerCurve = (
  url,
  turbineType,
  setPowerCurveData
) => {
  // Extract filename from the selected turbine type
  const urlwithfilename = url + turbineType + ".csv";
  // Fetch the CSV file from the URL
  fetch(urlwithfilename)
    .then((response) => response.text())
    .then((csv) => {
      // Parse the CSV data
      const results = Papa.parse(csv, { header: true });
      // Convert CSV power curve data to JSON format
      const powerCurve = results.data.map((row) => ({
        WS: parseFloat(row.WS) || 0, // Handle missing or invalid values by defaulting to 0
        Power: parseInt(row.Power, 10) || 0, // Parse "Power" as an integer
      }));

      // Set the power curve data using setPowerCurveData
      setPowerCurveData(powerCurve);
    })
    .catch((error) => {
      console.error("Error downloading CSV:", error);
    });
};

export const sendVektaWindAPIRequest = (
  combinedJSON,
  setVektaWindResponse,
  setLoadingStatus
) => {
  // // Define the URL of the API endpoint
  // const apiUrl = 'https://vekwin-hiibq2h6dq-ez.a.run.app/calculateYieldforVD';

  // // Define the options for the fetch request
  // const options = {
  //     method: 'POST',
  //     headers: apiHeaders,
  //     body: combinedJSON // Set the request body as the JSON string
  // };

  // // Send the POST request
  // fetch(apiUrl, options)
  //     .then(response => {
  //         // Check if the request was successful
  //         if (!response.ok) {
  //             setLoadingStatus({ loading: false, loadingMsg: "" })
  //             throw new Error('Network response was not ok');
  //         }
  //         // Parse the JSON response
  //         return response.json();
  //     })
  //     .then(data => {
  //         // Log the response data
  //         console.log('Response from the API:', data);

  //         console.log(data);

  //         setLoadingStatus({ loading: false, loadingMsg: "" })

  //         setVektaWindResponse(data);

  //     })
  //     .catch(error => {
  //         // Log any errors that occurred during the request
  //         console.error('Error sending POST request:', error);
  //         setLoadingStatus({ loading: false, loadingMsg: "" })
  //     });
  // Define the URL of the API endpoint
  // const apiUrl = 'https://vekwin-hiibq2h6dq-ez.a.run.app/calculateYieldforVD';
  //   const apiUrl = "https://europe-west1-vekta-functions-380610.cloudfunctions.net/yield_for_vd/YieldforVD";
  const apiUrl =
    "https://vekwin2-405606309488.us-central1.run.app/calculateYieldforVD";

  const settings = {
    uid: combinedJSON.uid,
    weatherSource: combinedJSON.weatherSource,
    hub: combinedJSON.hub,
    turbName: combinedJSON.turbName,
    deficitModel: combinedJSON.model,
    neighbours: combinedJSON.neighbours,
  };

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(
      //   combinedJSON
      convertToGeoJson(settings, combinedJSON.coords, "yield")
    ),
  })
    .then((response) => response.json())
    .then((data) => {
      data.coords = convertToOldSite(data.geometry);
      setLoadingStatus({ loading: false, loadingMsg: "" });
      setVektaWindResponse(data);
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const formatDataToGeoJSON = (data) => {
  return {
    type: "FeatureCollection",
    features: data.map((item) => ({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [item.lon, item.lat],
      },
      properties: {
        ids: item.ids,
      },
    })),
  };
};

export const callElevationAPI = (geojsonData, setSiteElevation) => {
  const url = "https://vekdig-hiibq2h6dq-nw.a.run.app/elevation";

  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(geojsonData),
  })
    .then((response) => response.json())
    .then((data) => {
      // Handle response and update data with elevation information if needed
      setSiteElevation(data);
      return true;
    })
    .catch((error) => {
      console.error("Error sending data:", error);
    });
};

export function roundToNearestHalf(number) {
  return Math.round(number * 2) / 2;
}

export function calculateMean(array, property) {
  if (array.length === 0) {
    return 0; // return 0 for an empty array, you may choose to handle this differently
  }

  var sum = 0;
  for (var i = 0; i < array.length; i++) {
    sum += array[i][property];
  }

  var mean = sum / array.length;
  return roundToNearestHalf(mean);
}

export const generateExcel = (meta, coords, site, triggerGoogleAnalytics) => {
  triggerGoogleAnalytics();
  const wb = XLSX.utils.book_new();

  if (!site.osp) {
    site.osp = [];
  }

  // Transpose the data to have latitudes and longitudes in separate rows
  //   const transposedData = [meta].reduce((acc, item, index) => {
  //     const keys = Object.keys(item);
  //     keys.forEach(key => {
  //         const values = Object.values(item[key]);
  //         values.forEach((value, valueIndex) => {
  //             if (!acc[valueIndex]) {
  //                 acc[valueIndex] = {};
  //             }
  //             if (valueIndex < 2) {
  //                 acc[valueIndex][`Column ${index + 1}`] = key;
  //             }
  //             acc[valueIndex][`Column ${index + 2}`] = value;
  //         });
  //     });
  //     return acc;
  // }, []);

  // TODO: ADD METADATA SHEET
  // Add meta data sheet
  // const wsMeta = XLSX.utils.json_to_sheet(transposedData);
  // XLSX.utils.book_append_sheet(wb, wsMeta, 'Meta');

  // Add 'coords' sheet
  const wsCoords = XLSX.utils.json_to_sheet([...coords.coords, ...site.osp]);
  XLSX.utils.book_append_sheet(wb, wsCoords, "Turbines");

  // Add 'cables' sheet
  // const wsCables = XLSX.utils.json_to_sheet(site.cables);
  let wsCables = XLSX.utils.aoa_to_sheet(
    [
      ["id", "Cable Nodes", "Length"], // Header row
    ].concat(
      site.cables.map(
        ({ id, geo, length }) => {
          let formatted = "";
          for (let i = 0; i < geo.length; i++) {
            const element = geo[i];
            formatted = formatted + `(${element.lat}, ${element.lng}), `;
          }
          return [id, formatted, length];
        } // [name, age, car[0], car[1], ... ]
      )
    )
  );
  XLSX.utils.book_append_sheet(wb, wsCables, "Cables");

  XLSX.writeFile(wb, "YieldData.xlsx");
};
