/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import React, { useContext, useEffect, useRef, useState } from 'react'
import Plot from 'react-plotly.js';
import Plotly from "plotly.js-basic-dist-min";
import { PlatformData } from '../../../imports/Context';

function WindRose({ data }) {
    const [windRose, setWindRose] = useState([])
    const plotRef = useRef();
    const { platformData, setPlatformData } = useContext(PlatformData)

    const colours = ["", 'rgb(168,225,255)', "rgb(118,204,255)", "rgb(64,181,255)", "rgb(0,157,255)", "rgb(0,130,255)", 'rgb(0,100,249)']

    const layout = {
        title: {
        text: "Wind Rose",
        font:{
         family: "Montserrat"
        }
        },
        autosize: true,
        font: { size: 13 },
        yaxis: {
            tickformat: '.2f', // Rounds to 2 decimal places (adjust the number as needed)
        },
        legend: {
            orientation: "h", font: { size: 13 },
            xanchor: "center",
            x: 0.5
            // x: 0.15, // Center horizontally (0 is left, 1 is right)
            // y: -0.2, // Center vertically (0 is bottom, 1 is top)
        },
        margin: {
            l: 50, // Adjust the left margin to create space for the legend
            r: 50, // Adjust the right margin to create space for the legend
            t: 75, // Adjust the top margin to create space for the legend
            b: 100, // Adjust the bottom margin to create space for the legend
        },
        plot_bgcolor: "rgba(0,0,0,0)",
        paper_bgcolor: "rgba(0,0,0,0)",
        polar: {
            barmode: "overlay",
            bargap: 0,
            radialaxis: { ticksuffix: "%", angle: 45, dtick: 20 },
            angularaxis: { direction: "clockwise" }
        },
        //width: window.innerWidth * plotSizeX,
        height: "500px"
    }

    const config = {
        responsive: true,
        toImageButtonOptions: {
            format: 'png', // one of png, svg, jpeg, webp
            filename: 'WindRose',
            height: 500,
            width: 700,
            scale: 1 // Multiply title/legend/axis/canvas sizes by this factor
        }
    };

    useEffect(() => {
        if (data && data[0]?.name.length === 1) {
            const processed = []

            for (let i = data.length - 1; i > -1; i--) {
                data[i].marker.color = colours[data[i].marker.color[0]]
                data[i].name = data[i].name.toString()
                data[i].type = data[i].type.toString()

                processed.push(data[i])
            }

            setPlatformData({ ...platformData, weatherAnalysis: { ...platformData.weatherAnalysis, windRoseData: { ...platformData.weatherAnalysis.windRoseData, WindRoseData: processed } } })
            setWindRose(processed)
        } else {
            setWindRose(data)
        }
    }, [data])

    return (
        <div>
            {/*  useResizeHandler */}
            <Plot useResizeHandler ref={plotRef} style={{ width: "100%", height: "500px" }} className='weatherAnalysis_windRose' data={windRose} layout={layout} config={config} />
        </div>
    )
}

export default WindRose