import { sendPasswordResetEmail } from "@firebase/auth";
import { Box, Modal } from "@mui/material";
import React from "react";
import { auth } from "../../firebase";
import { PrimeButton, SecButton } from "../../styles/styles";
import { InputText } from "primereact/inputtext";

function ChangePassword({ changePassword, setChangePassword, setEmailSent }) {
  return (
    <Modal
      open={changePassword}
      onClose={() => setChangePassword(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className="GIS_Loader"
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: "16px",
            position: "fixed",
            inset: "auto 35%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <div className="input">
            {/* <label>Account Email</label>
            <input
              style={{ color: "black" }}
              id="accountEmail"
              type="email"
              placeholder="name@example.com"
            /> */}
            <span
              className="p-float-label"
              style={{ width: "100%", marginBottom: "24px", height: "3rem" }}
            >
              <InputText
                id="forgotEmail"
                style={{ width: "100%", height: "3rem" }}
              />
              <label htmlFor="forgotEmail">Account Email</label>
            </span>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <SecButton style={{marginRight: "8px"}} onClick={() => setChangePassword(false)}>
              Cancel
            </SecButton>
            <PrimeButton
              onClick={() => {
                sendPasswordResetEmail(
                  auth,
                  document.getElementById("forgotEmail").value
                ).then(() => {
                  setChangePassword(false);
                  setEmailSent(true);
                });
              }}
            >
              Send Email
            </PrimeButton>
          </div>
        </Box>
      </div>
    </Modal>
  );
}

export default ChangePassword;
