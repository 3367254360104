import React, { useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";

function FaqHomeOne({ className, setDemoSelected }) {
  const [showQues, setQues] = useState(1);
  const openQuestion = (value) => {
    setQues(value);
  };

  const handleDownloadValidation = () => {
    // Get the download URL
    getDownloadURL(ref(storage, "validation_docs/CIGRE_report_v5.pdf"))
      .then((url) => {
        // Insert url into an <img> tag to "download"
        const a = document.createElement("a");

        a.href = url;
        a.download = "CIGRE-Validation.pdf";
        a.target = "_blank";
        a.click();
        a.remove();
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/object-not-found":
            // File doesn't exist
            break;
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            break;
          case "storage/canceled":
            // User canceled the upload
            break;

          // ...

          case "storage/unknown":
            // Unknown error occurred, inspect the server response
            break;
        }
      });
  };

  return (
    <>
      <section id="faqs" className={`appie-faq-area pb-95 pt-35 ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Frequently asked questions</h3>
                {/* <p>Different layouts and styles for team sections.</p> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(1)}
                    className={`accrodion ${showQues === 1 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          Do I need to download any software and are there
                          minimum system requirements?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 1 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            No you don't need to download anything, the software
                            is cloud based and will run on any browser on almost
                            any PC or MAC. We recommend that you only run the
                            tool on a laptop or desktop with the browser /
                            system zoom set to 100% or less as it's not yet
                            optimised for mobile use.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(5)}
                    className={`accrodion ${showQues === 5 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do secure my data and what happens when I delete
                          my account?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 5 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Our software is built using google cloud platform
                            and all the user data, login credentials and account
                            management are handled by google firebase which is a
                            widely trusted secure cloud platform. All the
                            platform data and APIs we use are encrypted and its
                            not possible for other users to see your project
                            data so you can create projects and scenarios
                            securely and privately. You can choose who you want
                            to share data with both internal and external to
                            your organisation.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(2)}
                    className={`accrodion ${showQues === 2 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do you handle bugs and feature requests?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 2 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            You can contact us either on our website or from
                            directly within the tool by using the help icon. You
                            are able to report a bug, submit a feature idea or
                            request a meeting. We welcome your feedback as we
                            work on a very open and collaborative basis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="faq-accordion wow fadeInRight mt-30"
                data-wow-duration="1500ms"
              >
                <div
                  className="accrodion-grp animated fadeIn faq-accrodion wow"
                  data-wow-duration="1500ms"
                  data-grp-name="faq-accrodion"
                >
                  <div
                    onClick={() => openQuestion(3)}
                    className={`accrodion ${showQues === 3 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          Do you provide references to all your data sources?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 3 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Yes, we provide a data file that shows all our
                            sources which you can download by clicking on the
                            help icon within the platform. Also, within the
                            layers widget if you hover on the map layer names
                            with your mouse Click it shows the individual layer
                            source.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(6)}
                    className={`accrodion ${showQues === 6 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do you calculate wind yield and what are your
                          assumptions?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 6 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            We use pywake NOJ deficit to run the AEP model for
                            the free version, however we will be including
                            additional models and options like Turbopark in VD+
                            along with a wider range of onshore and offshore
                            turbines from NREL and IEA.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => openQuestion(8)}
                    className={`accrodion ${showQues === 8 ? "active" : ""}`}
                  >
                    <div className="accrodion-inner">
                      <div className="accrodion-title">
                        <h4 style={{ width: "90%" }}>
                          How do I manage my license and can I buy new upgrades
                          when they are available?
                        </h4>
                      </div>
                      <div
                        className="accrodion-content"
                        style={{
                          display: showQues === 8 ? "block" : "none",
                        }}
                      >
                        <div className="inner">
                          <p>
                            Click on the my account icon within the tool to see
                            your account details and what subscription you have.
                            We offer a discount if you have a muti-user licence
                            depending on the user number brackets. We are
                            developing new modules for our software regularly
                            and you will be able to buy those as an upgrade
                            within the tool or part of a bundle. You can cancel
                            your license at any time and it will remain active
                            until the expiry date but we don't offer partial
                            refunds at this stage as per the license T&C's which
                            are available here.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="faq-text text-center pt-40">
                <p>
                  Can't find an answer?{" "}
                  <a
                    href="#"
                    style={{ color: "#00a0c6" }}
                    onClick={() => setDemoSelected(true)}
                  >
                    Contact us
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FaqHomeOne;
