import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { formatCreateInput, handleSave, handleShare } from "../helperFunctions";

import UserIcons from "../../common/UserIcons";

import { Box, Divider, Modal } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CreatableSelect from "react-select/creatable";
// import Modal from "react-modal";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../firebase";
import { fetchSignInMethodsForEmail } from "firebase/auth";

import { Steps } from "primereact/steps";

import {
  Footer,
  ModalClose,
  PrimeButton,
  Row8pxGap,
  SecButton,
  SelectionButton,
  StyledIconButton,
} from "../../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faAnglesRight,
  faChevronLeft,
  faChevronRight,
  faGreaterThan,
} from "@fortawesome/free-solid-svg-icons";

const StandardContainerColumn = styled("div")`
  height: 50%;
  width: 100%;

  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StandardContainerRow = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px;
`;

const UserSelectCard = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;

  .userEmail {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;

    &:hover {
      overflow: visible;
    }
  }
`;

const InfoPrompt = styled("span")`
  width: 95%;
  height: 30px;
  padding: 8px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.success ? "rgba(0, 196, 84, 1)" : "rgb(206, 69, 69)"};

  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepPage = styled(StandardContainerColumn)`
  height: 100%;

  margin-bottom: 16px;
`;

function SharePopup({
  sharePop,
  setSharePop,
  shareSettings,
  setShareSettings,
  shareGroup,
  setSaveLoadShare,
  setSharedWith,
}) {
  const [user, loading, error] = useAuthState(auth);
  const [externalEmail, setExternalEmail] = useState([]);
  const [promptStatus, setPromptStatus] = useState({
    success: false,
    message: "",
  });
  const [validEmail, setValidEmail] = useState(false);

  const [projectScenarios, setProjectScenarios] = useState([]);
  const [shareScenarios, setShareScenarios] = useState([]);
  const [scenariosSelected, setScenariosSelected] = useState({
    project: [],
    share: [],
  });
  const [activeIndex, setActiveIndex] = useState(0);

  const [selectedTeam, setSelectedTeam] = useState({
    label: "Whole Company",
    value: "whole",
  });
  const defaultTeams = [
    { label: "Whole Company", value: "whole" },
    { label: "Electrical", value: "electrical" },
    { label: "GIS", value: "gis" },
    { label: "O&M", value: "om" },
    { label: "Project Management", value: "pm" },
  ];

  const shareSteps = [
    {
      label: "User Selection",
    },
    {
      label: "Scenarios",
    },
    {
      label: "Comments & Share",
    },
  ];

  useEffect(() => {
    if (validEmail) {
      setTimeout(() => {
        setValidEmail(false);
      }, 5000);
    }
  }, [validEmail]);

  useEffect(() => {
    if (sharePop.key) {
      setProjectScenarios(sharePop?.key?.scenario?.ids);
    }
  }, [sharePop.key]);

  const handleEmailValidation = (e) => {
    setValidEmail(false);
    setPromptStatus({ success: false, message: "" });

    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (e.match(validRegex)) {
      fetchSignInMethodsForEmail(auth, e).then((res) => {
        if (res.length > 0) {
          setValidEmail(true);
          setPromptStatus({
            success: true,
            message: "Valid VD User - Email added to share list",
          });
          if (!externalEmail.includes(e))
            setExternalEmail((externalEmail) => [...externalEmail, e]);
        } else {
          setValidEmail(true);
          setPromptStatus({
            success: false,
            message: "Invalid VD User - Try again",
          });
        }
      });
    } else {
      setValidEmail(true);
      setPromptStatus({ success: false, message: "Invalid Email - Try again" });
    }
  };

  const handlePermissionShare = (e, type, opposite) => {
    if (e.target.checked) {
      const temp = shareSettings.users;
      temp[e.target.value].permissions = type;

      setShareSettings((shareSettings) => ({
        ...shareSettings,
        users: temp,
      }));
    } else {
      const temp = shareSettings.users;
      temp[e.target.value].permissions = opposite;

      setShareSettings((shareSettings) => ({
        ...shareSettings,
        users: temp,
      }));
    }
  };

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
  };

  const handleScenarioSelection = (scenario, type) => {
    setScenariosSelected((scenariosSelected) => ({
      ...scenariosSelected,
      [type]:
        scenariosSelected[type].indexOf(scenario) === -1
          ? [...scenariosSelected[type], scenario]
          : scenariosSelected[type].map((item) => item !== scenario),
    }));
  };

  const handleScenarioMove = (type) => {
    switch (type) {
      case "add":
        const tempSelected = [...scenariosSelected.project];
        const tempProject = [...projectScenarios];
        for (let i = 0; i < scenariosSelected.project.length; i++) {
          const scenario = scenariosSelected.project[i];

          if (tempProject.indexOf(scenario) > -1)
            tempProject.splice(tempProject.indexOf(scenario), 1);
          if (shareScenarios.indexOf(scenario) > -1)
            tempSelected.splice(tempSelected.indexOf(scenario), 1);
        }

        // Add the selected scenarios to share
        setProjectScenarios(tempProject);
        setShareScenarios((shareScenarios) => [
          ...shareScenarios,
          ...tempSelected,
        ]);
        setScenariosSelected((scenariosSelected) => ({
          ...scenariosSelected,
          project: [],
        }));
        break;
      case "addAll":
        // Make the share scenario match the project scenarios
        setShareScenarios(sharePop?.key?.scenario?.ids);
        setProjectScenarios([]);
        break;
      case "removeAll":
        // Remove all share scenarios
        setShareScenarios([]);
        setProjectScenarios(sharePop?.key?.scenario?.ids);
        break;
      case "remove":
        // Remove the selected scenarios from share
        const tempShare = [...shareScenarios];
        for (let i = 0; i < scenariosSelected.share.length; i++) {
          const scenario = scenariosSelected.share[i];

          tempShare.splice(tempShare.indexOf(scenario), 1);
        }

        setProjectScenarios((projectScenarios) => [
          ...projectScenarios,
          ...scenariosSelected.share,
        ]);
        setShareScenarios(tempShare);
        setScenariosSelected((scenariosSelected) => ({
          ...scenariosSelected,
          share: [],
        }));
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      open={sharePop.open}
      onClose={() => {
        setSharePop((sharePop) => ({ ...sharePop, open: false }));
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        className="shareWith_internalPopup"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: 2,
            position: "fixed",
            inset: "15% 20%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <header style={{ display: "flex", justifyContent: "space-between" }}>
            <Steps
              style={{ width: "90%" }}
              model={shareSteps}
              activeIndex={activeIndex}
            />

            <ModalClose
              onClick={() => {
                setProjectScenarios([]);
                setShareScenarios([]);
                setActiveIndex(0);
                setSharePop({ open: false, key: null });
              }}
            >
              X
            </ModalClose>
          </header>

          <body style={{ height: "100%" }}>
            {activeIndex === 0 && (
              <StepPage className="stepPage">
                <StandardContainerRow style={{ height: "100%" }}>
                  <StandardContainerColumn style={{ height: "100%" }}>
                    <h3>Teams</h3>
                    <StandardContainerColumn
                      style={{ gap: "8px", height: "100%" }}
                    >
                      {defaultTeams.map((name) => {
                        return (
                          <SecButton
                            style={{ width: "100%" }}
                            active={selectedTeam.value === name.value}
                            onClick={() => handleTeamSelect(name)}
                          >
                            {name.label}
                          </SecButton>
                        );
                      })}
                    </StandardContainerColumn>
                  </StandardContainerColumn>

                  <Divider orientation="vertical" flexItem />

                  <StandardContainerColumn
                    id="share_popup"
                    style={{ height: "100%", padding: "8px" }}
                  >
                    <div className="selectedUsers">
                      <StandardContainerRow>
                        <h3>
                          Users Selected{" "}
                          <span style={{ fontSize: "medium" }}>
                            {Object.keys(shareSettings.users).length} of{" "}
                            {shareGroup[selectedTeam.value].length - 1}
                          </span>
                        </h3>
                        {/* <button
                                                title="Share With All"
                                                onClick={() => {
                                                    const users = [];
                                                    shareGroup.forEach((user) => {
                                                        users.push(user.id);
                                                    });
                                                    setShareSettings(users);
                                                }}
                                            >
                                                Select All
                                            </button> */}
                      </StandardContainerRow>
                      <UserIcons
                        list={shareSettings.users}
                        setShareSettingsPop={null}
                        status={true}
                        limit={10}
                        setSharedWith={setSharedWith}
                      />
                    </div>

                    <StandardContainerColumn className="sharableUsers_list">
                      <h4>{selectedTeam.label} Team</h4>
                      {shareGroup[selectedTeam.value].map((shared) => {
                        // shared.name !== user?.email &&
                        return (
                          <div style={{ width: "100%" }}>
                            <UserSelectCard>
                              <Avatar
                                style={{
                                  backgroundColor: "#009ec6",
                                  color: "#ffffff",
                                  fontWeight: "600",
                                  fontFamily: "Montserrat",
                                }}
                              >
                                {shared.name.charAt(0).toUpperCase()}
                              </Avatar>
                              <label
                                className="userEmail"
                                style={{ margin: "0" }}
                              >
                                {shared.name}
                              </label>
                              <div>
                                <Checkbox
                                  value={shared.id}
                                  title="Select"
                                  aria-label="Select"
                                  icon={
                                    <RadioButtonUncheckedIcon fontSize="large" />
                                  }
                                  checkedIcon={
                                    <RadioButtonCheckedIcon
                                      fontSize="large"
                                      color="#009ec6"
                                    />
                                  }
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setShareSettings((shareSettings) => ({
                                        ...shareSettings,
                                        users: {
                                          ...shareSettings.users,
                                          [e.target.value]: {
                                            permissions: "viewer",
                                            scenarios: [],
                                          },
                                        },
                                      }));
                                    } else {
                                      const temp = shareSettings.users;
                                      delete temp[e.target.value];

                                      setShareSettings((shareSettings) => ({
                                        ...shareSettings,
                                        users: temp,
                                      }));
                                    }
                                  }}
                                />
                                <Checkbox
                                  title="Viewer"
                                  value={shared.id}
                                  aria-label="viewer"
                                  icon={
                                    <RemoveRedEyeOutlinedIcon fontSize="large" />
                                  }
                                  checkedIcon={
                                    <RemoveRedEyeIcon
                                      fontSize="large"
                                      color="#009ec6"
                                    />
                                  }
                                  onChange={(e) => {
                                    handlePermissionShare(
                                      e,
                                      "viewer",
                                      "editor"
                                    );
                                  }}
                                  checked={
                                    shareSettings.users[shared.id]
                                      ?.permissions === "viewer"
                                  }
                                />
                                <Checkbox
                                  title="Editor"
                                  value={shared.id}
                                  aria-label="editor"
                                  icon={<EditOutlinedIcon fontSize="large" />}
                                  checkedIcon={
                                    <EditIcon
                                      fontSize="large"
                                      color="#009ec6"
                                    />
                                  }
                                  onChange={(e) => {
                                    handlePermissionShare(
                                      e,
                                      "editor",
                                      "viewer"
                                    );
                                  }}
                                  checked={
                                    shareSettings.users[shared.id]
                                      ?.permissions === "editor"
                                  }
                                />
                              </div>
                            </UserSelectCard>
                            <Divider />
                          </div>
                        );
                      })}
                    </StandardContainerColumn>

                    <div className="externalShare">
                      {validEmail && (
                        <InfoPrompt success={promptStatus.success}>
                          {promptStatus.message}
                        </InfoPrompt>
                      )}

                      <div className="input" style={{ margin: "16px 0" }}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <label className="labelInput">
                            Add External User
                          </label>
                          <span title="A user that is not apart of your internal company group">
                            ?
                          </span>
                        </div>
                        <CreatableSelect
                          isClearable
                          value={formatCreateInput(externalEmail)}
                          isMulti
                          menuPosition="fixed"
                          menuPortalTarget={document.getElementById(
                            "share_popup"
                          )}
                          placeholder={"External Email"}
                          onChange={(e) => {
                            const emails = [];
                            e.forEach((email) => {
                              emails.push(email.value);
                            });

                            setExternalEmail(emails);
                          }}
                          onCreateOption={(e) => {
                            handleEmailValidation(e);
                          }}
                        />
                      </div>
                    </div>
                  </StandardContainerColumn>
                </StandardContainerRow>

                <Footer>
                  <div></div>
                  <Row8pxGap>
                    <PrimeButton onClick={() => setActiveIndex(1)}>
                      Next
                    </PrimeButton>
                  </Row8pxGap>
                </Footer>
              </StepPage>
            )}

            {activeIndex === 1 && (
              <StepPage>
                <StandardContainerRow style={{ height: "100%" }}>
                  <StandardContainerColumn style={{ height: "100%" }}>
                    <h3>Project Scenarios</h3>
                    <StandardContainerColumn style={{ gap: "8px" }}>
                      {projectScenarios.map((scenario) => {
                        return (
                          <SecButton
                            active={scenariosSelected.project.find(
                              (saved) => saved === scenario
                            )}
                            style={{ width: "100%" }}
                            className="navButton"
                            onClick={() =>
                              handleScenarioSelection(scenario, "project")
                            }
                          >
                            {scenario}
                          </SecButton>
                        );
                      })}
                    </StandardContainerColumn>
                  </StandardContainerColumn>

                  <StandardContainerColumn
                    style={{
                      height: "100%",
                      justifyContent: "center",
                      gap: "8px",
                    }}
                  >
                    <StyledIconButton
                      title="Add Selected Scenario"
                      onClick={() => handleScenarioMove("add")}
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </StyledIconButton>
                    <StyledIconButton
                      title="Add All Project Scenarios"
                      onClick={() => handleScenarioMove("addAll")}
                    >
                      <FontAwesomeIcon icon={faAnglesRight} />
                    </StyledIconButton>
                    <StyledIconButton
                      title="Remove All Share Scenarios"
                      onClick={() => handleScenarioMove("removeAll")}
                    >
                      <FontAwesomeIcon icon={faAnglesLeft} />
                    </StyledIconButton>
                    <StyledIconButton
                      title="Remove Selected Scenarios"
                      onClick={() => handleScenarioMove("remove")}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </StyledIconButton>
                  </StandardContainerColumn>

                  <StandardContainerColumn style={{ height: "100%" }}>
                    <h3>Scenarios to Share</h3>
                    <StandardContainerColumn style={{ gap: "8px" }}>
                      {shareScenarios.map((scenario) => {
                        return (
                          <SecButton
                            active={scenariosSelected.share.find(
                              (saved) => saved === scenario
                            )}
                            style={{ width: "100%" }}
                            className="navButton"
                            onClick={() =>
                              handleScenarioSelection(scenario, "share")
                            }
                          >
                            {scenario}
                          </SecButton>
                        );
                      })}
                    </StandardContainerColumn>
                  </StandardContainerColumn>
                </StandardContainerRow>

                <Footer>
                  <div></div>
                  <Row8pxGap>
                    <SecButton onClick={() => setActiveIndex(0)}>
                      Back
                    </SecButton>
                    <PrimeButton onClick={() => setActiveIndex(2)}>
                      Next
                    </PrimeButton>
                  </Row8pxGap>
                </Footer>
              </StepPage>
            )}

            {activeIndex === 2 && (
              <StepPage>
                <div style={{ height: "100%" }} className="input">
                  <label>Add a Comment</label>
                  <textarea
                    onChange={(e) =>
                      setShareSettings((shareSettings) => ({
                        ...shareSettings,
                        comment: e.target.value,
                      }))
                    }
                    rows="4"
                    cols="50"
                  ></textarea>
                </div>

                <Footer>
                  <div></div>
                  <Row8pxGap>
                    <SecButton onClick={() => setActiveIndex(1)}>
                      Back
                    </SecButton>
                    <PrimeButton
                      onClick={async () => {
                        setSaveLoadShare(true);
                        setPromptStatus(
                          await handleShare(
                            user,
                            shareSettings,
                            sharePop.key,
                            externalEmail,
                            shareScenarios
                          )
                        );
                        setSharePop({ open: false, key: null });
                        setShareSettings({
                          users: {},
                          comment: null,
                          shareType: "Master",
                          projID: null,
                        });
                        setExternalEmail([]);
                        setProjectScenarios([]);
                        setShareScenarios([]);
                        setActiveIndex(0);
                      }}
                    >
                      Share
                    </PrimeButton>
                  </Row8pxGap>
                </Footer>
              </StepPage>
            )}
          </body>

          {/* <div className="sharableUsers">
                <div className="selectedUsers">
                    <StandardContainerRow>
                        <h3>Users Selected <span style={{ fontSize: "medium" }}>{Object.keys(shareSettings.users).length} of {shareGroup.length - 1}</span></h3>
                        <button
                            title="Share With All"
                            onClick={() => {
                                const users = [];
                                shareGroup.forEach((user) => {
                                    users.push(user.id);
                                });
                                setShareSettings(users);
                            }}
                        >
                            Select All
                        </button>
                    </StandardContainerRow>
                    <UserIcons list={shareSettings.users} setShareSettingsPop={null} status={true} limit={10} setSharedWith={setSharedWith} />
                </div>

                <StandardContainerColumn
                    className="sharableUsers_list"
                >
                    <h4>Company Group</h4>
                    {shareGroup.map((shared) => {
                        return shared.name !== user?.email && (
                            <div style={{ width: "100%" }}>
                                <UserSelectCard>
                                    <Avatar style={{ backgroundColor: '#009ec6', color: '#ffffff', fontWeight: "600", fontFamily: "Montserrat" }}>
                                        {shared.name.charAt(0).toUpperCase()}
                                    </Avatar>
                                    <label className="userEmail" style={{ margin: "0" }}>{shared.name}</label>
                                    <div>
                                        <Checkbox value={shared.id} title="Select" aria-label="Select"
                                            icon={<RadioButtonUncheckedIcon fontSize="large" />}
                                            checkedIcon={<RadioButtonCheckedIcon fontSize="large" color="#009ec6" />}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setShareSettings((shareSettings) => ({
                                                        ...shareSettings,
                                                        users: { ...shareSettings.users, [e.target.value]: "viewer" },
                                                    }));
                                                } else {
                                                    const temp = shareSettings.users;
                                                    delete temp[e.target.value];

                                                    setShareSettings((shareSettings) => ({
                                                        ...shareSettings,
                                                        users: temp,
                                                    }));
                                                }
                                            }}
                                        />
                                        <Checkbox title="Viewer" value={shared.id} aria-label="viewer"
                                            icon={<RemoveRedEyeOutlinedIcon fontSize="large" />}
                                            checkedIcon={<RemoveRedEyeIcon fontSize="large" color="#009ec6" />}
                                            onChange={(e) => {
                                                handlePermissionShare(e, "viewer", "editor")
                                            }}
                                            checked={shareSettings.users[shared.id] === "viewer"}
                                        />
                                        <Checkbox title="Editor" value={shared.id} aria-label="editor"
                                            icon={<EditOutlinedIcon fontSize="large" />}
                                            checkedIcon={<EditIcon fontSize="large" color="#009ec6" />}
                                            onChange={(e) => {
                                                handlePermissionShare(e, "editor", "viewer")
                                            }}
                                            checked={shareSettings.users[shared.id] === "editor"}
                                        />
                                    </div>
                                </UserSelectCard>
                                <Divider />
                            </div>
                        )
                    })}
                </StandardContainerColumn>

                <div className="externalShare">
                    {validEmail && <InfoPrompt success={promptStatus.success} >{promptStatus.message}</InfoPrompt>}

                    <div className="input" style={{ margin: "16px 0" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                            <label className="labelInput">Add External User</label>
                            <span title="A user that is not apart of your internal company group">?</span>
                        </div>
                        <CreatableSelect
                            isClearable
                            value={formatCreateInput(externalEmail)}
                            isMulti
                            placeholder={"External Email"}
                            onChange={(e) => {
                                const emails = []
                                e.forEach((email) => {
                                    emails.push(email.value)
                                })

                                setExternalEmail(emails)
                            }}
                            onCreateOption={(e) => { handleEmailValidation(e) }}
                        />
                    </div>
                </div>
            </div>

            <Divider orientation="vertical" flexItem />

            <div className="shareOptions">
                <div className="input">
                    <label>Share As</label>
                    <select
                        onChange={(e) =>
                            setShareSettings((shareSettings) => ({
                                ...shareSettings,
                                shareType: e.target.value,
                            }))
                        }
                    >
                        <option value={"Master"}>Master</option>
                        <option value={"Provisional"}>Provisional</option>
                    </select>
                </div>

                <div className="input">
                    <label>Add a Comment</label>
                    <textarea
                        onChange={(e) =>
                            setShareSettings((shareSettings) => ({
                                ...shareSettings,
                                comment: e.target.value,
                            }))
                        }
                        rows="4"
                        cols="50"
                    ></textarea>
                </div>
            </div> */}
        </Box>
      </div>
    </Modal>
  );
}

export default SharePopup;
