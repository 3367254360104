import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserMinus as ShareRemoveControl,
  faShareFromSquare as ShareControl,
  faAnglesRight as CommentControl,
  faShareNodes as ShareIcon,
  faEnvelope as InviteIcon,
  faFolder as PersonalIcon,
  faFolderPlus,
  faArrowsRotate,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCircleCheck as AcceptControl,
  faCircleXmark as DeclineControl,
  faFolderOpen as OpenControl,
} from "@fortawesome/free-regular-svg-icons";

import Scenario from "./Scenario";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { Chips } from "primereact/chips";

import { RadioButton } from "primereact/radiobutton";

import { auth, db, app } from "../../../../firebase";
import {
  doc,
  setDoc,
  onSnapshot,
  getDoc,
  updateDoc,
  collection,
  deleteDoc,
  arrayRemove,
} from "firebase/firestore";
import { PlatformData } from "../../../../imports/Context";
import { useAuthState } from "react-firebase-hooks/auth";

import DataTable from "react-data-table-component";
import SettingsMenu from "../../common/SettingsMenu";
import { getFunctions, httpsCallable } from "firebase/functions";
import { formatCreateInput, handleSave, handleShare } from "../helperFunctions";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import UserIcons from "../../common/UserIcons";

import SharePopup from "./Sharepopup";

import {
  Box,
  Divider,
  Modal,
  Checkbox,
  Badge,
  Avatar,
  Tooltip,
} from "@mui/material";
import {
  ModalClose,
  PrimeButton,
  Row8pxGap,
  SecButton,
} from "../../../../styles/styles";
import NewProject from "./NewProject";
import CommentsSheet from "./CommentsSheet";
import ShareSettings from "./ShareSettings";

// Provided from MUI
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#9feed6",
    color: "#9feed6",
    boxShadow: `0 0 0 2px #009ec6`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledIconButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  margin: 6px;

  &:hover {
    background-color: rgba(0, 158, 198, 0.255) !important;
    transform: scale(1.1);
  }
`;

const StandardContainerColumn = styled("div")`
  height: 50%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StandardContainerRow = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserSelectCard = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 8px 0;

  .userEmail {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;

    &:hover {
      overflow: visible;
    }
  }
`;

const InfoPrompt = styled("span")`
  width: 95%;
  height: 30px;
  padding: 8px;
  border-radius: 8px;
  color: white;
  background-color: ${(props) =>
    props.success ? "rgba(0, 196, 84, 1)" : "rgb(206, 69, 69)"};

  display: flex;
  align-items: center;
  justify-content: center;
`;

function SavePopup({
  saveLoad,
  setSaveLoad,
  projectIds,
  allSaves,
  setAllSaves,
  userAccount,
  userSaves,
  setUserSaves,
  externalInvites,
  scenarioSnap,
  setScenarioSnap,
  recentOpened,
  setRefresh,
}) {
  const [user, loading, error] = useAuthState(auth);
  const { platformData, setPlatformData } = useContext(PlatformData);

  const [activeSection, setActiveSection] = useState("personal");

  const [sharedStatus, setSharedStatus] = useState({ shared: true, users: {} });

  const [sharedProjects, setSharedProjects] = useState([]);
  const [savedProjects, setSavedProjects] = useState([]);
  const [sharedWith, setSharedWith] = useState({ all: [], selected: [] });
  const [sharePop, setSharePop] = useState({ open: false, key: null });
  const [shareSettingsPop, setShareSettingsPop] = useState(false);
  const [commentPop, setCommentPop] = useState({
    open: false,
    comments: [],
    scenario: null,
    project: null,
    snapshot: null,
  });

  const [saveNew, setSaveNew] = useState(false);

  const [shareGroup, setShareGroup] = useState({
    whole: [],
    electrical: [],
    gis: [],
    om: [],
    pm: [],
  });
  const [shareSettings, setShareSettings] = useState({
    users: {},
    comment: null,
    shareType: "Master",
    projID: null,
  });

  const [promptStatus, setPromptStatus] = useState({
    success: true,
    message: "",
  });
  const [saveLoadShare, setSaveLoadShare] = useState(false);

  var date = new Date();
  const dateTime =
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " @ " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();

  const subCollection = async (location, key) => {
    let docRef = doc(db, "userSaves", user.uid, location, key.id);
    let shared = key.sharedStatus;

    if (key.proj) {
      docRef = key.proj;
    }

    if (shared === undefined) {
      const share = (await getDoc(docRef)).data();

      shared = share.sharedStatus;
    }

    const getScenarioInfo = (snap, share) => {
      // Resetting useStatue to combat duplication
      setAllSaves({
        pName: [
          { label: null, options: [] },
          { label: null, options: [] },
        ],
        sName: [
          { label: null, options: [] },
          { label: null, options: [] },
        ],
      });
      const info = {
        ids: [],
        dates: [],
        createdBy: [],
        tags: [],
        baseScenario: [],
        locked: [],
        hidden: [],
      };

      snap.forEach((scn) => {
        // Only giving access to the scenarios a user has permission
        if (
          location === "shared" &&
          share.users[user.uid]?.scenarios.indexOf(scn.id) === -1
        )
          return;
        // Only showing the scenarios that are not hidden by the owner
        if (location === "shared" && scn.data().hidden) return;

        // Add project scenario info for the sub tables in the popup
        info.ids.push(scn.id);
        info.dates.push(scn.data().lastSaved);
        info.createdBy.push(scn.data().createdBy);
        info.tags.push(scn.data().tags);
        info.baseScenario.push(scn.data().base);
        info.locked.push(scn.data().locked);
        info.hidden.push(scn.data().hidden);
      });

      setUserSaves((userSaves) => ({
        ...userSaves,
        [location]: {
          ...userSaves[location],
          [key.id]: {
            scn: info,
            proj: docRef,
            ...key,
          },
        },
      }));

      setScenarioSnap((scenarioSnap) => ({
        ...scenarioSnap,
        [key.id]: scenarios,
      }));
    };

    /**
     * Observing the scenarios collection within each given project for changes. These are saved
     * to update the sub tables in the project
     */
    const scenarios = onSnapshot(collection(docRef, "scenarios"), (snap) => {
      if (location === "shared") {
        let share = shared;
        getDoc(docRef).then((data) => {
          share = data.data().sharedStatus;

          getScenarioInfo(snap, share);
        });
      } else {
        getScenarioInfo(snap, shared);
      }
    });
  };

  useEffect(() => {
    setSharedWith({ all: [], selected: [] });

    projectIds.personal.map((key) => {
      subCollection("personal", key);

      Object.keys(key.sharedStatus.users).forEach(async (element) => {
        // getDoc(doc(db, "userAccounts", element.id)).then((info) => {
        const functions = getFunctions(app, "us-central1");
        const info = httpsCallable(functions, "getUserInfo");

        info({ uid: element })
          .then((result) => {
            // Read result of the Cloud Function.
            setSharedWith((sharedWith) => ({
              ...sharedWith,
              all: [
                ...sharedWith.all,
                {
                  id: element,
                  projName: key.id,
                  email: result.data.email,
                  permissions: key.sharedStatus.users[element].permissions,
                },
              ],
            }));
          })
          .catch((error) => {
            // Getting the Error details.
            console.log(error.code);
          });
        // })
      });
    });

    projectIds.shared.map((key) => {
      subCollection("shared", key);
    });
  }, [projectIds]);

  useEffect(() => {
    const getCompanyUsers = async () => {

      for (let i = 0; i < userAccount?.fileData?.CID?.length; i++) {

        const companyUsers = await getDoc(
          doc(db, "company", userAccount.fileData.CID[i])
        );
        const users = companyUsers.data() ? companyUsers.data().users : [];

        users.forEach(async (companyUser) => {
          const id = companyUser?.userRef?.id;
          const functions = getFunctions(app, "us-central1");
          const info = httpsCallable(functions, "getUserInfo");

          info({ uid: id })
            .then((result) => {
              // Read result of the Cloud Function.
              for (let i = 0; i < companyUser.teams.length; i++) {
                const team = companyUser.teams[i].value;
                setShareGroup((shareGroup) => {
                  return shareGroup[team].findIndex(
                    (item) => item.id === id
                  ) === -1
                    ? {
                        ...shareGroup,
                        [team]: [
                          ...shareGroup[team],
                          { id: id, name: result.data.email },
                        ],
                      }
                    : { ...shareGroup, [team]: [...shareGroup[team]] };
                });
                // shareUsers[team] = [
                //   ...shareUsers[team],
                //   { id: id, name: result.data.email },
                // ];
              }
            })
            .catch((error) => {
              // Getting the Error details.
              console.log(error.code);
            });
        });
      }
    };

    getCompanyUsers();
  }, [userAccount.fileData, sharePop.open]);

  useEffect(() => {
    const personalOptions = {
      projects: allSaves.pName[0].options,
      scenarios: allSaves.sName[0].options,
    };
    const sharedOptions = {
      projects: allSaves.pName[1].options,
      scenarios: allSaves.sName[1].options,
    };
    /**
     * Formatting the save information for the Data Tables
     */
    setSavedProjects(
      Object.keys(userSaves.personal).map((key, i) => {
        personalOptions.projects.push(...formatCreateInput([key]));
        personalOptions.scenarios.push(
          ...formatCreateInput(userSaves?.personal[key]?.scn.ids)
        );

        if (
          !projectIds.personal[
            projectIds.personal.findIndex(
              (element) => element.projID === userSaves.personal[key].projID
            )
          ]?.sharedStatus?.shared
        )
          setSharedStatus((sharedStatus) => ({
            ...sharedStatus,
            shared: false,
          }));

        return {
          id: userSaves.personal[key].projID,
          name: key,
          // tags: <Chips pt={{
          //   container: { style: { display: "flex", alignItems: "center", maxHeight: "50px", background: "none", border: "none" } },
          //   token: { style: { border: "2px solid #00a0c6", background: "none" } }
          // }} max={3} readOnly value={userSaves.personal[key].tags} />,
          scenario: userSaves.personal[key].scn,
          createdBy:
            user.email === userSaves.personal[key].createdBy
              ? "Me"
              : userSaves.personal[key].createdBy,
          shared: (
            <UserIcons
              list={
                projectIds.personal[
                  projectIds.personal.findIndex(
                    (element) =>
                      element.projID === userSaves.personal[key].projID
                  )
                ]?.sharedStatus?.users
                  ? projectIds.personal[
                      projectIds.personal.findIndex(
                        (element) =>
                          element.projID === userSaves.personal[key].projID
                      )
                    ]?.sharedStatus?.users
                  : []
              }
              setShareSettingsPop={setShareSettingsPop}
              status={
                projectIds.personal[
                  projectIds.personal.findIndex(
                    (element) =>
                      element.projID === userSaves.personal[key].projID
                  )
                ]?.sharedStatus.shared
              }
              limit={4}
              setSharedWith={setSharedWith}
              projName={key}
              scenarios={userSaves.personal[key].scn}
            />
          ),
          ref: userSaves.personal[key].proj,
        };
      })
    );

    setSharedProjects(
      Object.keys(userSaves.shared).map((key, i) => {
        sharedOptions.projects.push(...formatCreateInput([key]));
        sharedOptions.scenarios.push(
          ...formatCreateInput(userSaves?.shared[key]?.scn.ids)
        );

        const permissions =
          userSaves.shared[key]?.sharedStatus?.users[user.uid];

        return {
          id: i,
          name: key,
          scenario: userSaves.shared[key].scn,
          sharedBy:
            user.email === userSaves.shared[key].createdBy
              ? "Me"
              : userSaves.shared[key].createdBy,
          permissions: permissions?.permissions,
          ref: userSaves.shared[key].proj,
        };
      })
    );

    setAllSaves({
      pName: [
        { label: "Personal Projects", options: personalOptions.projects },
        { label: "Shared Projects", options: sharedOptions.projects },
      ],
      sName: [
        { label: "Personal Scenarios", options: personalOptions.scenarios },
        { label: "Shared Scenarios", options: sharedOptions.scenarios },
      ],
    });
  }, [userSaves]);

  useEffect(() => {
    if (saveLoadShare) {
      setTimeout(() => {
        setSaveLoadShare(false);
      }, 5000);
    }
  }, [saveLoadShare]);

  const handleDelete = (row) => {
    const functions = getFunctions(app, "us-central1");
    const fullDelete = httpsCallable(functions, "deleteProject");

    Object.keys(scenarioSnap).map((key) => {
      scenarioSnap[key]();
    });

    let location = "personal";
    if (projectIds.shared.includes(row.name)) {
      location = "shared";
    }

    fullDelete({ location: location, project: row.name })
      .then((result) => {
        // Read result of the Cloud Function.
        setPromptStatus({
          success: true,
          message: "Project Successfully Delete - Removing from table . . .",
        });
        setSaveLoadShare(result.data);
      })
      .catch((error) => {
        // Getting the Error details.
        console.log(error.code);
      });
  };

  const handleAcceptance = (invite) => {
    const functions = getFunctions(app, "us-central1");
    const accept = httpsCallable(functions, "inviteAccepted");

    accept({ projID: invite.projID, scenarios: invite.scenarios })
      .then((result) => {
        // Read result of the Cloud Function.
        // console.log(result.data);
      })
      .catch((error) => {
        // Getting the Error details.
        console.log(error.code);
      });

    handleDecline(invite);
  };

  const handleDecline = (invite) => {
    deleteDoc(doc(db, "userAccounts", user.uid, "invites", invite.id));
  };

  const savedTableColumns = [
    {
      name: "Project Name",
      selector: (row) => row.name,
      sortable: true,
      grow: 2,
      style: {
        fontSize: "14px",
        fontWeight: 600,
      },
    },
    // {
    //   name: "Tags",
    //   selector: (row) => row.tags,
    //   sortable: true,
    //   grow: 2,
    // },
    ...(null
      ? [
          {
            name: "Owner",
            selector: (row) => row.createdBy,
            sortable: true,
          },
          {
            name: "Shared",
            selector: (row) => row.shared,
            omit: sharedStatus.shared,
          },
        ]
      : []),
    {
      cell: (row) => (
        <div title="Purchase Vekta Digital + to access">
          <StyledIconButton
            disabled
            title="Purchase Vekta Digital + to access"
            onClick={() => setSharePop({ open: true, key: row })}
          >
            <FontAwesomeIcon size="xs" icon={ShareControl} />
          </StyledIconButton>
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },

    {
      cell: (row) => <SettingsMenu row={row} onDeleteRow={handleDelete} />,
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const sharedTableColumns = [
    {
      name: "Project Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Last Updated",
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: "Shared By",
      selector: (row) => row.sharedBy,
      sortable: true,
    },
    {
      name: "Permissions",
      selector: (row) => row.permissions,
      style: {
        textTransform: "capitalize",
      },
    },
  ];

  const inviteTableColumns = [
    {
      name: "Project Name",
      selector: (row) => row.projName,
      sortable: true,
    },
    {
      name: "Invite From",
      selector: (row) => row.from,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      cell: (row) => (
        <StyledIconButton
          title="Accept Invite"
          onClick={() => handleAcceptance(row)}
        >
          <FontAwesomeIcon size="xs" color="#63E6BE" icon={AcceptControl} />
        </StyledIconButton>
      ),
      allowOverflow: true,
      button: true,
      width: "100px",
    },
    {
      cell: (row) => (
        <StyledIconButton
          title="Decline Invite"
          onClick={() => handleDecline(row)}
        >
          <FontAwesomeIcon size="xs" color="#FF0000" icon={DeclineControl} />
        </StyledIconButton>
      ),
      allowOverflow: true,
      button: true,
      width: "100px",
    },
  ];

  return (
    <>
      <Modal
        open={saveLoad.open}
        onClose={() =>
          setSaveLoad((saveLoad) => ({ ...saveLoad, open: false }))
        }
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="modal-popups saveLoad_popup"
            sx={{
              inset: "1rem",
            }}
          >
            {saveLoadShare && (
              <InfoPrompt success={promptStatus.success}>
                {promptStatus.message}
              </InfoPrompt>
            )}

            <header>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2 style={{ margin: "0 0 16px 0" }}>Project Saves </h2>
                <ModalClose
                  onClick={() => {
                    setSaveLoad({ ...saveLoad, open: false });
                  }}
                >
                  X
                </ModalClose>
              </div>
              <Divider
                style={{ marginBottom: "16px" }}
                sx={{ bgcolor: "black" }}
              />
            </header>

            <StandardContainerRow
              style={{
                overflow: "hidden",
                justifyContent: "normal",
                height: "90%",
              }}
            >
              <StandardContainerColumn
                style={{ width: "25%", height: "100%", gap: "16px" }}
              >
                <StandardContainerColumn
                  style={{
                    gap: "8px",
                    padding: "8px",
                    overflow: "hidden",
                    justifyContent: "center",
                    background: "rgba(211, 211, 211, 0.3294117647)",
                    borderRadius: "8px",
                    height: "50%",
                  }}
                >
                  <SecButton
                    style={{ width: "100%" }}
                    onClick={() => setActiveSection("personal")}
                  >
                    <FontAwesomeIcon size="xs" icon={PersonalIcon} /> Personal
                    Projects
                  </SecButton>

                  <SecButton
                    style={{ width: "100%" }}
                    onClick={() => setActiveSection("shared")}
                    disabled
                    title="Purchase Vekta Digital + to access"
                  >
                    <FontAwesomeIcon size="xs" icon={ShareIcon} /> Shared
                    Projects
                  </SecButton>

                  <StyledBadge
                    overlap="rectangular"
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    variant="dot"
                    invisible={externalInvites.length === 0}
                    style={{ width: "100%" }}
                  >
                    <SecButton
                      style={{ width: "100%" }}
                      onClick={() => setActiveSection("invites")}
                      disabled
                      title="Purchase Vekta Digital + to access"
                    >
                      <FontAwesomeIcon size="xs" icon={InviteIcon} /> Project
                      Invitations
                    </SecButton>
                  </StyledBadge>
                </StandardContainerColumn>

                <StandardContainerColumn
                  style={{
                    padding: "8px",
                    overflow: "hidden",
                    justifyContent: "center",
                    background: "rgba(211, 211, 211, 0.3294117647)",
                    borderRadius: "8px",
                    height: "50%",
                  }}
                >
                  <h3 style={{ margin: "0 0 16px 0" }}>Recently Opened</h3>

                  <StandardContainerColumn
                    style={{ gap: "8px", height: "100%", overflow: "hidden" }}
                  >
                    {recentOpened?.toReversed().map((recent, index) => {
                      return (
                        index < 5 && (
                          <StandardContainerRow
                            style={{
                              overflow: "hidden",
                              background: "#009ec641",
                              borderRadius: "4px",
                              padding: "8px",
                            }}
                          >
                            <p style={{ margin: "0" }}>
                              {recent.split("_")[0] +
                                ": " +
                                recent.split("_")[1]}
                            </p>
                            <StyledIconButton>
                              <FontAwesomeIcon size="xs" icon={OpenControl} />
                            </StyledIconButton>
                          </StandardContainerRow>
                        )
                      );
                    })}
                  </StandardContainerColumn>
                </StandardContainerColumn>
              </StandardContainerColumn>

              <Divider orientation="vertical" flexItem />

              <StandardContainerColumn style={{ width: "80%", height: "100%" }}>
                {activeSection === "personal" && (
                  <div style={{ width: "100%" }} className="personalProjects">
                    <DataTable
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 style={{ margin: "0 0 8px 0" }}>
                            Personal Projects
                          </h4>
                          <PrimeButton onClick={() => setSaveNew(true)}>
                            <FontAwesomeIcon
                              icon={faFolderPlus}
                              color="white"
                            />
                          </PrimeButton>
                        </div>
                      }
                      columns={savedTableColumns}
                      data={savedProjects}
                      pagination
                      noDataComponent="No Saved Projects"
                      highlightOnHover
                      pointerOnHover
                      expandableRows
                      expandOnRowClicked
                      expandableRowsComponent={Scenario}
                      expandableRowsComponentProps={{
                        setCommentPop: setCommentPop,
                        projectIds: projectIds,
                        type: "personal",
                        setSaveLoadShare: setSaveLoadShare,
                        setPromptStatus: setPromptStatus,
                      }}
                    />
                  </div>
                )}

                {activeSection === "shared" && (
                  <div style={{ width: "100%" }} className="sharedProjects">
                    <DataTable
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 style={{ margin: "0 0 8px 0" }}>
                            Shared Projects
                          </h4>
                          <Row8pxGap>
                            <SecButton
                              title="Refresh Table"
                              onClick={() => setRefresh((refresh) => !refresh)}
                            >
                              <FontAwesomeIcon
                                icon={faArrowsRotate}
                                color="#00A0C6"
                              />
                            </SecButton>
                            <PrimeButton
                              title="New Project"
                              onClick={() => setSaveNew(true)}
                            >
                              <FontAwesomeIcon
                                icon={faFolderPlus}
                                color="white"
                              />
                            </PrimeButton>
                          </Row8pxGap>
                        </div>
                      }
                      columns={sharedTableColumns}
                      data={sharedProjects}
                      pagination
                      noDataComponent="No Shared Projects"
                      highlightOnHover
                      pointerOnHover
                      expandableRows
                      expandOnRowClicked
                      expandableRowsComponent={Scenario}
                      expandableRowsComponentProps={{
                        setCommentPop: setCommentPop,
                        projectIds: projectIds,
                        type: "shared",
                        setSaveLoadShare: setSaveLoadShare,
                        setPromptStatus: setPromptStatus,
                      }}
                    />
                  </div>
                )}

                {activeSection === "invites" && (
                  <div style={{ width: "100%" }} className="projectInvitations">
                    <DataTable
                      title={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h4 style={{ margin: "0 0 8px 0" }}>
                            Project Invitations
                          </h4>
                          <PrimeButton onClick={() => setSaveNew(true)}>
                            <FontAwesomeIcon
                              icon={faFolderPlus}
                              color="white"
                            />
                          </PrimeButton>
                        </div>
                      }
                      columns={inviteTableColumns}
                      data={externalInvites}
                      pagination
                      noDataComponent="No Pending Project Invites"
                    />
                  </div>
                )}
              </StandardContainerColumn>

              {saveNew && (
                <>
                  <Divider orientation="vertical" flexItem />

                  <NewProject
                    platformData={platformData}
                    userSaves={userSaves}
                    allSaves={allSaves}
                    projectIds={projectIds}
                    setPromptStatus={setPromptStatus}
                    setSaveLoadShare={setSaveLoadShare}
                    setSaveNew={setSaveNew}
                  />
                </>
              )}

              {commentPop.open && null && (
                <>
                  <Divider orientation="vertical" flexItem />

                  <CommentsSheet
                    commentPop={commentPop}
                    setCommentPop={setCommentPop}
                  />
                </>
              )}
            </StandardContainerRow>
          </Box>
        </div>
      </Modal>

      {/* <Modal
        className="shareWith_internalPopup"
        isOpen={sharePop.open}
        onRequestClose={() => sharePop.open}
      >
        <div className="popup_heading">
          <button
            className="popup_close"
            onClick={() => {
              setSharePop({ open: false, key: null });
            }}
          >
            X
          </button>
        </div>

        <div className="sharableUsers">
          <div className="selectedUsers">
            <StandardContainerRow>
              <h3>Users Selected <span style={{ fontSize: "medium" }}>{Object.keys(shareSettings.users).length} of {shareGroup.length - 1}</span></h3>
              <button
                title="Share With All"
                onClick={() => {
                  const users = [];
                  shareGroup.forEach((user) => {
                    users.push(user.id);
                  });
                  setShareSettings(users);
                }}
              >
                Select All
              </button>
            </StandardContainerRow>
            <UserIcons list={shareSettings.users} setShareSettingsPop={null} status={true} limit={10} setSharedWith={setSharedWith} />
          </div>

          <StandardContainerColumn
            className="sharableUsers_list"
          >
            <h4>Company Group</h4>
            {shareGroup.map((shared) => {
              return shared.name !== user?.email && (
                <div style={{ width: "100%" }}>
                  <UserSelectCard>
                    <Avatar style={{ backgroundColor: '#009ec6', color: '#ffffff', fontWeight: "600", fontFamily: "Montserrat" }}>
                      {shared.name.charAt(0).toUpperCase()}
                    </Avatar>
                    <label className="userEmail" style={{ margin: "0" }}>{shared.name}</label>
                    <div>
                      <Checkbox value={shared.id} title="Select" aria-label="Select"
                        icon={<RadioButtonUncheckedIcon fontSize="large" />}
                        checkedIcon={<RadioButtonCheckedIcon fontSize="large" color="#009ec6" />}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setShareSettings((shareSettings) => ({
                              ...shareSettings,
                              users: { ...shareSettings.users, [e.target.value]: "viewer" },
                            }));
                          } else {
                            const temp = shareSettings.users;
                            delete temp[e.target.value];

                            setShareSettings((shareSettings) => ({
                              ...shareSettings,
                              users: temp,
                            }));
                          }
                        }}
                      />
                      <Checkbox title="Viewer" value={shared.id} aria-label="viewer"
                        icon={<RemoveRedEyeOutlinedIcon fontSize="large" />}
                        checkedIcon={<RemoveRedEyeIcon fontSize="large" color="#009ec6" />}
                        onChange={(e) => {
                          handlePermissionShare(e, "viewer", "editor")
                        }}
                        checked={shareSettings.users[shared.id] === "viewer"}
                      />
                      <Checkbox title="Editor" value={shared.id} aria-label="editor"
                        icon={<EditOutlinedIcon fontSize="large" />}
                        checkedIcon={<EditIcon fontSize="large" color="#009ec6" />}
                        onChange={(e) => {
                          handlePermissionShare(e, "editor", "viewer")
                        }}
                        checked={shareSettings.users[shared.id] === "editor"}
                      />
                    </div>
                  </UserSelectCard>
                  <Divider />
                </div>
              )
            })}
          </StandardContainerColumn>

          <div className="externalShare">
            {validEmail && <InfoPrompt success={promptStatus.success} >{promptStatus.message}</InfoPrompt>}

            <div className="input" style={{ margin: "16px 0" }}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                <label className="labelInput">Add External User</label>
                <span title="A user that is not apart of your internal company group">?</span>
              </div>
              <CreatableSelect
                isClearable
                value={formatCreateInput(externalEmail)}
                isMulti
                placeholder={"External Email"}
                onChange={(e) => {
                  const emails = []
                  e.forEach((email) => {
                    emails.push(email.value)
                  })

                  setExternalEmail(emails)
                }}
                onCreateOption={(e) => { handleEmailValidation(e) }}
              />
            </div>
          </div>
        </div>

        <Divider orientation="vertical" flexItem />

        <div className="shareOptions">
          <div className="input">
            <label>Share As</label>
            <select
              onChange={(e) =>
                setShareSettings((shareSettings) => ({
                  ...shareSettings,
                  shareType: e.target.value,
                }))
              }
            >
              <option value={"Master"}>Master</option>
              <option value={"Provisional"}>Provisional</option>
            </select>
          </div>

          <div className="input">
            <label>Add a Comment</label>
            <textarea
              onChange={(e) =>
                setShareSettings((shareSettings) => ({
                  ...shareSettings,
                  comment: e.target.value,
                }))
              }
              rows="4"
              cols="50"
            ></textarea>
          </div>
        </div>

        <footer className="popup_footer">
          <button
            className="cancel_button"
            onClick={() => {
              setSharePop({ open: false, key: null });
            }}
          >
            Cancel
          </button>
          <button
            className="share_button"
            onClick={async () => {
              setSaveLoadShare(true);
              setPromptStatus(await handleShare(user, shareSettings, sharePop.key, externalEmail))
              setSharePop({ open: false, key: null });
              setShareSettings({
                users: {},
                comment: null,
                shareType: "Master",
                projID: null,
              });
              setExternalEmail([]);
            }}
          >
            Share
          </button>
        </footer>
      </Modal> */}

      <ShareSettings
        shareSettingsPop={shareSettingsPop}
        setShareSettingsPop={setShareSettingsPop}
        sharedWith={sharedWith}
      />
      <SharePopup
        sharePop={sharePop}
        setSharePop={setSharePop}
        shareSettings={shareSettings}
        setShareSettings={setShareSettings}
        shareGroup={shareGroup}
        setSaveLoadShare={setSaveLoadShare}
        setSharedWith={setSharedWith}
      />
    </>
  );
}

export default SavePopup;
